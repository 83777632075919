import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom';


import Blog from "../components/Blog/Blog";
import Index from "../components/Index/Index";
import Roma from "../components/Sucursales/Roma/Roma";
import ResetPwd from "../components/User/reset/ResetPwd";
import Valle from "../components/Sucursales/Valle/Valle";
import SnackbarErr1 from "../components/Tienda/SnackbarErr1";
import BlogContainer from "../components/Blog/BlogContainer";
import Tlalpan from "../components/Sucursales/Tlalpan/Tlalpan";
import AboutComponent from '../components/About/AboutComponent';
import Membresias from "../components/Landing-pages/Membresias";
import NRComponent from "../components/Landing-pages/NRComponent";
import Promociones from "../components/Landing-pages/Promociones";
import CartComponent from '../components/Tienda/Cart/CartComponent';
import ShopComponent from '../components/Tienda/Shop/ShopComponent';
import SucJuarez from "../components/Sucursales/SucJuarez/SucJuarez";
import ShopCategory from '../components/Tienda/Category/ShopCategory';
import Facturacion from "../components/Avisos/Facturacion/Facturacion";
import ProductSample from '../components/Tienda/Product/ProductSample';
import CodigoEtica from "../components/Avisos/CodigoEtica/CodigoEtica";
import Iztapalapa from "../components/Sucursales/Iztapalapa/Iztapalapa";
import EventosMesRiñon from "../components/Landing-pages/EventosMesRiñon";
import RastreoComponent from "../components/User/Rastreo/RastreoComponent";
import Toluca from "../components/Sucursales/Nefropolis-Lite/Toluca/Toluca";
import Tlalnepantla from "../components/Sucursales/Tlalnepantla/Tlalnepantla";
import PoliticaQueja from "../components/Avisos/Politica_Queja/PoliticaQueja";
import SucursalesComponent from '../components/Sucursales/SucursalesComponent';
import CheckoutComponent from '../components/Tienda/Checkout/CheckoutComponent';
import InsuficienciaRenal from "../components/InsuficienciaRenal/InsuficienciaRenal";
import BolsaTrabajoComponent from "../components/Bolsa-trabajo/BolsaTrabajoComponent";
import ThankYouPageComponent from "../components/Tienda/Ty-page/ThankYouPageComponent";
import SucursalMTYComponent from "../components/Sucursales/Monterrey/SucursalMTYComponent";
import SucursalesCdmxComponent from "../components/Sucursales/Cdmx/SucursalesCdmxComponent";
import SucursalGDLComponent from "../components/Sucursales/Guadalajara/SucursalGDLComponent";
import SucursalOaxacaComponent from "../components/Sucursales/Oaxaca/SucursalOaxacaComponent";
import SucursalTuxtlaComponent from "../components/Sucursales/Tuxtla/SucursalTuxtlaComponent";
import SucursalPueblaComponent from "../components/Sucursales/Puebla/SucursalPueblaComponent";
// import LeonLiteComponent from "../components/Sucursales/Nefropolis-Lite/Leon/LeonLiteComponent";
import PoliticaEnviosComponent from '../components/Avisos/Politica_Envios/PoliticaEnviosComponent';
import SucursalTlaxcalaComponent from "../components/Sucursales/Tlaxcala/SucursalTlaxcalaComponent";
import SucursalQueretaroComponent from "../components/Sucursales/Querétaro/SucursalQueretaroComponent";
import SucursalHermosilloComponent from "../components/Sucursales/Hermosillo/SucursalHermosilloComponent";
import AccountCreateComponent from "../components/User/SignUp/Successful_Registration/AccountCreateComponent";
import PoliticaPrivacidadComponent from '../components/Avisos/Politica_Privacidad/PoliticaPrivacidadComponent';
import DistribuidoresAutorizadosComponent from "../components/Distribuidores/DistribuidoresAutorizadosComponent";
import PreguntasFrecuentesComponent from '../components/Avisos/Preguntas_Frecuentes/PreguntasFrecuentesComponent';
import TerminosCondicionesComponent from '../components/Avisos/Terminos-condiciones/TerminosCondicionesComponent';
import MedicalDirectoryListContainer from "../components/MedicalDirectory/MedicalDirectoryListContainer/MedicalDirectoryListContainer";
import MedicalDirectoryDetailContainer from "../components/MedicalDirectory/MedicalDirectoryDetailContainer/MedicalDirectoryDetailContainer";
import SearchComponent from "../components/Tienda/SearchComponent/SearchComponent";
import ShopBrand from "../components/Tienda/Brand/ShopBrand";
// import NuevoPedido from "../components/Mails/NuevoPedido";
// import PedidoEnviado from "../components/Mails/PedidoEnviado";
// import EmailToSend from "../components/Test/Emails/EmailToSend";
// import PedidoRealizado from "../components/Mails/PedidoRealizado";
// import PedidoCompletado from "../components/Mails/PedidoCompletado";
// import CarritoAbandonado from "../components/Mails/CarritoAbandonado";
// import FreshdeskTest from "../components/Test/Freshdesk/FreshdeskTest";
// import ContactoComponente from '../components/Contact/ContactoComponent';
// import ActualizacionPedido from "../components/Mails/ActualizacionPedido";
// import FlashSaleProduct from "../components/Tienda/FlashSale/FlashSaleProduct";
// import FlashSaleContainer from "../components/Tienda/FlashSale/FlashSaleContainer";
// import Autofacturacion from "../components/Avisos/Autofacturacion/Autofacturacion";
// import AdminSucursalesComponent from '../components/Admin/Sucursales/AdminsSucursalesComponent';

export const ShopRoute = () => {
    // useEffect(() => {
    //     window.zE('webWidget', 'show');
    // }, []);

    return (
        <>
            <Routes>
                <Route path="" element={<Index />} />
                <Route path='sobre-nefropolis' element={<AboutComponent />} />
                <Route path='nuestro-equipo' element={<BolsaTrabajoComponent />} />
                <Route path='gracias-registro' element={<AccountCreateComponent />} />
                <Route path="/tienda" element={<ShopComponent />} />
                <Route path="/tienda/buscar/:product_field" element={<SearchComponent />} />
                <Route path="/tienda/categoria/:category_url" element={<ShopCategory />} />
                <Route path="/tienda/marca/:brand_url" element={<ShopBrand />} />
                <Route path="/tienda/producto/:product" element={<ProductSample />} />
                <Route path="/carrito" element={<CartComponent />} />
                <Route path="/checkout" element={<CheckoutComponent />} />
                {/* <Route path='contacto' element={<ContactoComponente />} /> */}
                <Route path='gracias-por-tu-compra/:reference' element={<ThankYouPageComponent />} />
                <Route path='rastrear-pedido/:reference' element={<RastreoComponent />} />
                <Route path='restablecer/:hash' element={<ResetPwd />} />
                <Route path="*" element={<Navigate to="/" replace />} />
                {/* Sucursales */}
                <Route path='sucursales' element={<SucursalesComponent />}></Route>
                <Route path='sucursales-cdmx' element={<SucursalesCdmxComponent />}></Route>
                <Route path='sucursal-monterrey' element={<SucursalMTYComponent />}></Route>
                <Route path='sucursal-iztapalapa' element={<Iztapalapa />}></Route>
                <Route path='sucursal-roma' element={<Roma />}></Route>
                <Route path='sucursal-tlalnepantla' element={<Tlalnepantla />}></Route>
                <Route path='sucursal-tlalpan' element={<Tlalpan />}></Route>
                <Route path='sucursal-valle-de-aragon' element={<Valle />}></Route>
                <Route path='sucursal-guadalajara' element={<SucursalGDLComponent />}></Route>
                <Route path='sucursal-hermosillo' element={<SucursalHermosilloComponent />}></Route>
                <Route path='sucursal-queretaro' element={<SucursalQueretaroComponent />}></Route>
                <Route path='sucursal-oaxaca' element={<SucursalOaxacaComponent />}></Route>
                <Route path='sucursal-tuxtla' element={<SucursalTuxtlaComponent />}></Route>
                <Route path='sucursal-tlaxcala' element={<SucursalTlaxcalaComponent />}></Route>
                <Route path='sucursal-puebla' element={<SucursalPueblaComponent />}></Route>
                <Route path='sucursal-lite-toluca' element={<Toluca />}></Route>
                <Route path='juarez-distribuidor-autorizado' element={<SucJuarez />}></Route>
                <Route path='distribuidores-autorizados' element={<DistribuidoresAutorizadosComponent />}></Route>
                {/* Avisos */}
                <Route path='politica-de-privacidad' element={<PoliticaPrivacidadComponent />} />
                <Route path='politica-de-envios' element={<PoliticaEnviosComponent />} />
                <Route path='preguntas-frecuentes' element={<PreguntasFrecuentesComponent />} />
                <Route path='terminos-y-condiciones' element={<TerminosCondicionesComponent />} />
                <Route path='codigo-de-etica' element={<CodigoEtica />} />
                <Route path='politica-de-queja' element={<PoliticaQueja />} />
                <Route path='politica-de-facturacion' element={<Facturacion />} />
                {/* <Route path='autofacturacion' element={<Autofacturacion />} /> */}
                {/* Landing pages */}
                <Route path='asesoria-nutricion-renal' element={<NRComponent />}></Route>
                <Route path='eventos' element={<EventosMesRiñon />}></Route>
                <Route path='promociones' element={<Promociones />}></Route>
                <Route path='membresias-prime' element={<Membresias />}></Route>
                {/* Test */}
                {/* <Route path='mailerlite-test' element={<Test />} />
                <Route path='freshdesk-test' element={<FreshdeskTest />} />
                <Route path='email-test' element={<EmailToSend />} /> */}
                {/* Blog */}
                <Route path='blog' element={<BlogContainer />}></Route>
                <Route path='blog/:url' element={<Blog />}></Route>
                {/* Directorio médico */}
                <Route path='directorio-medico' element={<MedicalDirectoryListContainer />}></Route>
                <Route path='directorio-medico/:name' element={<MedicalDirectoryDetailContainer />}></Route>
                {/* Flash sale countdown */}
                {/* <Route path='/tienda/oferta-relampago' element={<FlashSaleContainer />}></Route>
                <Route path="/tienda/oferta-relampago/:name" element={<FlashSaleProduct />}></Route> */}
                <Route path='/insuficiencia-renal' element={<InsuficienciaRenal />}></Route>

                <Route path='google-login' element={<ThankYouPageComponent />} />
            </Routes>
            <SnackbarErr1 />
        </>
    )
};