import { types } from '../types/types';
import { USER_SESSION } from '../constants/user/user_constants';

const initialState = {
    loggedUser: USER_SESSION,
    loading: true
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.userLoggedIn:
            return {
                ...state,
                loggedUser: {
                    ...state.loggedUser,
                    ...action.payload
                },
                loading: false
            }
        case types.userLoggedOut:
            return {
                ...state,
                loggedUser: USER_SESSION,
                loading: false
            }
        case types.loadingDone:
            return {
                ...state,
                loading: false
            }
        case types.clearRdrPage:
            return {
                ...state,
                loggedUser: {
                    ...state.loggedUser,
                    page: ''
                }
            }
        default:
            return state;
    }
};