import React, { useRef, useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { basicMsg } from '../../../../../actions/swal_msg';

import {
    Button, Card, CardActions, Grid, CardMedia,
    Container, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { regex_file_ext } from '../../../../../constants/regex';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const AdditionalImagesContainer = ({ images, additionalImages, setAdditionalImages }) => {
    const fileInputRef = useRef();
    // se utiliza para modificar nombre de archivos/imágenes repetidas
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        fileInputRef.current.value = "";
    }, [additionalImages]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(additionalImages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        setAdditionalImages(items);
    };

    // remueve imagen de producto
    const removeImg = name => () => {
        setAdditionalImages(e => e.filter(u => u.name !== name));
    };

    const loadImages = async ({ target }) => {
        let count = images.length + additionalImages.length; // total de imágenes
        let duplicate_img = false;
        let exceeded = false; // se alcanzó el límite?
        if (count >= 20) {
            basicMsg('No puede agregar más imágenes');
            return;
        }
        if (target.files.length > 0) {
            let target_files = [];
            for (const element of [...target.files]) {
                if (count >= 20) exceeded = true;
                else {
                    const { name, type } = element;
                    // archivo es una imagen compatible
                    if (['image/jpeg', 'image/png', 'image/webp'].includes(type)) {
                        const file_ext = regex_file_ext.exec(name)[1]; 
                        if (file_ext) { // extensión es válida
                            // mismo archivo ya se ha subido => editar nombre
                            if (additionalImages.find(e => e.name === name)) {
                                // nuevo nombre de archivo
                                // nombre anterior sin extensión + contador + . + extensión
                                const file_name = `${name.replace(/\.[^/.]+$/, "")}_${counter}.${file_ext}`;
                                target_files = [
                                    ...target_files, new File([element], file_name, { type })
                                ];
                                duplicate_img = true;
                            } else
                                target_files = [...target_files, element];
                            count++;
                        }
                    }
                }
            };
            if (duplicate_img) // hubo por lo menos una imagen duplicada
                setCounter(counter + 1);
            
            setAdditionalImages(e => ([...e, ...target_files]));
            if (exceeded) basicMsg('Se alcanzó el límite de imágenes (20)');
        }
    };

    return (
        <Container maxWidth='xl' sx={{ mt: 2, mb: 4 }}>
            <DragDropContext onDragEnd={ handleOnDragEnd }>
                <Droppable droppableId="product-images">
                    {(provided) => (
                        <Grid container spacing={2}
                            { ...provided.droppableProps }
                            ref={ provided.innerRef }>
                            {
                                additionalImages.length > 0 &&
                                additionalImages.map((item, i) => {
                                    const { name } = item;
                                    return (
                                        <Draggable key={ name }
                                            draggableId={ name } index={i}>
                                            {(provided) => (
                                                <Grid item xs={12} md={4}>
                                                    <Card key={i}
                                                        ref={ provided.innerRef }
                                                        { ...provided.draggableProps }
                                                        { ...provided.dragHandleProps }>
                                                        <CardMedia
                                                            component="img"
                                                            height="194"
                                                            image={ URL.createObjectURL(item) }
                                                            alt="imagen-adicional"
                                                        />
                                                        <CardActions disableSpacing>
                                                            <IconButton aria-label="delete"
                                                                onClick={ removeImg(name) }>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </CardActions>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </Draggable>
                                    );
                                })
                            }
                            { provided.placeholder }
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
            <Button sx={{ mt: 2 }}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                Subir imágenes
                <VisuallyHiddenInput
                    ref={ fileInputRef }
                    accept="image/*" type="file"
                    onChange={ loadImages } multiple
                />
            </Button>
        </Container>
    );
};

export default AdditionalImagesContainer;