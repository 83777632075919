import { types } from "../../../types/types";
import { basicMsg, htmlMsg, msg as swalMsg } from "../../swal_msg";
import { generateURL } from "../../../helpers/admin/productHelper";
import { axiosProtectedReq, protectedReq } from "../../../helpers/requestHelper";

// guardar marca de producto
export const saveProductBrand = ({ brand, imgFile }, setOpen, setLoading) => {
    return async(dispatch) => {
        try {
            const data = new FormData();
            data.append('brand', brand);
            data.append('url', generateURL(brand));
            data.append('file', imgFile);
            const resp = await axiosProtectedReq('product/brand/save', data, 'POST');
            if (resp.status) {
                dispatch(brandAdded(resp.resp));
                setOpen(false);
            } else {
                if (resp.msg === 'exists')
                    basicMsg('La marca de producto ya existe');
                else if (resp.msg === 'brand-not-registered')
                    basicMsg('No se pudo registrar la marca de producto');
                else if (resp.msg === 'image-not-uploaded')
                    basicMsg('Portada de marca no subida');
                else if (resp.msg === 'no-info')
                    basicMsg('Falta información de marca');
                else if (resp.msg === 'server-err')
                    swalMsg('warning', 'Marca de producto',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else swalMsg('warning', 'Marca de producto',
                    'Inténtelo de nuevo, por favor');
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            swalMsg('No se pudo registrar la marca de producto');
        };
    };
};

// marca de producto añadida
const brandAdded = (payload) => ({
    type: types.brandAdded, payload
});

// eliminar marca(s) de producto
export const deleteBrands = (model, setLoading) => {
    return async(dispatch) => {
        try {
            const brands = model.map(({ _id, brand, imgId }) => ({ _id, brand, imgId }));
            const resp = await protectedReq('product/brand/delete-many', { brands }, 'DELETE');
            const body = await resp.json();
            if (body.status) {
                const { resp: { ids, html } } = body;
                dispatch(brandsDeleted(ids));
                const txt = html.length === 0 ?
                    '<p>Las marcas de productos fueron eliminadas correctamente</p>'
                :
                    `<div style="text-align: left; padding-left: 0pt;"><ul>${
                        html.map(e => e)
                    }</ul></div>`
                htmlMsg('success', 'Marcas de producto', txt);
            } else {
                const { msg } = body;
                if (msg === 'miss')
                    basicMsg('Falta información de marcas');
                else if (msg === 'brands-not-deleted')
                    basicMsg('Marcas de producto no eliminadas');
                else if (msg === 'server-err')
                    swalMsg('error', 'Marcas de producto',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else
                    swalMsg('warning', 'Marcas de producto',
                    'Inténtelo de nuevo, por favor');
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            const txt = model.length > 1 ? 'No se pudieron eliminar las marcas'
                : 'No se pudo eliminar la marca de producto'
            swalMsg('error', 'Marcas de producto', txt);
        };
    };
};

// marcas de producto eliminadas
const brandsDeleted = (payload) => ({
    type: types.brandsDeleted, payload
});


// eliminar marca(s) de producto
export const updateBrands = (model, setLoading) => {
    return async(dispatch) => {
        try {
            const brands = model.map(({ _id, brand }) => ({ _id, brand }));
            const resp = await protectedReq('product/brand/update', { brands }, 'PUT');
            const body = await resp.json();
            if (body.status) {
                const { resp: { brand_arr } } = body;
                dispatch(brandsUpdated(brand_arr));
                swalMsg('success', 'Marcas de producto',
                'Las marcas de productos fueron actualizadas correctamente');
            } else {
                const { msg, resp: { html } } = body;
                if (msg === 'miss')
                    basicMsg('Falta información de marcas');
                else if (msg === 'server-err')
                    swalMsg('error', 'Marcas de producto',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else {
                    if (html.length > 0)
                        htmlMsg('success', 'Marcas de producto',
                        `<div style="text-align: left; padding-left: 0pt;"><ul>${
                            html.map(e => e)
                        }</ul></div>`);
                    else
                        swalMsg('warning', 'Marcas de producto',
                        'Inténtelo de nuevo, por favor');
                }
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            swalMsg('error', 'Marcas de producto',
            'No se pudieron actualizar las marcas');
        };
    };
};

// categorías actualizadas
const brandsUpdated = (payload) => ({
    type: types.brandUpdated,
    payload
});