import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

//Router dom
import { Link, useNavigate } from 'react-router-dom';

import './UserBilling.css';
import { delBillingAddress, myBillingAddresses } from '../../../actions/user-account/billing/ua_billing';

//MUI
import {
    Alert, Box, Breadcrumbs, Button, Container, Divider,
    IconButton, List, ListItem, ListItemButton, ListItemText,
    Menu, MenuItem, Typography, useMediaQuery
} from '@mui/material';
import EditBilling from './EditBilling/EditBilling';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const init = {
    business_name: '',
    rfc: '',
    cif: '',
    tax_system: '',
    cfdi: '',
    billing_tel: '',
    address: '',
    billing_postalcode: '',
    billing_colony: '',
    billing_city: '',
    billing_state: ''
};

const UserBilling = () => {
    const navigate = useNavigate();
    // régimen fiscal y CFDI disponible
    const { taxSystemData, CFDIData } = useSelector(state => state.checkout);

    const matches = useMediaQuery('(max-width: 950px)');
    const matches_2 = useMediaQuery('(max-width: 375px)');

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // direcciones de facturación del usuario
    const [addresses, setAddresses] = useState([]);
    // dirección a editar/eliminar
    const [values, setValues] = useState(init);

    useEffect(() => {
        getBillingAdresses();
    }, []);

    // cargar direcciones de facturación del usuario
    const getBillingAdresses = async () => {
        const req = await myBillingAddresses();
        if (req.status)
            setAddresses(req.resp);
    };

    // click a menú de dirección
    const handleClick = (myValues) => (event) => {
        setValues(myValues);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [modal, setModal] = useState(false);

    const handleOpenEdit = () => {
        setModal(true);
        setAnchorEl(null);
    };

    // registrar nueva dirección
    const newBillingAddress = () => {
        navigate('/mi-cuenta/nueva-direccion-facturacion');
    };

    // filtrar régimen fiscal (descripción)
    const getTaxSystem = (_taxSystem) => {
        const dataFound = taxSystemData.find(e => e.codigo === _taxSystem);
        return dataFound ? `${_taxSystem} - ${dataFound.descripcion}` : _taxSystem;
    };

    // filtrar CFDI (descripción)
    const getCFDI = (_cfdi) => {
        const dataFound = CFDIData.find(e => e.codigo === _cfdi);
        return dataFound ? `${_cfdi} - ${dataFound.descripcion}` : _cfdi;
    };

    const handleDelete = () => {
        setAnchorEl(null);
        Swal.fire({
            title: `¿Eliminar la dirección de facturación?`,
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then(async result => {
            if (result.isConfirmed) {
                const resp = await delBillingAddress(values);
                if (resp.status)
                    setAddresses((e) => e.filter(
                        e => (e._id !== values._id)
                    ));
            }
        });
    };

    return (
        <>
            {/* Titulo y breadcrumb */}
            <Container maxWidth='lg' sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant='h4' sx={{ fontWeight: 'light' }} gutterBottom>
                    Mis direcciones de facturación
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/mi-cuenta">
                        Mi cuenta
                    </Link>
                    <Typography color="text.primary">Direcciones de facturación</Typography>
                </Breadcrumbs>
            </Container>
            {/* Fin titulo y breadcrumb */}

            {/* Contenido de direcciónes */}
            <Container maxWidth='lg' sx={{ mt: 5 }} className="last-view">
                {
                    addresses.length > 0
                        ?
                        <List className='list_billing_address' sx={{ p: 0 }}>
                            {
                                addresses.map((item) => (
                                    <Box key={item._id}>
                                        <ListItem
                                            alignItems="flex-start"
                                            secondaryAction={
                                                matches_2
                                                    ?
                                                    null
                                                    :
                                                    <IconButton edge="end" onClick={ handleClick(item) }>
                                                        <MoreVertIcon />
                                                    </IconButton>

                                            }
                                        >
                                            <ListItemText
                                                primary={item.business_name}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography variant='body1'>{item.rfc}</Typography>
                                                        <Typography variant='body1'>{
                                                            `Régimen fiscal: ${getTaxSystem(item.tax_system)}`
                                                        }</Typography>
                                                        <Typography variant='body1'>{
                                                            `CFDI: ${getCFDI(item.cfdi)}`
                                                        }</Typography>
                                                        <Typography variant='body1' sx={{ mt: 2 }}>{ `Tel: ${item.tel}` }</Typography>
                                                        <Typography variant='body1'>
                                                            {item.address}, C.P. {item.postalcode} Col. {item.colony}, {item.city}, {item.state}
                                                        </Typography>
                                                        <Typography variant='body2' sx={{ fontStyle: 'italic' }}>{
                                                            `CSF subido: ${item.filename}`
                                                        }</Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        {
                                            matches_2
                                            &&
                                            <Box sx={{ textAlign: 'center' }}>
                                                <IconButton onClick={ handleClick(item) }>
                                                    <MoreHorizIcon />
                                                </IconButton>
                                            </Box>
                                        }
                                        <Divider />
                                    </Box>
                                ))
                            }
                            <ListItem disablePadding>
                                <ListItemButton component={Link} to='/mi-cuenta/nueva-direccion-facturacion'>
                                    <ListItemText
                                        primary="Agregar dirección de facturación"
                                        sx={{ color: '#0066FD' }}
                                        primaryTypographyProps={{
                                            fontWeight: '600',
                                        }}
                                    />
                                    <ChevronRightIcon sx={{ color: '#0066FD' }} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        :
                        <>
                            <Alert severity="warning">
                                Por el momento no cuenta con ninguna dirección de facturación
                            </Alert>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                                <Button variant='contained' onClick={ newBillingAddress }>
                                    Crear dirección
                                </Button>
                            </Box>
                        </>
                }
            </Container>
            {/* Fin contenido de direcciones */}

            {/* Menu */}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleDelete}>Eliminar</MenuItem>
                <MenuItem onClick={handleOpenEdit}>Editar</MenuItem>
            </Menu>

            <EditBilling modal={ modal }
                setModal={ setModal }
                values={ values }
                setAddresses={ setAddresses } />
        </>
    );
};

export default UserBilling;