import { htmlMsg } from "../../../actions/swal_msg";
import { regex_num } from "../../../constants/regex";

// validación básica de formulario para nuevo producto
export const form1Validator = ({
    title, prod_category, url, brand, variants
}) => {
    let errors = [];
    const title_len = title.trim().length;
    const url_len = url.trim().length;
    const brand_len = brand.trim().length;
    if (title_len < 2 || title_len > 200)
        errors = [...errors, 'Ingrese título de producto'];
    if (url_len < 2 || url_len > 200)
        errors = [...errors, 'No se generó la url de producto'];
    if (prod_category.length <= 0)
        errors = [...errors, 'Seleccione por lo menos una categoría'];
    if (brand === '' || (brand_len < 3 || brand_len > 60))
        errors = [...errors, 'Seleccione marca de producto'];
    if (variants.length > 0) {
        variants.map((e, i) => {
            if (e.price.length < 2 || e.price.length > 8)
                errors = [...errors, `Variante #${i+1}: Requiere precio`];
            else if (e.description.length < 3 || e.description.length > 2000)
                errors = [...errors, `Variante #${i+1}: Ingrese descripción`];
            else if (e.sat_key.length !== 8 || !regex_num.test(e.sat_key))
                errors = [...errors, `Variante #${i+1}: Ingrese clave SAT`];
            else if (e.iva !== 0 && e.iva !== 16)
                errors = [...errors, `Variante #${i+1}: Ingrese IVA - 0/16%`];
            else if (e.claveUnidad.length !== 3)
                errors = [...errors, `Variante #${i+1}: Ingrese clave de unidad`];
            else if (e.unidad.length < 2 || e.unidad.length > 64)
                errors = [...errors, `Variante #${i+1}: Ingrese unidad`];
            else if (e.barcode.length < 1 || e.barcode.length > 32)
                errors = [...errors, `Variante #${i+1}: Ingrese código de barras`];
            else if (e.stock.length < 1 || e.stock.length > 5)
                errors = [...errors, `Variante #${i+1}: Ingrese stock`];
            else if (e.images <= 0 || e.images > 6)
                errors = [...errors, `Variante #${i+1}: Ingrese entre 1 y 6 imágenes`];
            return e;
        });
    } else errors = [...errors, `Agregue por lo menos una variante`];

    if (errors.length > 0) { // no ha completado datos del producto/variante(s)
        let txt = '';
        for (const err of errors) {
            txt += `<li>${err}</li>`;
        };
        htmlMsg('info', 'Complete los datos del producto',
        `<div style="text-align: left; padding-left: 0pt;">Pendiente: <ul>${txt}</ul></div>`);
        return false;
    } else return true;
};

// validación básica de formulario para edición de producto
export const form2Validator = ({
    title, url, brand
}, prod_category) => {
    let errors = [];
    const title_len = title.trim().length;
    const url_len = url.trim().length;
    const brand_len = brand.trim().length;
    if (title_len < 2 || title_len > 200)
        errors = [...errors, 'Ingrese título de producto'];
    if (url_len < 2 || url_len > 200)
        errors = [...errors, 'No se generó la url de producto'];
    if (prod_category.length <= 0)
        errors = [...errors, 'Seleccione por lo menos una categoría'];
    if (brand === '' || (brand_len < 3 || brand_len > 60))
        errors = [...errors, 'Seleccione marca de producto'];

    if (errors.length > 0) { // no ha completado datos del producto/variante(s)
        let txt = '';
        for (const err of errors) {
            txt += `<li>${err}</li>`;
        };
        htmlMsg('info', 'Complete los datos del producto',
        `<div style="text-align: left; padding-left: 0pt;">Pendiente: <ul>${txt}</ul></div>`);
        return false;
    } else return true;
};