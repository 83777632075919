import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import BrandRadioGroup from './BrandRadioGroup';
import BrandDialog from '../../Brand/BrandDialog';

import {
    Grid, Button, Divider, Chip, Box, FormGroup, Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ProductBrand = ({ brand, setProdValues }) => {
    const { brands } = useSelector(state => state.category);
    const [open, setOpen] = useState(false);

    return (
        <Box sx={{ p: 3, mb: 3 }}
            className='box-shadow-add-product-category'>
            <Grid container>
                <Grid item xs={7} md={6} lg={9}>
                    <Divider>
                        <Chip label="Marca" sx={{ fontSize: 16 }} />
                    </Divider>
                </Grid>
                <Grid item xs={5} md={6} lg={3}>
                    <Button variant="outlined"
                        size="small" startIcon={
                            <AddIcon fontSize="inherit" />
                        } onClick={ () => setOpen(!open) }>
                        Agregar
                    </Button>
                </Grid>
            </Grid>

            <FormGroup>
                {
                    brands.length <= 0 ?
                        <Alert severity="info">
                            Añada marcas de producto
                        </Alert>
                    :
                        <BrandRadioGroup
                            brand={ brand }
                            setProdValues={ setProdValues }
                        />
                }
            </FormGroup>
            <BrandDialog
                open={ open }
                setOpen={ setOpen }
            />
        </Box>
    );
};

export default ProductBrand;