import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

import { basicMsg } from '../../../../actions/swal_msg';
import { deleteCategory } from '../../../../actions/category';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

import {
    Stack, Typography, Button, Grid, IconButton, ListItem, ListItemText,
    ListItemIcon, Checkbox, Card, CardMedia, CardActions, Paper, TextField
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LabelIcon from '@mui/icons-material/Label';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ProductCategoryList = ({ _category, categories, setCategories, setDeleted, index }) => {
    const dispatch = useDispatch();
    const { _id, checked, category, url, subcategories, imgId } = _category;

    const [displayField, setDisplayField] = useState(false);
    const [fields, setFields] = useState({
        categoryField: '',
        subcategoryField: ''
    });
    const { categoryField, subcategoryField } = fields;

    useEffect(() => {
        setFields((e) => ({ ...e, categoryField: category }));
    }, [category]);

    // seleccionar categorías existentes
    const onChangeCheckbox = ({ target }) => {
        const checked = target.checked;
        if (!checked)
            setDeleted((e) => e.filter(e => e._id !== _id));
        else
            setDeleted((e => [...e, { _id, imgId, category }]));
        setCategories((e) => e.map(
            u => u._id === _id ?
                { ...u, checked } : u
        ));
    };

    // agregar subcategoría a categoría
    const addSubcategory = () => {
        if (subcategoryField.length > 2 && subcategoryField.length <= 60) {
            const subcategoryInArray = subcategories.find(e => e.toLowerCase() === subcategoryField.toLowerCase());
            if (subcategoryInArray)
                basicMsg('La subcategoría ya está registrada');
            else {
                setCategories((e) => e.map(
                    u => u._id === _id ?
                        {
                            ...u, checked,
                            subcategories: [...u.subcategories, subcategoryField]
                        } : u
                ));
                setFields((e) => ({ ...e, subcategoryField: '' }));
            }
        }
    };

    // remueve subcategoría de categoría ya registrada
    const removeSubcategory = _subcategory => () => {
        setCategories((e) => e.map(
            u => u._id === _id ?
                {
                    ...u, checked,
                    subcategories: u.subcategories.filter(o => o !== _subcategory)
                } : u
        ));
    };

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'category' && value.length <= 60)
            setFields((e) => ({ ...e, categoryField: value.toUpperCase() }));
        else if (name === 'subcategory' && value.length <= 60)
            setFields((e) => ({ ...e, subcategoryField: value.toUpperCase() }));
    };

    const deleteCategoryFn = () => {
        Swal.fire({
            title: `¿Eliminar categoría '${category}'?`,
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCategory({ _id, imgId }, categories));
            }
        });
    };

    // mostrar campo de categoria
    const showField = () => {
        setDisplayField(!displayField);
    };

    const updateMainCategory = () => {
        if (categoryField.length > 2 && categoryField.length <= 60) {
            setCategories((e) => e.map(
                u => u._id === _id ?
                    { ...u, category: categoryField } : u
            ));
            setDisplayField(false);
        }
    };

    return (
        <Draggable key={ _id } draggableId={ _id } index={ index }>
            {(provided, snapshot) => (
            <Paper elevation={3} sx={{ marginTop: 2, p: 1 }}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Card elevation={0}>
                            <div style={{ position: "relative" }}>
                                <CardMedia style={{ height: "250px", paddingTop: "2%" }}
                                    component="img" title={ url } alt={`img-categoria-${url}`}
                                    image={ `${GOOGLE_DRIVE_CDN}${imgId} `} />
                                <div style={{
                                    position: "absolute",
                                    background: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                    bottom: 10,
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    width: "100%",
                                    fontSize: "20px"
                                }}>
                                    { category }
                                </div>
                            </div>
                            <CardActions>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}>
                                    <IconButton aria-label="delete"
                                        onClick={ showField }>
                                        <EditIcon />
                                    </IconButton>
                                    <Button size="small" onClick={ deleteCategoryFn }>Eliminar</Button>
                                    <Checkbox color="default"
                                        checked={ checked }
                                        onChange={ onChangeCheckbox } />
                                </Stack>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            displayField &&
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={2}>
                                    <TextField sx={{ mb: 2 }}
                                        fullWidth
                                        name="category"
                                        label="Categoría principal"
                                        variant="outlined"
                                        value={ categoryField }
                                        inputProps={{ maxLength: 60 }}
                                        size="small"
                                        onChange={ handleInputChange } />
                                    <IconButton aria-label="delete"
                                        onClick={ updateMainCategory }>
                                        <CheckCircleIcon />
                                    </IconButton>
                                </Stack>
                        }

                        <Typography variant="body1">
                            Subcategorías:
                        </Typography>
                        {
                            subcategories.map((value) => (
                                <ListItem
                                    key={ value }
                                    disableGutters
                                    secondaryAction={
                                        <IconButton aria-label="comment" onClick={ removeSubcategory(value) }>
                                            <DeleteIcon />
                                        </IconButton>
                                    }>
                                    <ListItemIcon>
                                        <LabelIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={ value } />
                                </ListItem>
                            ))
                        }
                        <Grid container>
                            <Grid item xs={10}>
                                <TextField fullWidth
                                    name="subcategory"
                                    label="Subcategoría"
                                    variant="outlined"
                                    value={ subcategoryField }
                                    inputProps={{ maxLength: 60 }}
                                    size="small"
                                    onChange={ handleInputChange } />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton aria-label="add" onClick={ addSubcategory }>
                                    <AddBoxIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        )}
        </Draggable>
    );
};

export default ProductCategoryList;