import { types } from '../types/types';
import { ORDER_INIT } from '../constants/constants';

const initialState = {
    abandoned_orders: [], // pedidos abandonados de cliente
    orders: [], // pedidos aprobados/pagados
    buyAgainProducts: [], // productos comprados
    activeOrder: ORDER_INIT
};

export const userAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.userOrdersLoaded:
            return {
                ...state,
                ...action.payload
            };
        case types.userOrderSelected:
            return {
                ...state,
                activeOrder: action.payload
            };
        case types.userOrderRemoved:
            return {
                ...state,
                abandoned_orders: [
                    ...state.abandoned_orders.filter(e => e.order_num !== action.payload)
                ]
            };
        case types.userOrderRestored:
            const orderList = [action.payload, ...state.abandoned_orders].sort((a, b) => b.order_num - a.order_num);
            return {
                ...state,
                abandoned_orders: [...orderList]
            };
        default:
            return state;
    }
};