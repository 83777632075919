import React from 'react'

//CDN
import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

//Images
import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";

//MUI
import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
// import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InventoryIcon from '@mui/icons-material/Inventory';
import AddIcon from '@mui/icons-material/Add';
// import SaveIcon from '@mui/icons-material/Save';

//Router-dom
import { useNavigate } from 'react-router-dom';

//Constants
// import { CURRENT_PROMOTION } from '../../../constants/shop/promotion/promotion_const';

//Actions
// import { addItemsToCart, saveItemToCart } from '../../../actions/shop';

//Redux
// import { useDispatch } from 'react-redux';

//Swal
// import { basicMsg } from '../../../actions/swal_msg';

const CartButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#56C596',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: '600',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#358f69',
        color: '#fff'
    }
}));

const CSButton = styled(Button)(({ theme }) => ({
    color: '#ffff',
    backgroundColor: '#0099DC',
    borderColor: '#0099DC',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#0076a9',
        color: '#ffff',
    },
}));

const AddToCartDialog = ({ open, ActivePromotions, item_promotion, handleClose, action }) => {

    const navigate = useNavigate();

    // const dispatch = useDispatch();

    // const [currentPromotion, setCurrentPromotion] = useState(CURRENT_PROMOTION);
    // const { promo_id, promo, pkg_qty, info, products, active } = currentPromotion;

    // useEffect(() => {
    //     if (promo_id !== '') {
    //         // misma promoción
    //         const Promotion = cart_promotion.find(e => e.promo_id === promo_id);
    //         if (Promotion) { // promoción sigue válida
    //             // cambió arreglo (se agregaron productos)
    //             if (products !== Promotion.products)
    //                 setCurrentPromotion(Promotion);
    //         }
    //     }
    // }, [cart_promotion]);

    // const [disabled, setDisabled] = useState(false);

    // agregar producto a carrito
    // const addToCart = item => () => {
    //     setDisabled(true);
    //     let qty = 1;
    //     const { prod_id, prod_var_id, quantity, medicine, refrigerated } = item;
    //     if (promo === 'CompraX%Descuento') // cantidad faltante
    //         qty = Number(pkg_qty) - quantity;
    //     else if (promo === '4x3') {
    //         if (quantity === 1)
    //             qty = 3;
    //         else if (quantity === 2)
    //             qty = 2;
    //     }
    //     const model = {
    //         prod_id, prod_var_id, quantity: qty,
    //         refrigerated, medicine
    //     };
    //     dispatch(saveItemToCart(model, '', setDisabled));
    //     // Tag Manager
    //     window.dataLayer.push({
    //         event: 'add-to-cart'
    //     });
    // };

    // agregar productos a carrito
    // const addManyToCart = () => {
    //     setDisabled(true);
    //     // producto ya está en carrito
    //     if (['2x1', '4x3', 'CompraX%Descuento'].includes(promo)) { // un sólo producto necesario para activar promoción
    //         let qty = 1;
    //         const { prod_id, prod_var_id, quantity, medicine, refrigerated } = products[0];
    //         if (promo === 'CompraX%Descuento') // cantidad faltante
    //             qty = Number(pkg_qty) - quantity;
    //         else if (promo === '4x3') {
    //             if (quantity === 1)
    //                 qty = 3;
    //             else if (quantity === 2)
    //                 qty = 2;
    //         }
    //         const model = {
    //             prod_id, prod_var_id, quantity: qty,
    //             refrigerated, medicine
    //         };
    //         dispatch(saveItemToCart(model, '', setDisabled));
    //         // Tag Manager
    //         window.dataLayer.push({
    //             event: 'add-to-cart'
    //         });
    //     } else {
    //         // productos faltantes para activar promoción (no están en carrito)
    //         const items = products.filter(e => !e.in_cart).map(({
    //             prod_id, prod_var_id, title, url, info,
    //             category, prod_category, variant_title,
    //             refrigerated, medicine
    //         }) => ({
    //             prod_id, prod_var_id, title, url, info,
    //             category, prod_category, variant_title,
    //             refrigerated, medicine
    //         }));
    //         if (items.length > 0)
    //             dispatch(addItemsToCart(items, setDisabled));
    //         else basicMsg('Ya ha completado la promoción');
    //     }
    // };

    return (
        <>
            {/* Dialog agregar al carrito */}
            <Dialog
                fullWidth={true}
                maxWidth={item_promotion.promo !== '' ? 'md' : 'sm'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle align='center'>Producto agregado al carrito</DialogTitle>
                <DialogContent>
                    {
                        item_promotion.promo === '' &&
                        <Box sx={{ mb: 4 }}>
                            <svg className="check_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </Box>
                    }
                    {
                        item_promotion.promo !== '' &&
                        <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;', mt: 2, borderRadius: '8px' }}>
                            <Alert
                                severity="info"
                                icon={<InventoryIcon />}
                            >
                                Promociones disponibles
                            </Alert>
                            <Box sx={{ p: 2 }}>
                                <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                                    {
                                        ActivePromotions.length > 1
                                            ?
                                            <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                                                <IconButton className='promotions-swiper-button-prev' size='small' disabled={ActivePromotions.length <= 1}>
                                                    <ChevronLeftIcon />
                                                </IconButton>
                                            </Grid>
                                            :
                                            null
                                    }
                                    <Grid item xl={ActivePromotions.length > 1 ? 10 : 12} lg={ActivePromotions.length > 1 ? 10 : 12} md={ActivePromotions.length > 1 ? 10 : 12} sm={ActivePromotions.length > 1 ? 8 : 12} xs={ActivePromotions.length > 1 ? 8 : 12}>
                                        <Swiper
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 1,
                                                },
                                                610: {
                                                    slidesPerView: 1,
                                                },
                                                800: {
                                                    slidesPerView: 1,
                                                },
                                                950: {
                                                    slidesPerView: 1,
                                                },
                                                1100: {
                                                    slidesPerView: 1,
                                                },
                                                1200: {
                                                    slidesPerView: 1,
                                                },
                                            }}
                                            spaceBetween={40}
                                            autoHeight={true}
                                            navigation={{
                                                nextEl: '.promotions-swiper-button-next',
                                                prevEl: '.promotions-swiper-button-prev',
                                            }}
                                            loop={ActivePromotions.length > 1 ? true : false}
                                            pagination={{
                                                clickable: true,
                                                dynamicBullets: true,
                                            }}
                                            modules={[Navigation, Pagination]}
                                        >
                                            {
                                                ActivePromotions.map((res, i) => {
                                                    return (
                                                        <SwiperSlide key={i}>
                                                            <Box className='prom_cont' sx={{ display: 'flex', justifyContent: 'center', gap: '15px', my: 3 }}>
                                                                <Box sx={{ my: 'auto' }}>
                                                                    {
                                                                        res.variant_a.images.length > 0
                                                                            ?
                                                                            <>
                                                                                {
                                                                                    res.variant_a.images.slice(0, 1).map((item, o) => (
                                                                                        <img key={o}
                                                                                            className="image_tog_prom" width='120' height="120"
                                                                                            style={{ objectFit: 'contain' }} alt={`${GOOGLE_DRIVE_CDN}${item.id}`}
                                                                                            src={`${GOOGLE_DRIVE_CDN}${item.id}`}
                                                                                        />
                                                                                    ))
                                                                                }
                                                                            </>
                                                                            :
                                                                            <Box sx={{ my: 'auto' }}>
                                                                                <img className="image_tog_prom" width='120' height="120"
                                                                                    style={{ objectFit: 'contain' }} alt={'producto_nefropolis'}
                                                                                    src={no_image}
                                                                                />
                                                                            </Box>
                                                                    }
                                                                </Box>

                                                                {
                                                                    res.variant_b._id !== '' &&
                                                                    <Box sx={{ my: 'auto' }}>
                                                                        <AddIcon fontSize='small' />
                                                                    </Box>
                                                                }

                                                                {
                                                                    res.variant_b._id !== '' &&
                                                                    <Box sx={{ my: 'auto' }}>
                                                                        {
                                                                            res.variant_b.images.length > 0
                                                                                ?
                                                                                <>
                                                                                    {
                                                                                        res.variant_b.images.slice(0, 1).map((item, o) => (
                                                                                            <img key={o}
                                                                                                className="image_tog_prom" width='120' height="120"
                                                                                                style={{ objectFit: 'contain' }} alt={`${GOOGLE_DRIVE_CDN}${item.id}`}
                                                                                                src={`${GOOGLE_DRIVE_CDN}${item.id}`}
                                                                                            />
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <Box sx={{ my: 'auto' }}>
                                                                                    <img className="image_tog_prom" width='120' height="120"
                                                                                        style={{ objectFit: 'contain' }} alt={'producto_nefropolis'}
                                                                                        src={no_image}
                                                                                    />
                                                                                </Box>
                                                                        }
                                                                    </Box>
                                                                }

                                                                {
                                                                    res.variant_c._id !== '' &&
                                                                    <Box sx={{ my: 'auto' }}>
                                                                        <AddIcon fontSize='small' />
                                                                    </Box>
                                                                }

                                                                {
                                                                    res.variant_c._id !== '' &&
                                                                    <Box sx={{ my: 'auto' }}>
                                                                        {
                                                                            res.variant_c.images.length > 0
                                                                                ?
                                                                                <>
                                                                                    {
                                                                                        res.variant_c.images.slice(0, 1).map((item, o) => (
                                                                                            <img key={o}
                                                                                                className="image_tog_prom" width='120' height="120"
                                                                                                style={{ objectFit: 'contain' }} alt={`${GOOGLE_DRIVE_CDN}${item.id}`}
                                                                                                src={`${GOOGLE_DRIVE_CDN}${item.id}`}
                                                                                            />
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <Box sx={{ my: 'auto' }}>
                                                                                    <img className="image_tog_prom" width='120' height="120"
                                                                                        style={{ objectFit: 'contain' }} alt={'producto_nefropolis'}
                                                                                        src={no_image}
                                                                                    />
                                                                                </Box>
                                                                        }
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <Typography variant='body1' paragraph>
                                                                {res.info}
                                                            </Typography>
                                                            {/* {
                                                                
                                                                    disabled ?
                                                                        <LoadingButton
                                                                            loading
                                                                            loadingPosition="start"
                                                                            startIcon={<SaveIcon />}
                                                                            variant="outlined">
                                                                            Guardando
                                                                        </LoadingButton>
                                                                        :
                                                                        <Button onClick={addManyToCart}
                                                                            disabled={disabled}>
                                                                            Agregar faltantes
                                                                        </Button>
                                                                
                                                            } */}
                                                            {/* <CSButton sx={{ textTransform: 'none' }} variant='contained' disabled={disabled}>Agregar promoción</CSButton> */}
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                    </Grid>
                                    {
                                        ActivePromotions.length > 1
                                            ?
                                            <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                                                <IconButton className='promotions-swiper-button-next' size='small' disabled={ActivePromotions.length <= 1}>
                                                    <ChevronRightIcon />
                                                </IconButton>
                                            </Grid>
                                            :
                                            null
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    }
                    {
                        action === 'exceeds-stock'
                            ?
                            <Typography variant='body1' align='center' color='text.secondary' sx={{ fontWeight: '600', mt: 3 }}>
                                Hicimos ajustes en su carrito dado que supera existencias en producto. ¿Desear proceder al carrito?
                            </Typography>
                            :
                            action === "promo-limit-2x1"
                                ?
                                <Typography variant='body1' align='center' color='text.secondary' sx={{ fontWeight: '600', mt: 3 }}>
                                    Debido a promoción 2x1, mantiene sólo 2 unidades en carrito. ¿Desear proceder al carrito?
                                </Typography>
                                :
                                action === 'promo-limit-4x3'
                                    ?
                                    <Typography variant='body1' align='center' color='text.secondary' sx={{ fontWeight: '600', mt: 3 }}>
                                        Debido a promoción 4x3, mantiene sólo 4 unidades en carrito. ¿Desear proceder al carrito?
                                    </Typography>
                                    :
                                    <Typography variant='body1' align='center' color='text.secondary' sx={{ fontWeight: '600', mt: 3 }}>¿Desea proceder al carrito?</Typography>
                    }
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', mt: '30px' }}>
                        <CartButton onClick={() => { navigate('/carrito') }} variant='contained'>Ver carrito</CartButton>
                        <CSButton onClick={handleClose} variant='contained'>Seguir comprando</CSButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddToCartDialog;