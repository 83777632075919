import Swal from 'sweetalert2';

import { loginModalToggle, uiLoginCodeModal } from './ui';
import { basicMsg, msg, valMsg } from './swal_msg';
import { emptyCart, getCartItems } from './shop';

import { types } from '../types/types';
import { protectedReq, request } from "../helpers/requestHelper";

// buscar carrito de usuario iniciado
export const userSearchCart = (payload) => {
    return async(dispatch) => {
        try {
            dispatch(loggedIn(payload));
            dispatch(getCartItems(false));
        } catch (err) {
            msg('Incidente al recuperar su carrito');
        };
    };
};

// registro de usuario
export const signUp = (model) => {
    return async(dispatch) => {
        try {
            // token carrito
            const cart_token = localStorage.getItem('cart-token') || '';

            const resp = await request('user/sign-up', { ...model, cart_token }, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { op_msg } = body;
                const { token, new_cart_token, ...user } = body.resp;

                localStorage.setItem('token', token);
                localStorage.setItem('token-date', new Date().getTime());

                // carrito de compras no registrado
                if (op_msg.includes('remove-cart'))
                    localStorage.removeItem('cart-token');

                // guardar nuevo token de carrito
                if (op_msg.includes('cart-init'))
                    localStorage.setItem('cart-token', new_cart_token);

                dispatch(loggedIn({ ...user, page: model.page }));
            } else {
                if (body.msg === 'email-exists')
                    valMsg('warning', 'Registro',
                    'Ya existe un usuario con ese correo electrónico');
                else if (body.msg === 'account-not-created') {
                    localStorage.removeItem('token');
                    valMsg('warning', 'Registro',
                    'No se pudo crear la cuenta');
                } else if (body.msg === 'server-err')
                    valMsg('error', 'Registro',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else valMsg('warning', 'Registro',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            valMsg('error', 'Ocurrió un incidente',
            'No se pudo realizar el registro');
        };
    };
};

// inicio de sesión de usuario
export const login = (model) => {
    return async(dispatch) => {
        try {
            const resp = await request('user/login', model, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { op_msg } = body;
                const { token, cart_token, user } = body.resp;
                localStorage.setItem('token', token);
                localStorage.setItem('token-date', new Date().getTime());

                // guardar carrito
                if (op_msg.includes('cart-init'))
                    localStorage.setItem('cart-token', cart_token);
                
                // eliminar token de carrito
                if (op_msg.includes('cart-not-found'))
                    localStorage.removeItem('cart-token');

                dispatch(userSearchCart({ ...user, page: model.page }));
            } else {
                if (body.msg === 'not-found')
                    basicMsg('No pudimos encontrar el usuario');
                else if (body.msg === 'code-sent') {
                    basicMsg('Inicie sesión mediante el código enviado a su correo');
                    dispatch(uiLoginCodeModal(true));
                } else if (body.msg === 'not-logged-in')
                    basicMsg('No pudimos iniciar su sesión');
                else if (body.msg === 'server-err')
                    valMsg('error', 'Inicio de sesión',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else valMsg('warning', 'Inicio de sesión',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            valMsg('error', 'Inicio de sesión',
            'No se pudo iniciar sesión');
        };
    };
};

// inicio de sesión con Google
export const googleLogin = (model) => {
    return async(dispatch) => {
        try {
            const { username, ...obj } = model;
            const resp = await request('user/google-login', obj, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { token, ...user } = body.resp;
                localStorage.setItem('token', token);
                localStorage.setItem('token-date', new Date().getTime());

                // guardar carrito
                // if (op_msg.includes('cart-init'))
                //     localStorage.setItem('cart-token', cart_token);
                
                dispatch(userSearchCart({ ...user, page: 'mi-cuenta' }));
            } else {
                if (body.msg === 'not-found') {
                    Swal.fire({
                        title: '¿Desea registrarse en el sitio mediante Google?',
                        showDenyButton: true,
                        confirmButtonText: 'Registrarme',
                        denyButtonText: 'Cancelar',
                      }).then((result) => {
                        if (result.isConfirmed) {
                            dispatch(googleSignUp(model));
                        }
                    });
                } else if (body.msg === 'not-match') {
                    valMsg('warning', 'Inicio de sesión',
                    'Intente registrarse con otra cuenta');
                } else if (body.msg === 'server-err') {
                    valMsg('error', 'Inicio de sesión',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                } else valMsg('warning', 'Inicio de sesión',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            valMsg('error', 'Inicio de sesión',
            'No se pudo iniciar sesión');
        };
    };
};

// registro de usuario con Google
export const googleSignUp = (model) => {
    return async(dispatch) => {
        try {
            const resp = await request('user/google-sign-up', model, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { token, ...user } = body.resp;
                localStorage.setItem('token', token);
                localStorage.setItem('token-date', new Date().getTime());

                // guardar carrito
                // if (op_msg.includes('cart-init'))
                //     localStorage.setItem('cart-token', cart_token);

                dispatch(loggedIn({ ...user, page: 'mi-cuenta' }));
            } else {
                if (body.msg === 'email-exists') {
                    valMsg('warning', 'Registro con Google',
                    'Intente registrarse con otra cuenta');
                } else if (body.msg === 'server-err') {
                    valMsg('error', 'Registro con Google',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                } else valMsg('warning', 'Registro con Google',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            valMsg('error', 'Registro con Google',
            'No se pudo registrar en Nefrópolis');
        };
    };
};

// inicio de sesión con Facebook
export const facebookLogin = (model) => {
    return async(dispatch) => {
        try {
            const { username, ...obj } = model;
            const resp = await request('user/facebook-login', obj, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { token, ...user } = body.resp;
                localStorage.setItem('token', token);
                localStorage.setItem('token-date', new Date().getTime());
                dispatch(userSearchCart({ ...user, page: 'mi-cuenta' }));
            } else {
                if (body.msg === 'not-found') {
                    Swal.fire({
                        title: '¿Desea registrarse en el sitio mediante Facebook?',
                        showDenyButton: true,
                        confirmButtonText: 'Registrarme',
                        denyButtonText: 'Cancelar',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            dispatch(facebookSignUp(model));
                        }
                    });
                } else if (body.msg === 'not-match') {
                    valMsg('error', 'Inicio de sesión',
                    'El correo electrónico ya ha sido utilizado');
                } else if (body.msg === 'server-err') {
                    valMsg('error', 'Inicio de sesión',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                } else valMsg('warning', 'Inicio de sesión',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            valMsg('error', 'Inicio de sesión',
            'No se pudo iniciar sesión');
        };
    };
};

// registro de usuario con Facebook
export const facebookSignUp = (model) => {
    return async(dispatch) => {
        try {
            const resp = await request('user/facebook-sign-up', model, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { token, ...user } = body.resp;
                localStorage.setItem('token', token);
                localStorage.setItem('token-date', new Date().getTime());
                dispatch(loggedIn({ ...user, page: 'mi-cuenta' }));
            } else {
                if (body.msg === 'email-exists') {
                    valMsg('warning', 'Registro con Facebook',
                    'Intente registrarse con otra cuenta');
                } else if (body.msg === 'server-err') {
                    valMsg('error', 'Registro con Facebook',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                } else valMsg('warning', 'Registro con Facebook',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            valMsg('error', 'Registro con Facebook',
            'No se pudo registrar en Nefrópolis');
        };
    };
};

// validar token almacenado
export const initLoading = (first_loading) => {
    // first_loading = verifica sesión de usuario (primer comprobación)
    return async(dispatch) => {
        try {
            // token carrito
            const cart_token = localStorage.getItem('cart-token') || '';
            const resp = await protectedReq('user/renew', { first_loading, cart_token }, 'POST');
            const body = await resp.json();
            if(body.status) {
                const { front_state } = body;
                const { token, new_cart_token, ...user } = body.resp;

                // guardar token de carrito
                if (first_loading && front_state &&
                    front_state.includes('cart-init')
                )
                    localStorage.setItem('cart-token', new_cart_token);

                // eliminar token de carrito
                if (first_loading && front_state &&
                    front_state.includes('remove-cart')
                )
                    localStorage.removeItem('cart-token');
                
                // guardar sesión de usuario
                localStorage.setItem('token', token);
                localStorage.setItem('token-date', new Date().getTime());
                dispatch(loggedIn({ ...user, page: 'mi-cuenta' }));
            } else {
                const { front_state } = body;
                const { new_cart_token } = body.resp;

                if (first_loading && front_state &&
                    front_state.includes('cart-init')
                )
                    localStorage.setItem('cart-token', new_cart_token);
                
                if (first_loading && front_state &&
                    front_state.includes('remove-cart')
                )
                    localStorage.removeItem('cart-token');
                
                // no se recuperó sesión (buscar carrito)
                dispatch(loadingDone());
            }
        } catch (err) {
            dispatch(loadingDone());
        };
    };
};

// crear sesión
export const loggedIn = (payload) => ({
    type: types.userLoggedIn,
    payload
});

// limpia local storage, usuario y redirecciona a inicio
export const logout = () => {
    return async(dispatch) => {
        localStorage.removeItem('token');
        dispatch(emptyCart());
        dispatch(loggedOut());
    };
};

// cerrar sesión
const loggedOut = () => ({
    type: types.userLoggedOut
});

// token no renovado. Mostrar sitio
const loadingDone = () => ({ type: types.loadingDone });

// envío de correo para restablecer contraseña
export const resetRequest = (email) => {
    return async(dispatch) => {
        try {
            const resp = await request('user/generate-reset-request', { email }, 'POST');
            const body = await resp.json();
            if (body.status) {
                valMsg('success', 'Correo enviado',
                    'Se ha enviado un correo electrónico para reetablecer su contraseña');
                dispatch(loginModalToggle(false));
            } else {
                if (body.msg === 'no-user') {
                    valMsg('success', 'Correo enviado',
                    'Se ha enviado un correo electrónico para restablecer su contraseña');
                    dispatch(loginModalToggle(false));
                } else if (body.msg === 'still-valid') {
                    valMsg('warning', 'Solicitud enviada',
                    'Ya se ha enviado un correo electrónico para restablecer su contraseña');
                    dispatch(loginModalToggle(false));
                } else if (body.msg === 'server-err')
                    valMsg('error', 'Envío de correo',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else valMsg('warning', 'Envío de correo',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            valMsg('error', 'Envío de correo',
            'No se pudo realizar el registro');
        };
    };
};

// recupera solicitud de restablecimiento de contraseña
export const getRequest = (hash) => {
    return async(dispatch) => {
        try {
            const resp = await request('user/reset-request', { hash });
            const body = await resp.json();
            if (body.status) {
                valMsg('success', 'Correo enviado',
                    'Se ha enviado un correo electrónico para restablecer su contraseña');
                dispatch(loginModalToggle(false));
            } else {
                if (body.msg === 'no-user') {
                    valMsg('success', 'Correo enviado',
                    'Se ha enviado un correo electrónico para restablecer su contraseña');
                    dispatch(loginModalToggle(false));
                } else if (body.msg === 'still-valid') {
                    valMsg('warning', 'Solicitud enviada',
                    'Ya se ha enviado un correo electrónico para restablecer su contraseña');
                    dispatch(loginModalToggle(false));
                } else if (body.msg === 'server-err')
                    valMsg('error', 'Envío de correo',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else valMsg('warning', 'Envío de correo',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            valMsg('error', 'Envío de correo',
            'No se pudo realizar el registro');
        };
    };
};

// limpiar página de redirección
export const clearRdrPage = () => ({ type: types.clearRdrPage });


// enviar código de 6 dígitos para inicio de sesión
export const sentLoginCode = async(email) => {
    try {
        const resp = await request('user/sent-code', { email }, 'POST');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'account-not-found')
                basicMsg('No hay una cuenta asociada al correo ingresado');
            else if (body.msg === 'miss')
                basicMsg('Introduzca su correo electrónico');
            else if (body.msg === 'no-req-done')
                valMsg('warning', 'Código de acceso no generado',
                'Intente de nuevo, por favor');
            else if (body.msg === 'email-not-send')
                valMsg('warning', 'Código de acceso no enviado',
                'Intente de nuevo, por favor');
            else if (body.msg === 'server-err')
                valMsg('error', 'Código de acceso',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else basicMsg('Código de acceso no generado');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Código de acceso',
        'No se pudo generar su código de acceso');
        return false;
    };
};

// iniciar sesión mediante código
export const codeLogin = (code, email, page, setDialogData) => {
    return async(dispatch) => {
        try {
            const resp = await request('user/code-login', { code, email }, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { op_msg } = body;
                const { token, cart_token, user } = body.resp;
                localStorage.setItem('token', token);
                localStorage.setItem('token-date', new Date().getTime());
                
                // guardar carrito
                if (op_msg.includes('cart-init'))
                    localStorage.setItem('cart-token', cart_token);
                
                // eliminar token de carrito
                if (op_msg.includes('cart-not-found'))
                    localStorage.removeItem('cart-token');

                dispatch(userSearchCart({ ...user, page }));
            } else {
                if (body.msg === 'no-req-found')
                    basicMsg('Código no es correcto o ha expirado');
                else if (body.msg === 'account-not-found')
                    basicMsg('No se ha encontrado su cuenta');
                else if (body.msg === 'req-not-upd')
                    basicMsg('No se pudo iniciar sesión');
                else if (body.msg === 'server-err')
                    valMsg('error', 'Acceso mediante código',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else
                    valMsg('warning', 'Acceso mediante código',
                    'No ha iniciado sesión');
            }
            setDialogData(e => ({
                ...e, loading2: false
            }));
        } catch (err) {
            valMsg('error', 'Acceso mediante código',
            'No se pudo iniciar sesión');
            setDialogData(e => ({
                ...e, loading2: false
            }));
        };
    };
};