import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

import {
    ListItemButton, Collapse, List, Divider,
    ListItemIcon, ListItemText
} from '@mui/material';
import ShopIcon from '@mui/icons-material/Shop';
import CategoryIcon from '@mui/icons-material/Category';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InventoryIcon from '@mui/icons-material/Inventory';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

const links = [
    {
        title: 'ONL',
        url: 'productos',
        icon: ShopIcon
    },
    {
        title: 'Odoo',
        url: 'productos-odoo',
        icon: ShopIcon
    },
    {
        title: 'Categorías',
        url: 'categorias-producto',
        icon: CategoryIcon
    },
    {
        title: 'Marcas',
        url: 'marca-producto',
        icon: CorporateFareIcon
    },
    {
        title: 'Ordenamiento',
        url: 'orden-productos',
        icon: ViewModuleIcon
    }
];

const ProductListItem = ({ props }) => {
    const [collapse, setCollapse] = useState(false);
    const handleClick = () => {
        setCollapse(!collapse);
    };

    return (
        <>
            <ListItemButton onClick={ handleClick }
                sx={[
                    {
                        bgcolor: '#F2F3F4',
                        borderBottom: '1px solid #F2F3F4'
                    },
                    {
                        '&:hover': {
                            backgroundColor: '#EAEDED'
                        }
                    },
                ]}
            >
                <ListItemIcon>
                    <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary="Productos" />
                    { collapse ? <ExpandLess /> : <ExpandMore /> }
            </ListItemButton>
            <Collapse in={ !collapse } timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        links.map(({ title, url, icon }) => {
                            const Icon = icon;
                            return (
                                <React.Fragment key={url}>
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        onClick={ props.open }
                                        component={ Link }
                                        to={"/nefro-admin/" + url}>
                                        <ListItemIcon>
                                        {
                                            title === 'Odoo' ?
                                                <img
                                                    src={ `${GOOGLE_DRIVE_CDN}1188OKyLJf8_dG0qRZXGmdg2nYP88AR-4` }
                                                    alt="odoo"
                                                    loading="lazy"
                                                    
                                                    style={{ maxWidth: '40px', maxHeight: '100%' }}
                                                />
                                            :
                                                <Icon />
                                        }
                                        </ListItemIcon>
                                        <ListItemText primary={ title } />
                                    </ListItemButton>
                                    <Divider />
                                </React.Fragment>
                            )
                        })
                    }
                </List>
            </Collapse>
        </>
    );
};

export default ProductListItem;