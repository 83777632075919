// * CartComponent.jsx
// cantidad envío gratuito (muestra)
export const minAmount = 1500; // 3000
// cantidad para validación envío gratuito
export const FREE_SHIPPING = 1499.99; // 2999.99

// * ProductSample.js
export const prodInit = {
    _id: '',
    title: '',
    info: '',
    category: [],
    available: false, // se muestra en tienda
    refrigerated: false, // producto requiere refrigeración
    medicine: false, // producto es medicamento
    prod_variants: [], // variantes y toda su info
    reviews: [], // reseñas de clientes
    fbt_item: [] // productos comprados frecuentemente (toda su info)
};

// * ProductSample.js
export const varInit = {
    _id: '9999',
    variant_title: '',
    price: '',
    description: '',
    stock: '',
    iva: 0,
    sat_key: '',
    claveUnidad: '',
    unidad: '',
    barcode: '',
    images: [],
    item_promotion: {
        promo: '',
        price: 0,
        text: '',
        long_text: ''
    },
    checked: true
};

export const BANNER_BACKUP = {
    desktop: ['1oruAb7AhoRK7eKm_EeFC6Qd67AtaD_fN'],
    mobile: ['1j7g5sIB5hXi_awxAnWQfJ-AgKwlBi-0P'],
    tablet: ['1E2x8jGxKBkyVeYAXXGJkbz27zbOQ36VA']
};

// * ShopCategory.js
// mostrar todos los productos cuando la categoría/subcategoría se designe como:
export const ALL_CATEGORIES = 'TODAS';
export const ALL_SUBCATEGORIES = 'VER TODO';

export const CATEGORY_DEFAULT = {
    _id: '',
    category: '',
    url: '',
    subcategories: [],
    imgId: '',
    position: 0,
};

// * shopReducer.js
// * MyPaypalButtons.js
// * Tienda/Cart/ShippingMethods.js
// método de envío seleccionado (POR DEFECTO)
export const SHIPPING_METHOD = {
    _id: '',
    partner: '',
    cost: 0,
    cost_no_iva: 0,
    description: '',
    delivery_time: '',
    delivery_days: [],
    for_refrigeration: false,
    odoo_id: 0,
    shippingMethodTitle: ''
};

// * shopReducer.js
// resumen de carrito
export const CART_SUMMARY= {
    promos_discount: 0,
    discount: 0,
    subtotal: 0,
    total: 0
};

export const SHIPPING_METHOD_INIT = {
    partner: '',
    cost: '',
    delivery_time: '',
    description: 'Guía de Envío',
    min_day: 1,
    max_day: 2,
    odoo_id: '',
    for_refrigeration: false
};