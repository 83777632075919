import React from 'react';

import CategoryStack from './CategoryStack';

import {
    Dialog, DialogTitle, DialogContent
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CategoryDialog = ({ open, setOpen }) => {
    return (
        <Dialog
            open={ open }
            onClose={ () => setOpen(false) }
            sx={{ zIndex: 1000 }}>
            <DialogTitle>
                Nueva categoría
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={ () => setOpen(false) }
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8, top: 8,
                    color: theme.palette.grey[500]
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <CategoryStack setOpen={ setOpen } />
            </DialogContent>
        </Dialog>
    );
};

export default CategoryDialog;