import React from 'react';

//CSS
import './BrandContainer.css'

//Redux
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import 'swiper/css';

//Constants
import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

//MUI
import { Link } from "@mui/material";

const BrandContainer = () => {
    const navigate = useNavigate();

    const { brands } = useSelector(state => state.shop);

    return (
        <>
            {
                brands.length > 0 && (
                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 2,
                            },
                            300: {
                                slidesPerView: 3.4,
                            },
                            375: {
                                slidesPerView: 4.2,
                            },
                            426: {
                                slidesPerView: 4.5,
                            },
                            600: {
                                slidesPerView: 5,
                            },
                            769: {
                                slidesPerView: 6,
                            },
                            1100: {
                                slidesPerView: 7,
                            },
                            1330: {
                                slidesPerView: 9,
                            },
                            1665: {
                                slidesPerView: 11,
                            },
                        }}
                        spaceBetween={7}
                        autoplay={{
                            delay: 6000,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Autoplay]}
                    >
                        {
                            brands.map(({ url, brand, imgId }, i) =>
                                <SwiperSlide key={i} style={{paddingBottom: 0}}>
                                    <Link component="button"
                                        onClick={() => navigate(`marca/${url}`)}>
                                        <img className='brand_category_img'
                                            width='100%'
                                            src={`${GOOGLE_DRIVE_CDN}${imgId}`}
                                            alt={`imagen-${url}`} />
                                    </Link>
                                    {/* <Typography variant='body2' align='center' sx={{ mt: .8 }}>
                                        {brand}
                                    </Typography> */}
                                </SwiperSlide>
                            )
                        }
                    </Swiper>
                )
            }
        </>
    );
};

export default BrandContainer;