import { types } from '../types/types';

const active = {
    _id: '',
    email: '',
    username: '',
    role: '',
    privileges: {
        products: false,
        coupons: false,
        clients: false,
        orders: false,
        admins: false
    }
};

const initialState = {
    admins: [],
    orders: [],
    activeAdmin: active
};

export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.adminListLoaded:
            return {
                ...state,
                admins: [...action.payload]
            };
        case types.adminSelected:
            return {
                ...state,
                activeAdmin: action.payload
            };
        case types.adminRegistered:
            return {
                ...state,
                admins: [
                    ...state.admins,
                    action.payload
                ]
            };
        case types.adminUpdated:
            return {
                ...state,
                admins: state.admins.map(
                    e => (e._id === action.payload._id) ? action.payload : e
                ),
            };
        case types.adminDeleted:
            return {
                ...state,
                admins: state.admins.filter(
                    e => (e._id !== action.payload)
                ),
            };
        case types.adminOrders:
            return {
                ...state,
                orders: action.payload
            };
        default:
            return state;
    }
};