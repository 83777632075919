import React from 'react'

//Redux
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Constants
import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

//MUI
import { Typography, Grid, Link, useMediaQuery } from "@mui/material";

const CategoryContainer = () => {

    const matches = useMediaQuery('(max-width: 500px)')

    const navigate = useNavigate();

    const { categories } = useSelector(state => state.shop);

    return (
        <>
            {/* Slider categorias de busqueda */}
            <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                {
                    categories.map(({ url, category, imgId }, i) =>
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={6} key={i}>
                            <Link component="button"
                                onClick={() => navigate(`/tienda/categoria/${url}`)}>
                                <img className='category_img'
                                    src={`${GOOGLE_DRIVE_CDN}${imgId}`}
                                    alt={`imagen-${category}`} />
                            </Link>
                            <Typography variant='body2' align='center' sx={{ mt: .8, fontSize: matches ? '12px' : '14px' }}>
                                {category}
                            </Typography>
                        </Grid>
                    )
                }
            </Grid>
        </>
    );
};

export default CategoryContainer;