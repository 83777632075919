import { types } from '../types/types';

import { ACTIVE_PRODUCT } from '../constants/admin/product_const';

const varActive = {
    // prod_id: '',
    _id: '',
    variant_title: '',
    price: '',
    description: '',
    stock: '',
    iva: '',
    sat_key: '',
    claveUnidad: '',
    unidad: '',
    barcode: '',
    available: true,
    images: [],
    batches: [],
    promotion: {
        promo: '',
        price: 0,
        text: ''
    }
};

const initialState = {
    products: [],
    activeVariants: {
        prod_id: '',
        folderId: '',
        variants: []
    },
    active: ACTIVE_PRODUCT,
    modal: false,
    var_active: varActive,
    var_modal: false,
    // loading: false,
    snackbar: {
        open: false,
        message: ''
    }
};

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.productListLoaded:
            return {
                ...state,
                products: [...action.payload]
            }
        case types.productDefault:
            return {
                ...state,
                snackbar: {
                    open: false,
                    message: ''
                }
            }
        case types.productSnackbarOpen:
            return {
                ...state,
                snackbar: {
                    open: true,
                    message: 'Imagen subida'
                },
            }
        case types.productSnackbarClose:
            return {
                ...state,
                snackbar: {
                    open: false,
                    message: ''
                },
            }
        case types.productActive:
            return {
                ...state,
                active: action.payload
            }
        case types.productEdit:
            return {
                ...state,
                modal: true
            }
        case types.productUpdated:
            return {
                ...state,
                products: state.products.map(
                    e => (e._id === action.payload._id) ? ({ ...e, ...action.payload }) : e
                ),
                modal: false
            }
        case types.productDeleted:
            return {
                ...state,
                activeVariants: {
                    prod_id: '', title: '',
                    folderId: '', variants: []
                },
                active: ACTIVE_PRODUCT,
                products: state.products.filter(
                    e => (e._id !== action.payload)
                ),
                modal: false
            }
        case types.productCloseModal:
            return {
                ...state,
                active: ACTIVE_PRODUCT,
                modal: false
            }
        case types.activeVariants:
            return {
                ...state,
                activeVariants: action.payload
            }
        case types.variantEdit:
            return {
                ...state,
                var_active: action.payload,
                var_modal: true
            }
        case types.variantSaved:
            return {
                ...state,
                activeVariants: {
                    ...state.activeVariants,
                    variants: [...state.activeVariants.variants, action.payload]
                },
                products: state.products.map(e => (e._id === state.activeVariants.prod_id)
                ? {...e, prod_variants: [...e.prod_variants, action.payload] }
                : e)
            }
        case types.variantUpdated:
            return {
                ...state,
                activeVariants: {
                    ...state.activeVariants,
                    variants: state.activeVariants.variants.map(
                    e => (e._id === action.payload._id) ?
                        { ...action.payload }
                    : e)
                },
                products: state.products.map(
                    e => ({
                        ...e,
                        prod_variants: e.prod_variants.map(i => (i._id === action.payload._id) ?
                        ({ ...i, ...action.payload }) : i)
                    })
                ),
                var_modal: false
            }
        case types.variantDeleted:
            return {
                ...state,
                activeVariants: {
                    ...state.activeVariants,
                    variants: state.activeVariants.variants.filter(
                    e => (e._id !== action.payload))
                },
                products: state.products.map(
                    e => ({
                        ...e,
                        prod_variants: e.prod_variants.filter(i => (i._id !== action.payload))
                    })
                ),
            }
        case types.variantCloseModal:
            return {
                ...state,
                var_active: varActive,
                var_modal: false
            }
        default:
            return state;
    }
}