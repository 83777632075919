import React from 'react';
import { useSelector } from 'react-redux';

import ContactForm from './ContactForm';
import logo from '../../assets/images/Logo_Nefropolis.png';

//MUI
import {
    Box, Grid, CircularProgress, Backdrop
} from '@mui/material';

const Contact = () => {
    const { form1Loading } = useSelector(state => state.form);

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={ form1Loading } >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={4}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ textAlign: 'center', my: 'auto' }}
                    order={{ xl: 1, lg: 1, md: 1, sm: 2, xs: 2 }}
                >
                    <Box>
                        <img src={logo} alt={logo} width='45%' />
                    </Box>
                    {/* <Box sx={{ mt: 3 }}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: '#56C596' }}>Ventas:</Typography>
                        <Box display='flex' justifyContent='center' gap='5px' mb={3}>
                            <WhatsAppIcon sx={{ color: '#38C53D' }} />
                            <Box>
                                <Typography variant='body1'>33 2496 6190</Typography>
                                <Typography variant='body1'>33 3826 8009</Typography>
                            </Box>
                        </Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: '#56C596' }}>Mail:</Typography>
                        <Typography variant='body1'>{ CORREO_CLIENTES }</Typography>
                    </Box> */}
                </Grid>
                <ContactForm
                    txt={ '¿Estas interesado en alguno de nuestros productos?' }
                />
            </Grid>
        </>
    );
};

export default Contact;