import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { codeLogin, sentLoginCode } from '../../../actions/user';

import {
    Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Button, TextField, Stack
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import SaveIcon from '@mui/icons-material/Save';

import { regex_num } from '../../../constants/regex';

const CodeLoginModal = ({
    dialogData, setDialogData, eInputRef, email, email_err, handleChange
}) => {
    const dispatch = useDispatch();
    const { user: { code_sent } } = useSelector(state => state.ui);

    const { sent, status, title, text, loading1, loading2 } = dialogData;
    const [codeData, setCodeData] = useState({
        code: '', code_err: false
    });
    const { code, code_err } = codeData;

    // referencias a campos
    const codeInputRef = useRef();

    useEffect(() => {
        if (code_sent)
            setDialogData(e => ({
                ...e,
                status: true,
                sent: true,
                loading1: false,
                title: 'Acceso con código',
                text: 'Ingresa el código de 6 dígitos que recibiste en tu correo electrónico. NO LO COMPARTAS CON NADIE'
            }));
    }, [code_sent]);

    const sendCodeToEmail = async () => {
        setDialogData(e => ({
            ...e, loading1: true
        }));
        const req = await sentLoginCode(email);
        if (req.status) {
            setDialogData(e => ({
                ...e, sent: true,
                loading1: false,
                title: 'Acceso con código',
                text: 'Ingresa el código de 6 dígitos que recibiste en tu correo electrónico. NO LO COMPARTAS CON NADIE'
            }));
        }
    };

    const loginWithCode = async () => {
        setDialogData(e => ({
            ...e, loading2: true
        }));
        if (code.length === 6)
            dispatch(codeLogin(code, email, 'mi-cuenta', setDialogData));
        else
            codeInputRef.current.select();
    };

    // cambios campo de código
    const handleInputChange = ({ target }) => {
        const value = target.value;
        if (value.length <= 6 && regex_num.test(value))
            setCodeData(e => ({
                ...e, code: value,
                code_err: (value.length !== 6) ? true : false
            }));
        else
            setCodeData(e => ({
                ...e,
                code_err: (value.length !== 6) ? true : false
            }));
    };

    return (
        <Dialog open={ status }
            onClose={ () => setDialogData(e => ({ ...e, status: false })) }
            sx={{ zIndex: 1000 }}>
            <DialogTitle>{ title }</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { text }
                </DialogContentText>
                {
                    !sent ?
                        <Stack spacing={2} sx={{ mt: 2 }}
                            alignItems='flex-start'>
                            <TextField fullWidth
                                label="Correo electrónico"
                                type="email"
                                variant="standard"
                                error={ email_err }
                                helperText={ email_err ? "Ingrese su correo electrónico" : "" }
                                inputRef={ eInputRef }
                                name="email"
                                value={ email }
                                onChange={ handleChange }
                            />
                            {
                                loading1 ?
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="outlined">
                                        Generando...
                                    </LoadingButton>
                                :
                                    <Button variant='outlined'
                                        onClick={ sendCodeToEmail }>
                                        Enviar código
                                    </Button>
                            }
                        </Stack>
                    :
                        <Stack spacing={2} sx={{ mt: 2 }}
                            alignItems='flex-start'>
                            <TextField fullWidth
                                label="Código de acceso"
                                type="code"
                                variant="standard"
                                error={ code_err }
                                helperText={ code_err ? "6 dígitos" : "" }
                                inputRef={ eInputRef }
                                name="code"
                                value={ code }
                                onChange={ handleInputChange }
                            />
                            {
                                loading2 ?
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="outlined">
                                        Iniciando...
                                    </LoadingButton>
                                :
                                    <Button variant='outlined'
                                        onClick={ loginWithCode }>
                                        Acceder
                                    </Button>
                            }
                        </Stack>
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={ () => setDialogData(e => ({ ...e, status: false })) }>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CodeLoginModal;