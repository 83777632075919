import React from 'react';

import AdditionalImagesContainer from './Images/AdditionalImagesContainer';

import { Grid, Typography, Divider, TextField } from '@mui/material';


const ProductInfo = ({
    info, handleInputChange, images, additionalImages, setAdditionalImages
}) => {
    return (
        <>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Información adicional
                    </Typography>
                    <TextField
                        fullWidth sx={{ mb: 2 }}
                        name="info"
                        label="Información adicional"
                        variant="outlined"
                        multiline
                        rows={7.7}
                        value={ info }
                        inputProps={{ maxLength: 2000 }}
                        onChange={ handleInputChange }
                    />
                    <Typography variant="subtitle1" gutterBottom>
                        Imágenes adicionales
                    </Typography>
                    <AdditionalImagesContainer
                        images={ images }
                        additionalImages={ additionalImages }
                        setAdditionalImages={ setAdditionalImages }
                    />
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
        </>
    );
};

export default ProductInfo;