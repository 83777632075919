import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';

import Swal from 'sweetalert2';
import { PayPalButtons } from "@paypal/react-paypal-js";

import { emptyCart } from '../../../../../actions/shop';
import { updatePaypalOrder } from '../../../../../actions/payment';
import { shippingMethodSelected } from '../../../../../actions/shop_shipping';

import { SHIPPING_METHOD } from '../../../../../constants/shop/shop_constants';

const tagManagerArgs = {
    gtmId: 'GTM-KQMW2JG'
};
TagManager.initialize(tagManagerArgs);

const MyPaypalButtons = ({ paymentValues }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { cart: { summary: { total } } } = useSelector(state => state.shop);
	const { order_id, nefro_reference } = paymentValues;

	// generar orden de PayPal
	const createOrder = (data, actions) => {
		return actions.order
		.create({
			purchase_units: [
			  	{
				  	description: "Compra en Tienda Nefrópolis.",
				  	amount: {
				  		currency_code: "MXN",
				  		value: total,
					},
			  	},
			],
			items: [
                {
					name: 'Producto(s) Nefrópolis',
					quantity: '1',
                  	category: 'DIGITAL_GOODS',
				  	description: '',
                },
            ],
		})
		.then((orderID) => {
			return orderID;
		});
	};
	const onApprove = (data, actions) => {
		// Tag Manager
		window.dataLayer.push({
			event: 'nefro-order'
		});
		return actions.order.capture().then(async function (details) {
			const resp = await updatePaypalOrder(
				data.orderID,
				order_id);
			if (resp.status) {
				dispatch(emptyCart());
				dispatch(shippingMethodSelected({
					selected: SHIPPING_METHOD,
					radio_button: null
				}));
				navigate(`/gracias-por-tu-compra/${nefro_reference}`);
			}
		});
	};
	const onError = (data, actions) => {
		Swal.fire({
			title: 'Checkout',
			icon: 'warning',
			html: `<h6>Parece que su pago no ha sido aprobado. Si se trata de un error, comuníquese con nosotros al correo: ecommerce@nefropolis.com</h6>`,
			focusConfirm: true,
			confirmButtonText: 'Entendido',
		});
	};

    return (
		<PayPalButtons
			style={{ layout: "vertical" }}
           	createOrder={ createOrder }
			onApprove={ onApprove }
			onError={ onError }
        />
    );
};

export default MyPaypalButtons;