import React from 'react';
import { useSelector } from 'react-redux';

import {
    Grid, List, ListItem, Typography, ListItemText, ListItemButton,
    Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BrandSidebar = ({ onBrandSelected }) => {
    // marcas de producto
    const { brands } = useSelector(state => state.shop);

    const [expanded, setExpanded] = React.useState('panel1');
    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Grid item xl={2} lg={3} md={12} sm={12} xs={12}>
            <Accordion expanded={ expanded === 'panel1' }
                onChange={ handleChangeAccordion('panel1') }>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Marcas</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0' }}>
                    <List>
                        {
                            brands.map(({ brand, url }) =>
                                <ListItem disablePadding key={brand}
                                    onClick={ onBrandSelected(brand, url) }>
                                    <ListItemButton component="a">
                                        <ListItemText secondary={ brand } />
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                            <ListItem disablePadding
                                onClick={ onBrandSelected('TODAS', 'todas') }>
                                <ListItemButton component="a">
                                    <ListItemText secondary="TODAS" />
                                </ListItemButton>
                            </ListItem>
                    </List>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};

export default BrandSidebar;