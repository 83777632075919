import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { logout } from '../../../actions/user';
import { basicMsg } from '../../../actions/swal_msg';
import { verifiyPwd } from '../../../actions/user-account';

import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Button, FormControl, InputLabel, OutlinedInput, InputAdornment,
    IconButton
} from '@mui/material';

const PasswordDialog = ({
    dialog1, setFieldStatus, mainPassword, setMainPassword
}) => {
    const dispatch = useDispatch();
    const [showPwd, setShowPwd] = useState(false);
    const [fieldErr, setFieldErr] = useState(false);

    const handleOnChange = ({ target }) => {
        const value = target.value;
        if (value === '' || value.length < 65)
            setMainPassword(target.value);
        setFieldErr(
            (value !== '' && (value.length < 5 || value.length > 64)) ?
            true : false
        );
    };

    // verifica si el password es correcto, si lo es activa los inputs y muestra el botón para guardar los cambios
    const verifyPwd = async (e) => {
        e.preventDefault();
        if (mainPassword.length > 4 && mainPassword.length <= 64) {
            const req = await verifiyPwd(mainPassword);
            if (req.status) {
                // activa los inputs de información personal
                // muestra el botón "Guardar cambios"
                // cierra el Dialog
                // desactiva el boton para editar la información
                setFieldStatus(e => ({
                    ...e,
                    dialog1: false,
                    pwd_button: true,
                    pwd_form: true
                }));
                setMainPassword('');
            } else {
                if (['no-token', 'not-logged-in', 'unavailable-token', 'invalid-token'].includes(req.msg)) {
                    basicMsg("Su sesión expiró");
                    dispatch(logout());
                }
            }
        } else
            basicMsg("Ingrese su contraseña");
    };

    const closeDialog = () => {
        setFieldStatus(e => ({
            ...e,
            dialog1: false
        }));
        setMainPassword('');
    };

    return (
        <Dialog open={ dialog1 }
            onClose={ closeDialog }
            sx={{ zIndex: 1000 }}>
            <DialogTitle>Contraseña</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Para poder cambiar su información personal ingrese su contraseña actual
                </DialogContentText>
                {/* Form para poder acceder al cambio de información personal Requiere autenticación de contraseña */}
                <form action="" id="form-dialog" onSubmit={ verifyPwd }>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel>Contraseña</InputLabel>
                        <OutlinedInput
                            type={ showPwd ? 'text' : 'password' }
                            placeholder="Contraseña"
                            value={ mainPassword }
                            onChange={ handleOnChange }
                            style={{ backgroundColor: '#fff' }}
                            error={ fieldErr }
                            inputProps={{ maxLength: 64 }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <i className="fa-solid fa-key"></i>
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={ () => setShowPwd(!showPwd) }
                                        edge="end"
                                    >
                                        {
                                            showPwd ?
                                                <i className="far fa-eye-slash" style={{ fontSize: '1.3rem' }}></i>
                                            :
                                                <i className="far fa-eye" style={{ fontSize: '1.3rem' }}></i>
                                        }
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña"
                        />
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={ closeDialog }>
                    Cancelar
                </Button>
                {/* button para verificar si la contraseña es correcta */}
                <Button type="submit" form="form-dialog">
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PasswordDialog;