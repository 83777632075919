import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import './ProductCategory.css';
import ProductCategoryList from './ProductCategoryList';

import { basicMsg } from '../../../../actions/swal_msg';
import { deleteCategories, updateAllCategories } from '../../../../actions/category';

import {
    List, ListItem, ListItemText, ListItemButton, ListItemAvatar,
    Avatar, Alert, Stack, Typography, Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UpgradeIcon from '@mui/icons-material/Upgrade';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

const ProductCategories = () => {
    const dispatch = useDispatch();
    // categorías de producto
    const { categories } = useSelector(state => state.category);
    
    // categorías
    const [allCategories, setCategories] = useState([]);
    // categorías removidas
    const [deleted, setDeleted] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCategories(categories.map(e => ({ ...e, checked: false })));
        setDeleted([]);
    }, [categories]);

    // eliminar una o más categorías
    const deleteMany = () => {
        if (deleted.length > 0)
            dispatch(deleteCategories(deleted));
    };

    const updateAll = async () => {
        if (allCategories.length <= 0)
            basicMsg('No hay categorías disponibles');
        else {
            setLoading(true);
            dispatch(updateAllCategories(allCategories, setLoading));
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = reorder(
            allCategories,
            result.source.index,
            result.destination.index
        );
      
        setCategories(items);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };
    
    return (
        <div>
            {
                allCategories.length > 0 ?
                    <Typography variant="h6" gutterBottom>
                        LISTA DE CATEGORÍAS
                    </Typography>
                :
                    <Alert severity="info">No hay categorías de producto guardadas</Alert>
            }
            
            {
                allCategories.length > 0 &&
                    <LoadingButton fullWidth
                        sx={{ mt: 2 }}
                        endIcon={<UpgradeIcon />}
                        loading={ loading }
                        loadingPosition="end"
                        variant="contained"
                        onClick={ updateAll }>
                        Actualizar categorías existentes
                    </LoadingButton>
            }
            
            <DragDropContext
                onDragEnd={ (result) => onDragEnd(result) }>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                        {
                            allCategories.map((category, index) => (
                                <ProductCategoryList
                                    key={ category._id }
                                    _category={ category }
                                    categories={ allCategories }
                                    setCategories={ setCategories }
                                    setDeleted={ setDeleted }
                                    index={ index }
                                />
                            ))
                        }
                        { provided.placeholder }
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            {
                deleted.length > 0 &&
                    <Stack sx={{ mt: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Categorías a eliminar:
                        </Typography>
                        <List>
                            {
                                deleted.map(({ _id, imgId, category }) =>
                                    <ListItem disablePadding key={ _id }>
                                        <ListItemButton>
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp"
                                                    src={`${GOOGLE_DRIVE_CDN}${imgId}`} />
                                            </ListItemAvatar>
                                            <ListItemText primary={ category } />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }
                        </List>
                        <Button variant="contained"
                            onClick={ deleteMany } color="error">
                            Eliminar
                        </Button>
                    </Stack>
            }
        </div>
    );
};

export default ProductCategories;