import React from 'react';

// constantes
import { format } from 'date-fns';

//MUI
import { Avatar, Divider, List, ListItem, ListItemText, Tooltip } from '@mui/material';
// import CustomDatePicker from '../components/Admin/Pedidos/CustomDatePicker';
// import CustomFilter from '../components/Admin/Pedidos/CustomFilter';
// import CloseIcon from '@mui/icons-material/Close';
// import CheckIcon from '@mui/icons-material/Check';

export const NO_IMG = '1GT-awi1U8MEOk6FyVzO2pJad-Y0j3UYj';
export const PRODUCT_NO_IMG = '1oo8-rZY91k2op5VR2PWYzbhO10IUwjEH';

// export const GOOGLE_DRIVE_CDN = 'https://drive.google.com/thumbnail?id=';
// export const GOOGLE_DRIVE_CDN = 'https://drive.google.com/uc?export=view&id=';
export const GOOGLE_DRIVE_CDN = 'https://lh3.googleusercontent.com/d/';

export const CUSTOMER_EMAIL = 'ecommerce@nefropolis.com';

// NewProduct.js
export const variantInit = {
    key: 0,
    variant_title: '',
    price: '',
    description: '',
    stock: '',
    iva: 0,
    sat_key: '',
    claveUnidad: '',
    unidad: '',
    barcode: '',
    odoo_id: '',
    available: true,
    images: [],
    batches: []
};

// NewProduct.js
export const productInit = {
    title: '',
    url: '',
    info: '',
    additional_images: [],
    category: [],
    category_url: [],
    subcategory: [],
    prod_category: [],
    brand: '',
    featured: false,
    refrigerated: false,
    medicine: false,
    keywords: [],
    reviews: [],
    variants: [{
        ...variantInit
    }]
};

// VariantAdd.js
export const variantInit2 = {
    variant_title: '',
    price: '',
    description: '',
    stock: '',
    iva: 0,
    sat_key: '',
    claveUnidad: '',
    unidad: '',
    barcode: '',
    odoo_id: '',
    available: true,
    images: [],
    batches: []
};

// valores por defecto Total de ventas (DashboardComponent.jsx)
export const salesDefault = {
    total: 0,
    qty: 0
};

// datos Nuevos Clientes (primer semestre, DashboardComponent.jsx)
export const first_sem_data = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
    datasets: [
        {
            label: 'Clientes nuevos',
            data: [0, 0, 0, 0, 0, 0],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
    ],
};

// datos Nuevos Clientes (segundo semestre, DashboardComponent.jsx)
export const second_sem_data = {
    labels: ['Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    datasets: [
        {
            label: 'Clientes nuevos',
            data: [0, 0, 0, 0, 0, 0],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
    ],
};

// data Top 5 estados (DashboardComponent.jsx)
export const stateRows = [
    { id: 1, state: 'Sin ventas', sales: '0' },
];

// data gráfico de estados (DashboardComponent.jsx)
export const MexStatesDefault = {
    "Baja California": {
        "total": 0,
        "sales": 0
    },
    "Baja California Sur": {
        "total": 0,
        "sales": 0
    },
    "Coahuila": {
        "total": 0,
        "sales": 0
    },
    "Chihuahua": {
        "total": 0,
        "sales": 0
    },
    "Durango": {
        "total": 0,
        "sales": 0
    },
    "Sinaloa": {
        "total": 0,
        "sales": 0
    },
    "Sonora": {
        "total": 0,
        "sales": 0
    },
    "Zacatecas": {
        "total": 0,
        "sales": 0
    },
    "Nuevo León": {
        "total": 0,
        "sales": 0
    },
    "San Luis Potosí": {
        "total": 0,
        "sales": 0
    },
    "Tamaulipas": {
        "total": 0,
        "sales": 0
    },
    "Aguascalientes": {
        "total": 0,
        "sales": 0
    },
    "Colima": {
        "total": 0,
        "sales": 0
    },
    "Jalisco": {
        "total": 0,
        "sales": 0
    },
    "Michoacán": {
        "total": 0,
        "sales": 0
    },
    "Nayarit": {
        "total": 0,
        "sales": 0
    },
    "Campeche": {
        "total": 0,
        "sales": 0
    },
    "Oaxaca": {
        "total": 0,
        "sales": 0
    },
    "Puebla": {
        "total": 0,
        "sales": 0
    },
    "Tabasco": {
        "total": 0,
        "sales": 0
    },
    "Tlaxcala": {
        "total": 0,
        "sales": 0
    },
    "Guanajuato": {
        "total": 0,
        "sales": 0
    },
    "Guerrero": {
        "total": 0,
        "sales": 0
    },
    "Hidalgo": {
        "total": 0,
        "sales": 0
    },
    "Ciudad de México": {
        "total": 0,
        "sales": 0
    },
    "Morelos": {
        "total": 0,
        "sales": 0
    },
    "Querétaro": {
        "total": 0,
        "sales": 0
    },
    "Veracruz": {
        "total": 0,
        "sales": 0
    },
    "Chiapas": {
        "total": 0,
        "sales": 0
    },
    "Quintana Roo": {
        "total": 0,
        "sales": 0
    },
    "Yucatán": {
        "total": 0,
        "sales": 0
    },
};

// data Top 10 clientes frecuentes (DashboardComponent.jsx)
export const clientDataRows = [
    { id: 1, name: 'No hay clientes', lastname: '', email: '', purchases: '0' },
];

// datos iniciales gráfica Ticket Promedio (DashboardComponent.jsx)
export const avgTicketDefault = {
    general_ticket: [
        //Datos para tabla
        { mes: "Enero", ganancia: '$' + 0.0 },
        { mes: "Febrero", ganancia: '$' + 0.0 },
        { mes: "Marzo", ganancia: '$' + 0.0 },
        { mes: "Abril", ganancia: '$' + 0.0 },
        { mes: "Mayo", ganancia: '$' + 0.0 },
        { mes: "Junio", ganancia: '$' + 0.0 },
        { mes: "Julio", ganancia: '$' + 0.0 },
        { mes: "Agosto", ganancia: '$' + 0.0 },
        { mes: "Septiembre", ganancia: '$' + 0.0 },
        { mes: "Octubre", ganancia: '$' + 0.0 },
        { mes: "Noviembre", ganancia: '$' + 0.0 },
        { mes: "Diciembre", ganancia: '$' + 0.0 }
    ],
    clients_ticket: [
        { mes: "Enero", ganancia: '$' + 0.0 },
        { mes: "Febrero", ganancia: '$' + 0.0 },
        { mes: "Marzo", ganancia: '$' + 0.0 },
        { mes: "Abril", ganancia: '$' + 0.0 },
        { mes: "Mayo", ganancia: '$' + 0.0 },
        { mes: "Junio", ganancia: '$' + 0.0 },
        { mes: "Julio", ganancia: '$' + 0.0 },
        { mes: "Agosto", ganancia: '$' + 0.0 },
        { mes: "Septiembre", ganancia: '$' + 0.0 },
        { mes: "Octubre", ganancia: '$' + 0.0 },
        { mes: "Noviembre", ganancia: '$' + 0.0 },
        { mes: "Diciembre", ganancia: '$' + 0.0 }
    ]
};

// labels gráfica
const labels = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

// datos iniciales tabla Ticket Promedio (DashboardComponent.jsx)
export const ticketGraphDefault = {
    clients_ticket_data: {
        labels,
        datasets: [
            {
                label: 'Cantidad en $',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    },
    general_ticket_data: {
        labels,
        datasets: [
            {
                label: 'Cantidad en $',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    },
};

export const ODOO_PRODUCT_COLUMNS = [
    {
        title: "ID",
        field: "id",
        filtering: false
    },
    {
        title: "Nombre",
        field: "name",
        filtering: false
    },
    {
        title: "Precio (antes de IVA)",
        field: "list_price",
        filtering: false
    },
    {
        title: "IVA %",
        field: "iva",
        filtering: false
    },
    {
        title: "Existencias Odoo",
        field: "stock",
        filtering: false
    },
    {
        title: "Existencias ONL",
        field: "onl_stock",
        filtering: false
    },
    // {
    //     title: "Costo de Compra",
    //     field: "standard_price",
    //     filtering: false
    // }
];

export const CHECKOUT_SHIPPING = {
    name: '',
    lastname: '',
    email: '',
    tel: '',
    street: '',
    ext_num: '',
    int_num: '',
    crossing_streets: '',
    postalcode: '',
    colony: '',
    city: '',
    state: '',
    references: '',
    residence_type: '',
    residence_type_select: ''
};

export const CHECKOUT_BILLING = {
    req_invoice: false, // requiere factura
    business_name: '',
    rfc: '',
    cif: '',
    filename: '',
    tax_system: '',
    cfdi: '',
    billing_tel: '',
    address: '',
    billing_postalcode: '',
    billing_colony: '',
    billing_city: '',
    billing_state: ''
};

export const CHECKOUT_PAYMENT_ID = {
    payment_method: '',
    // session_id: '', // EvoPayments
    order_id: '', // PayPal
    preference_id: '', // MercadoPago
    // sandbox_init_point: '',
    redirect_url: '', // OpenPay
    nefro_reference: ''
};

// PersonalInfoCheckoutComponent.jsx
export const CHECKOUT_DISABLED_INPUT = {
    cityInput: false,
    stateInput: false,
    billCityInput: false,
    billStateInput: false
};

// PersonalInfoCheckoutComponent.jsx
export const RESIDENCE_TYPE = ['Casa / Departamento', 'Negocio', 'Edificio', 'Oficina', 'Otro'];

// AdminPedidos.jsx / PedidoAbandonado.js / CarritoAbandonadoTable.js
export const adminOrder = {
    id: '',
    order_num: '9999',
    order_date: `${format(new Date(), 'dd/MM/yyyy')}`,
    products: [],
    summary: {
        discount: 0,
        subtotal: 0,
        total: 0
    },
    coupon: {
        _id: '',
        name: '',
        type: '',
        discount: 0
    },
    address: {
        name: '',
        lastname: '',
        email: '',
        tel: '',
        street: '',
        ext_num: '',
        int_num: '',
        crossing_streets: '',
        postalcode: '',
        colony: '',
        city: '',
        state: '',
        references: ''
    },
    billing: {
        req_invoice: false,
        data: {
            business_name: '',
            rfc: '',
            cif: '',
            tax_system: '',
            cfdi: '',
            tel: '',
            address: '',
            postalcode: '',
            colony: '',
            city: '',
            state: ''
        }
    },
    shipping: {
        status: '',
        tracking_guide: '',
        partner: '',
        cost: 0
    },
    tracking_guides: [],
    payment: {
        method: ''
    },
    odoo_request: {
        create: {
            sale_order_id: 0,
            sale_order_name: '',
            errors: []
        },
        sento: {
            errors: []
        },
        delivered: {
            errors: []
        }
    },
    fileId: ''
};

export const lostOrderColumns = [
    {
        title: "Número de pedido",
        field: "order_num",
        filtering: true
    },
    {
        title: "Nombre",
        field: "name",
        filtering: false
    },
    {
        title: "Correo electrónico",
        field: "email",
        filtering: false
    },
    {
        title: "Total del carrito",
        field: "total",
        filtering: false
    },
    {

        title: "Método de pago",
        field: "payment_method",
        filtering: true
    },
    {
        title: 'Estatus de la orden',
        field: 'payment_status',
        filtering: true
    },
    {
        title: 'Fecha',
        field: 'order_date',
        filtering: false
    }
];

export const orderColumns = [
    // {
    //     title: "Estatus de odoo",
    //     field: "odooState",
    //     filtering: true,
    //     sorting: false,
    //     lookup: { 'Registrado': 'Registrado', 'No Registrado': 'No Registrado' },
    //     render: rowData => (
    //         <>
    //             {
    //                 rowData.odoo_request.create.sale_order_id &&
    //                     rowData.odoo_request.create.sale_order_id !== 0
    //                     ?
    //                     <Box
    //                         sx={{
    //                             display: 'flex',
    //                             alignItems: 'center',
    //                             gap: '1rem',
    //                         }}
    //                     >
    //                         {/* <span>{rowData.odooState}</span> */}
    //                         <CheckIcon sx={{ color: 'green' }} />
    //                     </Box>
    //                     :
    //                     <Box
    //                         sx={{
    //                             display: 'flex',
    //                             alignItems: 'center',
    //                             gap: '1rem',
    //                         }}
    //                     >
    //                         {/* <span>{rowData.odooState}</span> */}
    //                         <CloseIcon sx={{ color: 'red' }} />
    //                     </Box>
    //             }
    //         </>
    //     )
    // },
    {
        title: "Número de pedido",
        field: "order_num",
        filtering: false,
    },
    {
        title: "No. pedido odoo",
        field: "odoo_order_name",
        filtering: true,
        // filterComponent: (props) => <CustomFilter {...props} />
    },
    {
        title: "Factura",
        field: "req_invoice",
        filtering: true,
        // filterComponent: (props) => <CustomFilter {...props} />
    },
    {
        title: "Nombre",
        field: "name",
        filtering: false
    },
    {
        title: "Fecha",
        field: "date",
        filtering: true
        // filterComponent: (props) => <CustomDatePicker {...props} />
    },
    {
        title: "Total",
        field: "total",
        filtering: false
    },
    {
        title: "Método de pago",
        field: "payment.method",
        filtering: true,
        // filterComponent: (props) => <CustomFilter {...props} />
    },
    {
        title: "Estatus de pago",
        field: "payment_status",
        // filterComponent: (props) => <CustomFilter {...props} />
    },
    {
        title: "Estatus de envío",
        field: "delivery_status",
        // filterComponent: (props) => <CustomFilter {...props} />
    },
    {
        title: "No. artículos",
        field: "art",
        filtering: false,
        render: rowData => {
            return (
                <Tooltip placement="left" title=
                    {
                        <React.Fragment>
                            <List dense={true} disablePadding>
                                {
                                    rowData.products.map((item, index) => (
                                        <div key={index}>
                                            <ListItem>
                                                <ListItemText primary={`${item.title} ${item.variant_title}`} disableTypography={true} />
                                            </ListItem>
                                            {index !== rowData.products.length - 1 ? <Divider sx={{ bgcolor: '#fff' }} /> : null}
                                        </div>
                                    ))
                                }
                            </List>
                        </React.Fragment>
                    }
                >
                    <Avatar sx={{ width: 32, height: 32, fontSize: '14px', bgcolor: '#DC7633' }}>{rowData.art}</Avatar>
                </Tooltip>
            )

        },
    },
];

export const orderColumnsRMT = [
    // {
    //     header: "Estatus de odoo",
    //     accessorKey: "odoo_state",
    //     enableColumnFilter: false,
    //     Cell: ({ renderedCellValue, row }) => (
    //         <>
    //             {
    //                 row.original.odoo_request.create.sale_order_id &&
    //                     row.original.odoo_request.create.sale_order_id !== 0
    //                     ?
    //                     <Box
    //                         sx={{
    //                             display: 'flex',
    //                             alignItems: 'center',
    //                             gap: '1rem',
    //                         }}
    //                     >
    //                         <CheckIcon sx={{ color: 'green' }} />
    //                         {/* <span>{renderedCellValue}</span> */}
    //                     </Box>
    //                     :
    //                     <Box
    //                         sx={{
    //                             display: 'flex',
    //                             alignItems: 'center',
    //                             gap: '1rem',
    //                         }}
    //                     >
    //                         <CloseIcon sx={{ color: 'red' }} />
    //                         {/* <span>{renderedCellValue}</span> */}
    //                     </Box>
    //             }
    //         </>
    //     ),
    // },
    {
        header: "Número de pedido",
        accessorKey: "order_num",
    },
    {
        header: "Factura",
        accessorKey: "req_invoice",
    },
    {
        header: "Nombre",
        accessorKey: "name",
    },
    {
        header: "Fecha",
        accessorKey: "date",
    },
    {
        header: "Total",
        accessorKey: "total",
    },
    {
        header: "Método de pago",
        accessorKey: "payment_method",
    },
    {
        header: "Estatus de pago",
        accessorKey: "payment_status"
    },
    {
        header: "Estatus de envío",
        accessorKey: "delivery_status"
    },
    {
        header: "No. artículos",
        accessorKey: "art",
        Cell: ({ renderedCellValue, row }) => (
            <Tooltip placement="left" title=
                {
                    <React.Fragment>
                        <List dense={true} disablePadding>
                            {
                                row.original.products.map((item, index) => (
                                    <div key={index}>
                                        <ListItem>
                                            <ListItemText primary={`${item.title} ${item.variant_title}`} disableTypography={true} />
                                        </ListItem>
                                        {index !== row.original.products.length - 1 ? <Divider sx={{ bgcolor: '#fff' }} /> : null}
                                    </div>
                                ))
                            }
                        </List>
                    </React.Fragment>
                }
            >
                <Avatar sx={{ width: 32, height: 32, fontSize: '14px', bgcolor: '#DC7633' }}>{renderedCellValue}</Avatar>
            </Tooltip>
        )
    },
];

// export const orderTracking = {
//     _id: '',
//     summary: {
//         discount: 0,
//         subtotal: 0,
//         total: 0
//     },
//     address: {
//         name: '',
//         lastname: '',
//         email: '',
//         tel: '',
//         street: '',
//         ext_num: '',
//         int_num: '',
//         postalcode: '',
//         colony: '',
//         city: '',
//         state: '',
//     },
//     shipping: {
//         partner: '',
//         cost: 0,
//         tracking_guide: '',
//         shipping_status: 'En espera',
//         local_shipping: false
//     },
//     tracking_guides: []
// };

// ThankYouPageComponent.jsx
export const ORDER_INIT = {
    order_num: '',
    summary: {
        discount: 0,
        subtotal: 0,
        total: 0
    },
    coupon: {
        name: '',
        description: '',
        _class: '',
        use_method: '',
        discount: {
            type: '',
            _value: ''
        }
    },
    address: {
        name: '',
        lastname: '',
        tel: '',
        street: '',
        ext_num: '',
        int_num: '',
        postalcode: '',
        colony: '',
        city: '',
        state: ''
    },
    payment: {
        method: '',
        nefro_reference: ''
    },
    products: [],
    shipping: {
        status: '',
        _id: '',
        partner: '',
        cost: 0,
        delivery_time: '',
        delivery_days: []
    },
    // promotion: {
    //     promo_id: '',
    //     promo: '',
    //     promo_long: ''
    // },
    // discount: {
    //     by_promotions: '',
    //     promotions_rate: '',
    //     by_coupon: '',
    //     coupon_rate: '',
    //     total: '',
    //     total_rate: '',
    //     item_total: '',
    // },
    account_id: '',
    order_date: ''
};

// promociones (Promotion.js)

export const prodPromo = {
    variant_id: '',
    title: '',
    variant_title: '',
    price: 0,
    image_id: ''
};

export const promotionList = [
    {
        name: '2 productos por el precio de 1',
        value: '2x1'
    },
    {
        name: '4 productos por el precio de 3',
        value: '4x3'
    },
    {
        name: 'En compra de producto A y B, producto C gratis',
        value: 'ABC'
    },
    // {
    //     name: 'Descuento neto en producto',
    //     value: 'Descuento$Producto' // directo
    // },
    {
        name: 'Descuento en producto',
        value: 'Descuento%Producto' // porcentual
    },
    // {
    //     name: 'En compra de A, descuento de $X en producto B',
    //     value: 'Compra$Descuento' // directo
    // },
    {
        name: 'En compra de producto A, descuento en producto B',
        value: 'Compra%Descuento' // porcentual
    },
    {
        name: 'En compra de producto A y B, descuento en ambos',
        value: 'CompraAB%Descuento'
    },
    {
        name: 'Descuento en compra de "x" productos A (Packs)',
        value: 'CompraX%Descuento'
    }
];

export const is_visible = { visibility: "visible" };
export const no_visible = { visibility: "hidden " };