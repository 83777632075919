import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Typography, Box } from '@mui/material';

const PAInfoFacturacion = ({ selectedOrder }) => {
    // CFDI disponible
    const { CFDIData } = useSelector(state => state.checkout);
    const {
        delivery_status, shipping: { partner, cost },
        billing: {
            req_invoice,
            data: {
                business_name, rfc, cif,
                tax_system, cfdi, tel,
                address, postalcode, colony,
                city, state
            }, status, yomefacturo_ticketId
        }
    } = selectedOrder;
    
    const [data, setData] = useState(cfdi);

    useEffect(() => {
        const orderCFDI = CFDIData.find(e => e.codigo === cfdi);
        if (orderCFDI)
            setData(`${orderCFDI.codigo} - ${orderCFDI.descripcion}`);
    }, [CFDIData]);

    return (
        <>
            {
                req_invoice ?
                    <Box>
                        <Typography variant="body1" component="div">
                            <b style={{ fontWeight: '600' }}>Razón social:</b> {business_name}
                        </Typography>
                        <Typography variant="body1" component="div">
                            <b style={{ fontWeight: '600' }}>RFC:</b> {rfc}
                        </Typography>
                        <Typography variant="body1" component="div">
                            <b style={{ fontWeight: '600' }}>Régimen social:</b> {tax_system}
                        </Typography>
                        <Typography variant="body1" component="div">
                            <b style={{ fontWeight: '600' }}>CFDI:</b> {data}
                        </Typography>
                        <Typography variant="body1" component="div">
                            <b style={{ fontWeight: '600' }}>Número telefónico:</b> {tel}
                        </Typography>
                        <Typography variant="body1" component="div">
                            <b style={{ fontWeight: '600' }}>Dirección:</b> { `Calle ${address}. CP: ${postalcode} Colonia: ${colony}, ${city}, ${state}` }
                        </Typography>
                        <Typography variant="body1" component="div">
                            <b style={{ fontWeight: '600' }}>{ `Documento CSF: ` }</b>
                                <a target="_blank" rel="noreferrer"
                                    href={`https://drive.google.com/file/d/${cif}/view`}>
                                    abrir
                                </a>
                        </Typography>
                    </Box>
                :
                    status === 'deactivated' ?
                        <Typography variant="body1" component="div">
                            Facturar a público en general
                        </Typography>
                    :
                        (
                            status === 'generated' ?
                                <Box>
                                    <Typography variant="body1" component="div">
                                        Factura generada en YoMeFacturo
                                    </Typography>
                                    <Typography variant="body1" component="div">
                                        { `TicketId: ${yomefacturo_ticketId}` }
                                    </Typography>
                                </Box>
                            :
                                <Typography variant="body1" component="div">
                                    Factura NO generada. YoMeFacturo
                                </Typography>
                        )
            }

            {/* Información de envío */}
            <Typography gutterBottom variant="h5" component="div"
                sx={{ fontWeight: '600', mt: 5 }}>
                Estado del envío
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Paquetería principal:</b> {partner}
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Estado:</b> {delivery_status}
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Costo de envío: </b>
                { cost === 0 ? 'Gratis' : `$${cost}` }
            </Typography>
        </>
    );
};

export default PAInfoFacturacion;