export const ACTIVE_PRODUCT = {
    _id: '',
    title: '',
    url: '',
    info: '',
    category: [],
    category_url: [],
    subcategory: [],
    prod_category: [],
    brand: '',
    keywords: [],
    featured: false,
    refrigerated: false,
    medicine: false,
    folderId: '',
    revFolderId: '',
    imagesFolderId: '',
    images: [],
    reviews: [],
    prod_variants: [], // información de variantes
    fbt_item: [] // comprados juntos frecuentemente
    // fbt => frequently bought together
};
