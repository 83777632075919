import axios from 'axios';
import Swal from 'sweetalert2';

import { basicMsg, msg } from './swal_msg';
import { request } from "../helpers/requestHelper";

const base_url = process.env.REACT_APP_NEFRO_API_URL;

// subir documento CIF a drive
const uploadCIFDocument = async(file) => {
    try {
        const data = new FormData();
        data.append('file', file);
        const url = `${base_url}/file/upload-cif`;
        return await axios.post(url, data).then(function(response) {
            const { data: { status, msg, resp } } = response;
            if (status) return { status: true, resp };
            else return { status: false, msg };
        })
        .catch(function(err) {
            return { status: false, msg: 'req-err' };
        });
    } catch (err) {
        return { status: false, msg: 'local-err' };
    };
};

const getOrderObject = ({
    addressValues, billingValues,
    summary, shipping,
    cart, couponData
    // account_id
}) => {
    // datos de envío
    const {
        residence_type, residence_type_select,
        ...address
    } = addressValues;

    // datos de facturación
    const {
        req_invoice, billing_tel, billing_postalcode,
        billing_colony, billing_city, billing_state,
        ...billing
    } = billingValues;
    const data = req_invoice ?
        {
            ...billing,
            tel: billing_tel,
            postalcode: billing_postalcode,
            colony: billing_colony,
            city: billing_city,
            state: billing_state
        }
    : null;

    // cupón
    const coupon = (couponData && couponData.name !== '') ? { ...couponData } : null;
    // token sesión usuario
    const user_token = localStorage.getItem('token') || '';
    // token carrito
    const token = localStorage.getItem('cart-token') || '';
    
    return {
        user_token,
        token,
        summary, // resumen de carrito
        coupon,
        address: {
            ...address,
            residence_type: residence_type_select === 'Otro' ?
                residence_type : residence_type_select
        },
        billing: {
            req_invoice,
            data
        },
        products: [...cart],
        shipping: {
            ...shipping,
            status: 'standby'
        },
        // account_id
    }
};

// compra realizada mediante EvoPayments
// export const evoPaymentsSession = async(OrderModel, nefro_reference) => {
//     try {
//         const model = {
//             ...OrderModel,
//             payment: {
//                 method: 'EvoPayments',
//                 nefro_reference,
//                 status: 'registered',
//             },
//         };
//         const resp = await request('payment/evopayments/session', model, 'POST');
//         const body = await resp.json();
//         if (body.status)
//             return body;
//         else {
//             if (body.msg === 'session-not-generated') {
//                 msg('warning', 'Checkout',
//                 'No se pudo proceder con el pago');
//                 return { status: false };
//             } else return returnMsg(body, 'EvoPayments', model, '');
//         }
//     } catch (err) {
//         msg('error', 'Checkout',
//         'No se pudo registrar el pedido. Comuníquese con nosotros: 33 3826 8009');
//         return { status: false };
//     }
// };

// generar referencia de orden para MercadoPago
export const getMPReference = async(OrderModel, nefro_reference) => {
    try {
        const model = {
            ...OrderModel,
            payment: {
                method: 'MercadoPago',
                nefro_reference
            }
        };
        const resp = await request('payment/mercadopago/preference', model, 'POST');
        const body = await resp.json();
        if (body.status)
            return body;
        else {
            if (body.msg === 'not-created') {
                msg('error', 'Mercado Pago',
                'No se generó el pedido');
                return { status: false };
            } else return returnMsg(body, 'MercadoPago', model, '');
        }
    } catch (err) {
        msg('error', 'Checkout',
        'No se pudo registrar el pedido. Comuníquese con nosotros: 33 3826 8009');
        return { status: false };
    }
};

// registrar pedido con PayPal
export const paypalOrder = async(OrderModel, nefro_reference) => {
    try {
        const model = {
            ...OrderModel,
            payment: {
                method: 'PayPal',
                nefro_reference,
                status: 'registered'
            }
        };
        const resp = await request('payment/generate-paypal-order', model, 'POST');
        const body = await resp.json();
        if (body.status) {
            return {
                status: true,
                resp: {
                    ...body.resp,
                    nefro_reference
                }
            };
        } else
            return returnMsg(body, 'PayPal', model, nefro_reference);
    } catch (err) {
        msg('error', 'Checkout',
        'No se pudo registrar el pedido. Comuníquese con nosotros: 33 3826 8009');
        return { status: false };
    }
};

export const openPayCharge = async(OrderModel, nefro_reference) => {
    try {
        const model = {
            ...OrderModel,
            payment: {
                method: 'OpenPay',
                nefro_reference,
                status: 'registered'
            },
        };
        const resp = await request('payment/openpay/nefro-charge', model, 'POST');
        const body = await resp.json();
        if (body.status)
            return body;
        else return returnMsg(body, 'OpenPay', model, '');
    } catch (err) {
        msg('error', 'Checkout',
        'No se pudo registrar el pedido. Comuníquese con nosotros: 33 3826 8009');
        return { status: false };
    };
};

// ¿requiere factura? / procesar información
export const processOrder = async(data) => {
    try {
        const { billingValues: { req_invoice, cif, filename } } = data;
        const { payment_method, ...model } = data;
        // referencia
        const nefro_reference = createHash();

        if (req_invoice) { // necesita factura
            const body = cif && filename ?
                {
                    status: true,
                    resp: {
                        fileId: cif
                    }
                } : await uploadCIFDocument(cif);
            if (body.status) {
                const OrderData = {
                    ...model,
                    billingValues: {
                        ...model.billingValues,
                        cif: body.resp.fileId
                    }
                };
                const OrderModel = getOrderObject(OrderData);
                if (payment_method === 'PayPal')
                    return await paypalOrder(OrderModel, nefro_reference);
                // else if (payment_method === 'EvoPayments')
                //     return await evoPaymentsSession(OrderModel, nefro_reference);
                else if (payment_method === 'MercadoPago')
                    return await getMPReference(OrderModel, nefro_reference);
                else if (payment_method === 'OpenPay')
                    return await openPayCharge(OrderModel, nefro_reference);
                else {
                    basicMsg('No se reconoce el método de pago');
                    return { status: false };
                }
            } else {
                if (body.msg === 'not-uploaded')
                    msg('warning', 'Checkout',
                    'Documento CIF no guardado');
                else if (body.msg === 'no-files')
                    msg('warning', 'Checkout',
                    'No se envió su documento CIF')
                else if (body.msg === 'req-err')
                    msg('error', 'Checkout',
                    'Incidente al enviar documento CIF');
                else if (body.msg === 'server-err')
                    msg('error', 'Checkout',
                    'Incidente al querer guardar documento CIF');
                else
                    msg('error', 'Checkout',
                    'No se pudo guardar su documento CIF')
                return { status: false };
            }
        } else { // no requiere factura
            const OrderModel = getOrderObject(model);
            if (payment_method === 'PayPal')
                return await paypalOrder(OrderModel, nefro_reference);
            // else if (payment_method === 'EvoPayments')
            //     return await evoPaymentsSession(OrderModel, nefro_reference);
            else if (payment_method === 'MercadoPago')
                return await getMPReference(OrderModel, nefro_reference);
            else if (payment_method === 'OpenPay')
                return await openPayCharge(OrderModel, nefro_reference);
            else {
                basicMsg('No se reconoce el método de pago');
                return { status: false };
            }
        }
    } catch (err) {
        msg('error', 'Checkout',
        'No se pudo registrar el pedido. Comuníquese con nosotros: 33 3826 8009');
        return { status: false };
    };
};

// actualizar pedido de PayPal (pago aprobado)
export const updatePaypalOrder = async(paypal_order_id, order_id) => {
    try {
        const resp = await request('payment/confirm-paypal-order',
            { paypal_order_id, order_id },
        'POST');
        const body = await resp.json();
        if (body.status)
            return body;
        else {
            if (body.msg === 'miss')
                Swal.fire({
                    title: 'Checkout',
                    icon: 'warning',
                    html: `<h5>Falta información su pedido</h5><h6>Asegúrese que el pago ha sido exitoso y comuníquese con nosotros al correo: ecommerce@nefropolis.com</h6>`,
                    focusConfirm: true,
                    confirmButtonText: 'Entendido',
                });
            else if (body.msg === 'not-found')
                Swal.fire({
                    title: 'Checkout',
                    icon: 'warning',
                    html: `<h5>No hemos podido encontrar su pedido</h5><h6>Asegúrese que el pago ha sido exitoso y comuníquese con nosotros al correo: ecommerce@nefropolis.com</h6>`,
                    focusConfirm: true,
                    confirmButtonText: 'Entendido',
                });
            else if (body.msg === 'server-err')
                Swal.fire({
                    title: 'Checkout',
                    icon: 'error',
                    html: `<h5>Incidente al actualizar su pedido</h5><h6>Asegúrese que el pago ha sido exitoso y comuníquese con nosotros al correo: ecommerce@nefropolis.com</h6>`,
                    focusConfirm: true,
                    confirmButtonText: 'Entendido',
                });
            else
                Swal.fire({
                    title: 'Checkout',
                    icon: 'error',
                    html: `<h5>Pedido no actualizado</h5><h6>Asegúrese que el pago ha sido exitoso y comuníquese con nosotros al correo: ecommerce@nefropolis.com</h6>`,
                    focusConfirm: true,
                    confirmButtonText: 'Entendido',
                });
            return { status: true };
        }
    } catch (err) {
        Swal.fire({
            title: 'Checkout',
            icon: 'error',
            html: `<h5>No se pudo actualizar el pedido</h5><h6>Asegúrese que el pago ha sido exitoso y comuníquese con nosotros al correo: ecommerce@nefropolis.com</h6>`,
            focusConfirm: true,
            confirmButtonText: 'Entendido',
        });
        return { status: true };
    };
};

// elabora un hash como referencia de la orden
export const createHash = () => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-';
    let result = '';
    for (let i=0; i<99; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
};

// nefro_reference solo necesario para pago con PayPal
// ya que no abandonamos el sitio de Nefrópolis
const returnMsg = async(body, method, model, nefro_reference = '') => {
    if (body.msg === 'miss')
        msg('warning', 'Checkout',
        'Falta información en su pedido');
    else if (body.msg === 'invalid-token') {
        msg('warning', 'Checkout',
        'Su carrito ha expirado');
        localStorage.removeItem('cart-token');
    } else if (body.msg === 'cart-not-found')
        msg('warning', 'Checkout',
        'No hemos podido cargar su carrito');
    // no se encontró producto en BD || no hay stock
    else if (body.msg === 'product-issue') {
        const { notFound, outOfStock } = body.resp;
        const not_found = notFound.length > 0 ?
            `<div style="margin-bottom: .5rem;"><h6>Los siguientes productos ya no están disponibles:</h6><div style="display: flex; justify-content: flex-start;"><ul><li>${notFound.map(e => e.title)}</li></ul></div></div>` : '';
        const no_stock = outOfStock.length > 0 ?
            `<div style="margin-bottom: .5rem;"><h6>No hay disponibilidad suficiente de los siguientes productos:</h6><div style="display: flex; justify-content: flex-start;"><ul><li>${outOfStock.map(e => e.title)}</li></ul></div></div>` : '';
        Swal.fire({
            title: 'Checkout',
            icon: 'warning',
            html: `<h5 style="margin-bottom: 2rem;">Se encontraron problemas en su carrito</h5>${not_found}${no_stock}`,
            focusConfirm: true,
            confirmButtonText: 'Entendido',
        });
    // una o más promociones ya no está activas
    } else if (body.msg === 'promotion-expired') {
        const { expiredPromos, ...cartData } = body.resp;
        let content = '';
        for (const e of expiredPromos) {
            content += `<li>${e.promo_long}.</li>`;
        }
        const resp = await Swal.fire({
            title: 'Checkout',
            icon: 'warning',
            html: `<h5>Las siguientes promociones han expirado:</h5><div style="margin-bottom: .5rem;"><div><ul>${content}</ul></div></div>`,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: 'Continuar con la compra',
            cancelButtonText: 'Revisar carrito'
        }).then(async (result) => {
            if (result.isConfirmed) { // registrar sin promociones expiradas
                let products = [];
                // ID's de promociones expiradas
                const ids = expiredPromos.map(e => e.promo_id);
                // revisamos los productos
                for (const e of model.products) {
                    if (e.promotion) {
                        // quitamos la promoción del producto
                        const { promotion, ..._product } = e;
                        // producto tiene promoción expirada
                        if (ids.includes(promotion.promo_id))
                            products = [...products, _product];
                        else // promoción activa (se converva)
                            products = [...products, {
                                _product, promotion
                            }];
                    }
                }
                // guardamos el pedido
                const order = { ...model, products };
                if (method === 'PayPal')
                    return await paypalOrderFn(order, cartData, nefro_reference);
                // else if (method === 'EvoPayments')
                //     return await evoPaymentsSessionFn(order, cartData);
                else if (method === 'MercadoPago')
                    return await getMPReferenceFn(order, cartData);
                else if (method === 'OpenPay')
                    return await openPayChargeFn(order, cartData);
            }
            return { status: false, cartData: { ...cartData } };
        });
        return resp;
    } else if (body.msg === 'server-err')
        msg('error', 'Checkout',
        'Ocurrió un incidente. Intente de nuevo, por favor');
    else if (body.msg === 'openpay-err') { // error con ID de pedido en Openpay
        const { description } = body.resp;
        const text = description === 'The order_id has already been processed'
            ? 'Incidente al procesar ID de su pedido' : description;
        msg('error', 'Checkout', text);
    } else if (body.msg === 'evopayments-err') // error en petición con EvoPayments
        msg('warning', 'Checkout', 'No se pudo proceder con el pago');
    else if (body.msg === 'order-not-created')
        basicMsg('No se pudo generar su pedido');
    else
        msg('warning', 'Checkout',
        'Inténtelo de nuevo, por favor');
    return { status: false };
};

// dar de alta pago con tarjeta débito/crédito (EvoPayments)
export const approveEvoPaymentsOrder = async(model) => {
    try {
        const resp = await request('payment/approve-evopayments-order', model, 'PUT');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'miss')
                alert('Falta información del pedido');
            else if (body.msg === 'no-match')
                alert('La contraseña no es correcta');
            else if (body.msg === 'not-found')
                alert('No se encontró el pedido');
            else if (body.msg === 'server-err')
               alert('Ocurrió un incidente. Intente de nuevo, por favor');
            else
                alert('Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        alert(`<p>No se pudo aprobar el pago del pedido</p><h6>Error: ${err}</h6>`);
        return { status: false };
    }
};

// compra realizada mediante EvoPayments (intento sin promociones expiradas)
// export const evoPaymentsSessionFn = async(model, cartData) => {
//     try {
//         const resp = await request('payment/evopayments/session', model, 'POST');
//         const body = await resp.json();
//         if (body.status)
//             return { body, cartData: { ...cartData } };
//         else {
//             if (body.msg === 'session-not-generated') {
//                 msg('warning', 'Checkout',
//                 'No se pudo proceder con el pago');
//                 return { status: false, cartData: { ...cartData } };
//             } else return returnMsg(body, 'EvoPayments', model, '');
//         }
//     } catch (err) {
//         msg('error', 'Checkout',
//         'No se pudo registrar el pedido. Comuníquese con nosotros: 33 3826 8009');
//         return { status: false, cartData: { ...cartData } };
//     }
// };

// generar referencia de orden para MercadoPago (intento sin promociones expiradas)
export const getMPReferenceFn = async(model, cartData) => {
    try {
        const resp = await request('payment/mercadopago/preference', model, 'POST');
        const body = await resp.json();
        if (body.status)
            return { body, cartData: { ...cartData } };
        else {
            if (body.msg === 'not-created') {
                msg('error', 'Mercado Pago',
                'No se generó el pedido');
                return { status: false, cartData: { ...cartData } };
            } else return returnMsg(body, 'MercadoPago', model, '');
        }
    } catch (err) {
        msg('error', 'Checkout',
        'No se pudo registrar el pedido. Comuníquese con nosotros: 33 3826 8009');
        return { status: false, cartData: { ...cartData } };
    }
};

// registrar pedido con PayPal (intento sin promociones expiradas)
export const paypalOrderFn = async(model, cartData, nefro_reference) => {
    try {
        const resp = await request('payment/generate-paypal-order', model, 'POST');
        const body = await resp.json();
        if (body.status)
            return {
                status: true,
                resp: { ...body.resp, nefro_reference },
                cartData: { ...cartData }
            };
        else
            return returnMsg(body, 'PayPal', model, nefro_reference);
    } catch (err) {
        msg('error', 'Checkout',
        'No se pudo registrar el pedido. Comuníquese con nosotros: 33 3826 8009');
        return { status: false, cartData: { ...cartData } };
    }
};

// registrar pedido con OpenPay (intento sin promociones expiradas)
export const openPayChargeFn = async(model, cartData) => {
    try {
        const resp = await request('payment/openpay/nefro-charge', model, 'POST');
        const body = await resp.json();
        if (body.status)
            return { body, cartData: { ...cartData } };
        else {
            if (body.msg === 'session-not-generated') {
                msg('warning', 'Checkout',
                'No se pudo proceder con el pago');
                return { status: false, cartData: { ...cartData } };
            } else return returnMsg(body, 'OpenPay', model, '');
        }
    } catch (err) {
        msg('error', 'Checkout',
        'No se pudo registrar el pedido. Comuníquese con nosotros: 33 3826 8009');
        return { status: false, cartData: { ...cartData } };
    }
};