import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { generateURL } from '../../../../../helpers/admin/productHelper';

import {
    Checkbox, List, ListItemButton, ListItemIcon, ListItemText,
    Collapse, ListItem, IconButton
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

const CategoryList = ({ setProdValues }) => {
    // lista de categorías
    const { categories } = useSelector(state => state.category);

    // categorías
    const [allCategories, setCategories] = useState([]);

    useEffect(() => {
        setCategories(
            categories.map(e => ({
                ...e,
                checked: false,
                subcategory_list: e.subcategories.map(u => ({ subcategory: u, sub_checked: false }))
            }))
        );
    }, [categories]);
    
    // selecciona categoría
    const handleChange = (category) => () => {
        setProdValues(e => ({
            ...e,
            prod_category: e.prod_category.find(u => u.category === category) ?
                e.prod_category.filter(u => u.category !== category)
            :
                [...e.prod_category, {
                    category, url: generateURL(category),
                    subcategories: []
                }]
        }));

        setCategories(e => e.map(
            u => u.category === category ?
                ({
                    ...u, checked: !u.checked,
                    subcategory_list: u.checked ?
                        u.subcategory_list.map(
                            a => ({ ...a, sub_checked: false })
                        )
                    : u.subcategory_list
                })
            : u
        ));
    };

    // selecciona subcategoría
    const handleSubChange = (category, subcategory) => () => {
        setProdValues(e => ({
            ...e,
            prod_category: e.prod_category.map(
                u => u.category === category ?
                    {
                        ...u, subcategories: u.subcategories.find(a => a === subcategory) ?
                            u.subcategories.filter(a => a !== subcategory)
                        :
                            [...u.subcategories, subcategory]
                    }
                : u
            )
        }));

        setCategories(e => e.map(
            u => u.category === category ?
                ({
                    ...u, subcategory_list: u.subcategory_list.map(
                        a => a.subcategory === subcategory ?
                            { ...a, sub_checked: !a.sub_checked }
                        : a
                    )
                })
            : u
        ));
    };

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav">
            {
                allCategories.map(({ _id, category, subcategory_list, checked }) =>
                    <React.Fragment key={_id}>
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end" aria-label="comments">
                                    { checked ? <ExpandLess /> : <ExpandMore /> }
                                </IconButton>
                            }
                            disablePadding
                        >
                            <ListItemButton
                                role={ undefined }
                                onClick={ handleChange(category) }
                                dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={ checked }
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText primary={ category } />
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={ checked } timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    subcategory_list.length <= 0 ?
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <DoNotDisturbOnIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="No hay subcategorías" />
                                        </ListItemButton>
                                    :
                                        subcategory_list.map(({ subcategory, sub_checked }) => 
                                            <ListItemButton sx={{ pl: 4 }} key={subcategory}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={ sub_checked }
                                                        onChange={ handleSubChange(category, subcategory) }
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={ subcategory }
                                                />
                                            </ListItemButton>
                                        )
                                }
                            </List>
                        </Collapse>
                    </React.Fragment>
                )
            }
        </List>
    );
};

export default CategoryList;