import React, { useState } from 'react';

import CategoryList from './CategoryList';
import CategoryDialog from './CategoryDialog';

import {
    Grid, Divider, Chip, FormGroup, Alert, Button
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// agregar nueva categoría
const ProductCategory = ({ categories, setCategories }) => {
    const [open, setOpen] = useState(false);
    
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={7} md={6} lg={9}>
                    <Divider>
                        <Chip label="Categorías" sx={{ fontSize: 16 }} />
                    </Divider>
                </Grid>
                <Grid item xs={5} md={6} lg={3}>
                    <Button variant="outlined"
                        size="small" startIcon={
                            <AddIcon fontSize="inherit" />
                        } onClick={ () => setOpen(!open) }>
                        Agregar
                    </Button>
                </Grid>
            </Grid>
            <FormGroup>
                {
                    categories.length <= 0 ?
                        <Alert severity="info">
                            Añada una categoría
                        </Alert>
                    :
                        <CategoryList
                            categories={ categories }
                            setCategories={ setCategories }
                        />
                }
            </FormGroup>
            <CategoryDialog
                open={ open }
                setOpen={ setOpen }
            />
        </>
    );
};

export default ProductCategory;