import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

//Componentes
import OrderList from "./OrderList";
import BuyAgain from "./BuyAgain/BuyAgain";
// import AbandonedOrder from "./Abandoned/AbandonedOrder";

import { uiUserOrdersTab } from "../../../actions/ui";
import { myOrders } from "../../../actions/user-account";

//CSS
import './pedidos.css';

//MUI
import {
    Box, Container, Tab, Typography, Breadcrumbs, useMediaQuery
} from '@mui/material';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from '@mui/material/styles';

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontSize: '15px',
        marginRight: '8px',
    })
);

const PedidosComponent = () => {
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width: 950px)');
    const { user_account: { userOrdersTab } } = useSelector(state => state.ui);

    useEffect(() => {
        dispatch(myOrders());
    }, []);

    const handleChange = (event, newValue) => {
        dispatch(uiUserOrdersTab(newValue));
    };

    return (
        <>
            <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }}>
                <Breadcrumbs aria-label="user breadcrumb" sx={{ mb: 2 }}>
                    <Link underline="hover" color="inherit" to="/mi-cuenta">
                        Mi cuenta
                    </Link>
                    <Typography color="text.primary">Mis pedidos</Typography>
                </Breadcrumbs>
                <Typography variant='h4' sx={{ fontWeight: 'light' }}>Mis pedidos</Typography>
            </Container>

            <Container maxWidth='xl' sx={{ mt: 3 }} className="last-view">
                <TabContext value={ userOrdersTab }>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={ handleChange } aria-label="user orders tab">
                            <StyledTab label="Pedidos" value="1" />
                            <StyledTab label="Comprados recientemente" value="2" />
                            {/* <StyledTab label="Pedidos abandonados" value="3" /> */}
                        </TabList>
                    </Box>
                    {/* Pedidos */}
                    <TabPanel value="1" sx={{ px: 0 }}>
                        <OrderList />
                    </TabPanel>

                    {/* Comprar nuevamente */}
                    <TabPanel value="2" sx={{ px: 0 }}>
                        <BuyAgain />
                    </TabPanel>

                    {/* <TabPanel value="3" sx={{ px: 0 }}>
                        <AbandonedOrder />
                    </TabPanel> */}
                </TabContext>
            </Container>
        </>
    );
};

export default PedidosComponent;