import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { basicMsg } from '../../../../actions/swal_msg';
import { saveProductBrand } from '../../../../actions/admin/product/product_brand';

import {
    Dialog, DialogTitle, DialogContent, Grid, Box,
    Stack, TextField, Button, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

import { GOOGLE_DRIVE_CDN, NO_IMG } from '../../../../constants/constants';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const INIT = { brand: '', imgFile: '', field_err: false };

const BrandDialog = ({ open, setOpen }) => {
    const dispatch = useDispatch();

    const [brandData, setBrandData] = useState(INIT);
    const { imgFile, brand, field_err } = brandData;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!open)
            setBrandData(INIT);
    }, [open]);

    // subir imagen de marca de producto
    const uploadImg = ({ target }) => {
        if (target.files.length > 0) {
            if (['image/jpeg', 'image/png', 'image/webp'].includes(target.files[0].type))
                setBrandData(e => ({ ...e, imgFile: target.files[0] }));
            else basicMsg('Imagen no compatible');
        }
    };

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'brand') {
            if (value.length <= 60)
                setBrandData(e => ({ ...e, [name]: value.toUpperCase() }));
        }
    };

    const saveBrand = () => {
        const b_len = brand.length;
        if (b_len > 2 && b_len <= 60) {
            if (imgFile !== '') {
                setLoading(true);
                dispatch(saveProductBrand({ brand, imgFile }, setOpen, setLoading));
            }
            else basicMsg('Suba portada de marca');
        } else basicMsg('Ingrese marca de producto');
    };

    return (
        <Dialog
            open={ open }
            onClose={ () => setOpen(false) }
            sx={{ zIndex: 1000 }}>
            <DialogTitle>
                Nueva marca
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={ () => setOpen(false) }
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8, top: 8,
                    color: theme.palette.grey[500]
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            sx={{
                                maxHeight: '100%',
                                maxWidth: '100%',
                            }}
                            alt="imagen-categoria"
                            src={
                                imgFile instanceof File ?
                                    URL.createObjectURL(imgFile)
                                :
                                    `${GOOGLE_DRIVE_CDN}${NO_IMG}`
                            } />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <TextField fullWidth
                                name="brand"
                                label="Marca de producto"
                                variant="outlined"
                                size="small"
                                value={ brand }
                                error={ field_err }
                                helperText={ field_err ? "Ingrese marca" : "" }
                                inputProps={{ maxLength: 60 }}
                                onChange={ handleInputChange } />
                            <Button fullWidth component="label" variant="outlined" startIcon={<PhotoCamera />}>
                                Subir imagen
                                <VisuallyHiddenInput accept="image/*" type="file" onChange={ uploadImg } />
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
                <LoadingButton fullWidth
                    sx={{ mt: 2 }}
                    endIcon={<SaveIcon />}
                    loading={ loading }
                    loadingPosition="end"
                    variant="contained"
                    onClick={ saveBrand }>
                    Guardar marca
                </LoadingButton>
            </DialogContent>
        </Dialog>
    );
};

export default BrandDialog;