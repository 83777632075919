import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';

import { removeItemFromCart } from "../../../actions/shop";

//CSS
import './buttondrawer.css';
import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';

import { GOOGLE_DRIVE_CDN } from "../../../constants/constants";

//MUI
import {
    AppBar, Container, Divider, Fab, Grid, IconButton,
    Toolbar, Drawer, Button, Typography, Badge
} from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#000',
        color: '#fff',
        // fontWeight: '600',
        fontSize: '10px',
        // right: '100%',
        top: -6,
        zIndex: 4
    },
}));



const DrawerCart = () => {

    const dispatch = useDispatch();
    const {
        // summary: { subtotal },
        cart: { item, summary: { subtotal } }
    } = useSelector(state => state.shop);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [itemCount, setItemCount] = useState(0);

    useEffect(() => { // actualización de carrito
        if (item.length > 0) {
            const stock = item.reduce((acum, { quantity }) => acum + quantity, 0)
            setItemCount(stock);
        } else setItemCount(0);
    }, [item]);

    const handleOpen = () => {
        setMobileOpen(true);
    };

    const handleClose = () => {
        setMobileOpen(false);
    };

    // eliminar producto de carrito
    const removeFromCart = _prod_var_id => () => {
        dispatch(removeItemFromCart(_prod_var_id));
    };

    return (
        <>
            <Fab onClick={handleOpen} sx={{ zIndex: 3, position: 'fixed', bottom: 16, right: 16 }}>
                <StyledBadge badgeContent={itemCount} className='btn-drawer-shop-style'>
                    <ShoppingBasketIcon />
                </StyledBadge >
            </Fab>

            {/* Drawer */}
            <Drawer
                anchor="right"
                open={mobileOpen}
                onClose={handleClose}
                PaperProps={{
                    sx: { width: '380px' },
                }}
            >
                <AppBar sx={{ position: 'relative', backgroundColor: '#fff' }} elevation={0}>
                    <Toolbar>
                        <Typography sx={{ flex: 1, color: '#000' }} variant="body1" component="div">
                            Carrito de compras ({itemCount})
                        </Typography>
                        <IconButton
                            edge="start"
                            onClick={handleClose}
                            sx={{ color: '#000' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <div className="drawer-cart">
                    {item.map((e, index) => (
                        <div key={index}>
                            <Container>
                                <Grid container spacing={2} sx={{ my: 2 }}>
                                    <Grid item xs={4}>
                                        {
                                            e.images.length > 0 ?
                                                <img style={{ marginTop: 'auto', marginBottom: 'auto', objectFit: 'contain', pointerEvents: 'none' }}
                                                    src={`${GOOGLE_DRIVE_CDN}${e.images[0].id}`}
                                                    width="110" height='110' alt={e.images[0].alt} />
                                                :
                                                <img style={{ marginTop: 'auto', marginBottom: 'auto' }}
                                                    src={no_image}
                                                    width="100%" alt="producto-nefropolis" />
                                        }
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1">
                                            {e.title}
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: '600' }}>
                                            ${e.price}
                                        </Typography>
                                        <Typography variant="body1">
                                            Cantidad: {`${e.quantity}`}
                                        </Typography>
                                        <Button size="small"
                                            sx={{ textTransform: 'none' }}
                                            color="error"
                                            startIcon={<DeleteIcon />}
                                            onClick={removeFromCart(e.prod_var_id)}>
                                            Eliminar del carrito
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Container>
                            <Divider />
                        </div>
                    ))}
                </div>
                <Container sx={{ my: 2 }}>
                    <Typography variant="h6" align="center" sx={{ fontWeight: '600' }} gutterBottom>
                        Subtotal: ${subtotal}
                    </Typography>
                    <Button fullWidth onClick={handleClose} variant="contained" sx={{ mb: 1, textTransform: 'none' }}>
                        Continuar comprando
                    </Button>
                    <Link to='/carrito' style={{ textDecoration: 'none' }}>
                        <Button fullWidth variant="contained" sx={{ textTransform: 'none' }}
                        >
                            Ver carrito
                        </Button>
                    </Link>
                </Container>

            </Drawer>
        </>
    );
};

export default DrawerCart;