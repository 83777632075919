export const CURRENT_PROMOTION = {
    promo_id: '',
    promo: '',
    pkg_qty: 0,
    info: '',
    end_date: '',
    active: false,
    products: []
};

export const PROMO_INIT = {
    promo: '', //
    price: 0, // precio de promoción
    text: '', // texto de promoción a mostrar en tienda
    long_text: '' // texto largo de promoción a mostrar en tienda
};
