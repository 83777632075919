import React from 'react'

//Owl carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//Images
import banner from "../../../assets/resources/sucursales/valle-de-aragon.jpg"
// import EnviosCDMX from '../../../assets/resources/sucursales/EnviosCDMX.jpg'
// import promocion_1 from '../../../assets/resources/sucursales/alimentos_nefropolis.jpg'
// import promocion_2 from '../../../assets/resources/sucursales/nefrolealtad.png'
// import promocion_3 from '../../../assets/resources/sucursales/Recurso-3.png'
// import promocion_4 from '../../../assets/resources/sucursales/promocional.png'
// import promocion_5 from '../../../assets/resources/sucursales/Promo_Nepro-HP.jpeg'
// import promocion_6 from '../../../assets/resources/sucursales/Todos-los-dias-Nefrolealtad.png'
import logo_whatsapp from '../../../assets/resources/sucursales/Logo_whatsApp.png'
import fb from '../../../assets/resources/sucursales/Recurso.png'

//MUI
import { Box, Button, Container, Grid, Typography, useMediaQuery } from '@mui/material';

const Valle = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    const options = {
        margin: 10,
        nav: false,
        dots: true,
        mouseDrag: false,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            800: {
                items: 3,
            },
            1000: {
                items: 3,

            }
        },
    };

    return (
        <>
            {/* Sucursal header */}
            <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant='h4' align='center' sx={{ fontWeight: '600' }}>
                    Sucursal Valle de Aragón
                </Typography>
            </Container>
            {/* Fin Sucursal header */}

            {/* sucursal */}
            <Container maxWidth="xl" sx={{ mt: 5 }}>

                {/* Banner */}
                <img src={banner} width="100%" alt={banner} />

                <Typography variant='h5' align='center' sx={{ fontWeight: '600', mt: 2 }}>
                    SOMOS UNA CADENA DE TIENDAS ESPECIALIZADAS EN LA VENTA DE ALIMENTOS Y SUPLEMENTOS PARA PERSONAS CON ENFERMEDAD RENAL.
                </Typography>

                {/* Información de sucursal */}
                <OwlCarousel className='owl-theme' {...options} style={{ marginTop: '30px', textAlign: 'center' }}>
                    <div className='item'>
                        <i className="fa-solid fa-location-dot" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Dirección</Typography>
                        <Typography variant="body1" gutterBottom>
                            Av. Central 854, Col Valle de Aragon 1era sección, 57100 Nezahualcóyotl, Méx.
                        </Typography>
                        <a href='https://www.google.com/maps/place/Nefropolis+Sucursal+Valle+de+Arag%C3%B3n/@19.4901578,-99.0501342,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1fb503f48e597:0x28a8810cab3b408!8m2!3d19.4901585!4d-99.0479473' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            <Button variant='contained'>Ver en mapa</Button>
                        </a>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-address-book" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Contacto</Typography>
                        <Typography variant="body1">
                            Teléfono:55 8948 9747 <br /> WhatsApp: 33 3559 1185 <br /> valledeearagon@nefropolis.com
                        </Typography>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-clock" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Horario</Typography>
                        <Typography variant="body1">
                            Lunes a Viernes <br /> 9:30 am - 2 pm, 2:30 pm - 6 pm <br /> Sábado <br /> 10:00 am – 2:30 pm
                        </Typography>
                    </div>
                </OwlCarousel>
                {/* Fin Información de sucursal */}

                {/* enlace redes  */}
                <Grid container spacing={4} sx={{ textAlign: 'center', mt: 5 }}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{ m: 'auto' }}>
                        <a href="https://wa.me/523335591185?text=Buen%20día,%20me%20gustaría%20obtener%20información%20"
                            target="_blank" rel="noopener noreferrer">
                            <img src={logo_whatsapp} width="80%" className='social-img social-whats' alt={logo_whatsapp} />
                        </a>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{ m: 'auto' }}>
                        <a href="https://www.facebook.com/nutriologarenal.valledearagon"
                            target="_blank" rel="noopener noreferrer">
                            <img src={fb} alt={fb} width="60%" className='social-img' />
                        </a>
                    </Grid>
                </Grid>
                {/* Fin enlace redes  */}

                <Box sx={{ textAlign: 'center', mt: 5 }}>
                    <Typography variant='body1' sx={{ color: '#00a7e2', fontWeight: 'bold' }}>
                        ¡Visita tu sucursal más cercana!
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#00a7e2', fontWeight: 'bold' }}>
                        CONTAMOS CON MÁS DE 80 PRODUCTOS
                    </Typography>
                </Box>
            </Container>
            {/* Fin sucursal */}

            {/* Map */}
            <Container maxWidth="xl" sx={{ mt: 1 }} className="last-view">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.196191610616!2d-99.05246337597171!3d19.490193244796423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fb503f48e597%3A0x28a8810cab3b408!2sNefropolis%20Sucursal%20Valle%20de%20Arag%C3%B3n!5e0!3m2!1ses-419!2smx!4v1662144676415!5m2!1ses-419!2smx"
                    width="100%" height="450" title='Valle' style={{ border: '0' }} allowFullScreen="" loading="lazy"></iframe>
            </Container>
            {/* Fin Map */}

            {/* Promociones */}
            {/* <Container maxWidth="xl" sx={{ mt: 5 }} className="last-view">
                <Typography variant="h4" sx={{ fontWeight: '600' }} align='center'>Promociones en sucursal</Typography>
                <Grid container spacing={4} sx={{ mt: 1 }}>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={EnviosCDMX} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_1} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_2} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_3} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_4} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_5} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_6} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                </Grid>
            </Container> */}
            {/* Fin Promociones */}
        </>
    )
}

export default Valle