import React from 'react';
import { useSelector } from 'react-redux';

import {
    FormControl, RadioGroup, FormControlLabel, Radio
} from '@mui/material';

const BrandRadioGroup = ({ brand, setProdValues }) => {
    const { brands } = useSelector(state => state.category);

    const handleChange = ({ target }) => {
        setProdValues(e => ({ ...e, brand: target.value }));
    };

    return (
        <FormControl>
            <RadioGroup
                name="brand_group"
                value={ brand }
                onChange={ handleChange }
            >
                {
                    brands.map(({ brand }, i) =>
                        <FormControlLabel key={ brand }
                            value={ brand }
                            control={<Radio />}
                            label={ brand }
                        />
                    )
                }
            </RadioGroup>
        </FormControl>
    );
};

export default BrandRadioGroup;