import { protectedReq } from "../helpers/requestHelper";
import { types } from "../types/types";
import { basicMsg, valMsg } from "./swal_msg";

export const myOrders = () => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('account/my-orders');
            const body = await resp.json();
            if (body.status) {
                const { orders, abandoned_orders, buyAgainProducts } = body.resp;
                dispatch(ordersLoaded({
                    orders, abandoned_orders, buyAgainProducts
                }));
            } else valMsg('warning', 'Mis pedidos',
                'Recarga la página para ver tus pedidos');
        } catch (err) {
            valMsg('error', 'Mis pedidos',
            'No se pudieron cargar tus pedidos');
        };
    };
};

// pedidos del usuario fueron recuperados
const ordersLoaded = (payload) => ({
    type: types.userOrdersLoaded,
    payload
});

// pedido fue seleccionado
export const orderSelected = (payload) => ({
    type: types.userOrderSelected,
    payload
});

// verificar contraseña de usuario en /informacion-y-seguridad
export const verifiyPwd = async(pwd) => {
    try {
        // token sesión usuario
        const token = localStorage.getItem('token') || '';
        const resp = await protectedReq('account/verify-pwd', { token, pwd }, 'POST');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'not-match')
                basicMsg("Las contraseñas no coinciden");
            else if (body.msg === 'account-not-found')
                basicMsg("No se encontró su cuenta");
            else if (body.msg === 'no-password')
                basicMsg("Ingrese su contraseña");
            else
                alert("Contraseña no verificada");
        }
        return body;
    } catch (err) {
        basicMsg("No se pudo verificar la contraseña");
        return { status: false };
    };
};

// actualizar contraseña de usuario en /informacion-y-seguridad
export const updatePwd = async(pwd) => {
    try {
        // token sesión usuario
        const token = localStorage.getItem('token') || '';
        const resp = await protectedReq('account/update-pwd', { token, pwd }, 'POST');
        const body = await resp.json();
        if (body.status)
            valMsg('success', 'Contraseña actualizada',
            'Ha actualizado su contraseña con éxito');
        else {
            if (body.msg === 'not-found')
                valMsg('warning', 'Actualizar contraseña',
                'No se encontró su usuario');
            else if (body.msg === 'not-match')
                valMsg('warning', 'Actualizar contraseña',
                'La contraseña no coincide');
            else if (body.msg === 'server-err')
                valMsg('error', 'Actualizar contraseña',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Actualizar contraseña',
                'Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Actualizar contraseña',
        'No se pudo actualizar la contraseña');
        return { status: false };
    };
};

// actualizar información de usuario
export const updateMyData = async(data) => {
    try {
        // token sesión usuario
        const token = localStorage.getItem('token') || '';
        const resp = await protectedReq('account/update-data', { token, ...data }, 'POST');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'account-not-found')
                valMsg('warning', 'Cuenta',
                'No se encontró su usuario');
            else if (body.msg === 'miss')
                basicMsg('Compruebe la información ingresada');
            else if (body.msg === 'server-err')
                valMsg('error', 'Mi cuenta',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Mi cuenta',
                'Información no actualizada');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Mi cuenta',
        'No se pudo actualizar tu información');
        return { status: true };
    };
};

export const myShippingAddresses = async() => {
    try {
        const resp = await protectedReq('account/shipping-addresses');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'server-err')
                valMsg('error', 'Direcciones de envío',
                'Ocurrió un incidente. Intente de nuevo, por favor');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Direcciones de envío',
        'No se pueden mostrar sus direcciones');
        return { status: false };
    };
};

export const addShippingAddress = async(model) => {
    try {
        const { _id, ...address } = model;
        const resp = await protectedReq('account/add-shipping-address', address, 'POST');
        const body = await resp.json();
        if (body.status)
            basicMsg('Dirección de envío guardada');
        else {
            if (body.msg === 'not-saved')
                valMsg('warning', 'Dirección de envío',
                'No se guardó su dirección. Intente de nuevo, por favor');
            else if (body.msg === 'server-err')
                valMsg('error', 'Dirección de envío',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Dirección de envío',
                'Dirección no guardada. Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Direcciones de envío',
        'No se pudo guardar su dirección');
        return { status: false };
    };
};

export const updShippingAddress = async(model) => {
    try {
        const resp = await protectedReq('account/upd-shipping-address', model, 'PUT');
        const body = await resp.json();
        if (body.status)
            basicMsg('Dirección de envío actualizada');
        else {
            if (body.msg === 'not-saved')
                valMsg('warning', 'Dirección de envío',
                'No se actualizó su dirección. Intente de nuevo, por favor');
            else if (body.msg === 'server-err')
                valMsg('error', 'Dirección de envío',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Dirección de envío',
                'Dirección no actualizada. Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Dirección de envío',
        'No se pudo actualizar su dirección');
        return { status: false };
    };
};

export const delShippingAddress = async(_id) => {
    try {
        const resp = await protectedReq('account/del-shipping-address', { _id }, 'DELETE');
        const body = await resp.json();
        if (body.status)
            basicMsg('Dirección de envío eliminada');
        else {
            if (body.msg === 'not-deleted')
                valMsg('warning', 'Dirección de envío',
                'No se eliminó su dirección. Intente de nuevo, por favor');
            else if (body.msg === 'server-err')
                valMsg('error', 'Dirección de envío',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Dirección de envío',
                'Dirección no eliminada. Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Dirección de envío',
        'No se pudo eliminar su dirección');
        return { status: false };
    };
};

// enviar código de 6 dígitos para confirmar usuario y establecer contraseña
export const sendConfirmCode = async(email) => {
    try {
        const resp = await protectedReq('account/send-confirm-code', { email }, 'POST');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'account-not-found')
                basicMsg('No hay una cuenta asociada al correo ingresado');
            else if (body.msg === 'miss')
                basicMsg('No reconocemos su correo electrónico');
            else if (body.msg === 'no-req-done')
                valMsg('warning', 'Código no generado',
                'Intente de nuevo, por favor');
            else if (body.msg === 'email-not-send')
                valMsg('warning', 'Código no enviado',
                'Intente de nuevo, por favor');
            else if (body.msg === 'server-err')
                valMsg('error', 'Código de confirmación',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else basicMsg('Código de confirmación no generado');
        }
        return body;
    } catch (err) {
        basicMsg('No se pudo generar su código de confirmación');
        return { status: false };
    };
};

// confirmar código y establecer contraseña
export const confirmNewPassword = async(data) => {
    try {
        const resp = await protectedReq('account/set-up-password', { ...data }, 'POST');
        const body = await resp.json();
        if (body.status)
            valMsg('success', 'Cuenta',
            'Ha establecido contraseña en su cuenta');
        else {
            if (body.msg === 'account-not-found')
                basicMsg('No hay una cuenta asociada al correo ingresado');
            else if (body.msg === 'miss')
                basicMsg('Introduzca su correo electrónico');
            else if (body.msg === 'no-req-done')
                valMsg('warning', 'Código no generado',
                'Intente de nuevo, por favor');
            else if (body.msg === 'email-not-send')
                valMsg('warning', 'Código no enviado',
                'Intente de nuevo, por favor');
            else if (body.msg === 'server-err')
                valMsg('error', 'Código',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else basicMsg('Contraseña no confirmada');
        }
        return body;
    } catch (err) {
        basicMsg('No se pudo confirmar su contraseña');
        return { status: false };
    };
};

// remover pedido abandonado de lista
export const removeOrderFromList = (order_num) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('account/remove-order', { order_num }, 'POST');
            const body = await resp.json();
            if (body.status)
                dispatch(orderRemoved(order_num));
            else {
                if (body.msg === 'miss')
                    valMsg('warning', 'Pedido abandonado',
                    'Falta información del pedido');
                else if (body.msg === 'order-not-removed')
                    valMsg('warning', 'Pedido abandonado',
                    'No se puede eliminar este pedido');
                else if (body.msg === 'server-err')
                    valMsg('error', 'Pedido abandonado',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else
                    basicMsg('Pedido no fue eliminado');
            }
        } catch (err) {
            valMsg('error', 'Pedido abandonado',
            'No se pudo eliminar el pedido');
        };
    };
};

// volver a mostrar pedido abandonado en lista (undo)
export const showAgainOrderInList = (order, setData) => {
    return async(dispatch) => {
        try {
            const { order_num } = order;
            const resp = await protectedReq('account/show-order-again', { order_num }, 'POST');
            const body = await resp.json();
            if (body.status) {
                setData(e => ({ ...e, snackbar1: false }));
                dispatch(orderRestored(order));
            } else {
                if (body.msg === 'miss')
                    valMsg('warning', 'Pedido abandonado',
                    'Falta información del pedido');
                else if (body.msg === 'order-not-restored')
                    valMsg('warning', 'Pedido abandonado',
                    'No se puede restaurar este pedido');
                else if (body.msg === 'server-err')
                    valMsg('error', 'Pedido abandonado',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else
                    basicMsg('Pedido no fue restaurado');
            }
        } catch (err) {
            valMsg('error', 'Pedido abandonado',
            'No se pudo restaurar el pedido');
        };
    };
};

// retirar pedido de la lista de pedidos abandonados
export const orderRemoved = (payload) => ({
    type: types.userOrderRemoved, payload
});

export const orderRestored = (payload) => ({
    type: types.userOrderRestored, payload
});
