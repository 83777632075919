import React from 'react';

import {
    Checkbox, List, ListItemButton, ListItemIcon, ListItemText,
    Collapse, ListItem, IconButton, Grid
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

const CategoryList = ({ categories, setCategories }) => {

    // selecciona categoría
    const handleChange = (category) => () => {
        setCategories(e => e.map(
            u => u.category === category ?
                ({
                    ...u, checked: !u.checked,
                    subcategories: u.checked ?
                        u.subcategories.map(
                            a => ({ ...a, checked: false })
                        )
                    : u.subcategories
                })
            : u
        ));
    };

    // selecciona subcategoría
    const handleSubChange = (category, subcategory) => () => {
        setCategories(e => e.map(
            u => u.category === category ?
                ({
                    ...u, subcategories: u.subcategories.map(
                        a => a.subcategory === subcategory ?
                            { ...a, checked: !a.checked }
                        : a
                    )
                })
            : u
        ));
    };

    return (
        <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav">
            <Grid container spacing={2}>
            {
                categories.map(({ _id, category, subcategories, checked }) =>
                    <Grid item xs={12} lg={4} key={_id}>
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end" aria-label="comments">
                                    { checked ? <ExpandLess /> : <ExpandMore /> }
                                </IconButton>
                            }
                            disablePadding
                        >
                            <ListItemButton
                                role={ undefined }
                                onClick={ handleChange(category) }
                                dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={ checked }
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText primary={ category } />
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={ checked } timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    subcategories.length <= 0 ?
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <DoNotDisturbOnIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="No hay subcategorías" />
                                        </ListItemButton>
                                    :
                                        subcategories.map(({ subcategory, checked }) => 
                                            <ListItemButton sx={{ pl: 4 }} key={subcategory}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={ checked }
                                                        onChange={ handleSubChange(category, subcategory) }
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={ subcategory }
                                                />
                                            </ListItemButton>
                                        )
                                }
                            </List>
                        </Collapse>
                    </Grid>
                )
            }
            </Grid>
        </List>
    );
};

export default CategoryList;