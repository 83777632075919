import React, { useState } from 'react';

import KeywordItem from './KeywordItem';

import {
    Grid, TextField, Button, ToggleButton, ToggleButtonGroup,
    Divider, Chip, Box, FormGroup, Alert, Stack, List, styled
} from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

// agregar keyword
const ProductKeywords = ({ keywords, setProdValues }) => {
    const [toggle, setToggle] = useState('list');
    const [keyword, setKeyword] = useState(''); // input

    const handleChange = ({ target }) => {
        setKeyword(target.value);
    };

    // agregar nueva keyword
    const onClick = () => {
        if (keyword.trim().length > 2) {
            setKeyword('');
            setProdValues(e => ({
                ...e,
                keywords: [...e.keywords, keyword]
            }));
            setToggle('list');
        }
    };

    // keywords / añadir keyword
    const onToggle = (event, pos) => {
        if (pos !== null)
            setToggle(pos);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={8}>
                    <Divider>
                        <Chip label="Keywords" sx={{ fontSize: 16 }} />
                    </Divider>
                </Grid>
                <Grid item xs={4}>
                    <Box display="flex" justifyContent="flex-end">
                        <ToggleButtonGroup
                            value={ toggle }
                            exclusive
                            onChange={ onToggle }
                            aria-label="toggle keywords"
                            size="small">
                            <ToggleButton value="list" aria-label="keyword list">
                                <ListIcon />
                            </ToggleButton>
                            <ToggleButton value="add" aria-label="add keyword">
                                <AddIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            {
                toggle === 'add' ? (
                    <Stack direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}>
                        <TextField
                            fullWidth
                            name="keyword"
                            label="Añadir keyword"
                            variant="standard"
                            value={ keyword }
                            onChange={ handleChange } />
                        <Button variant="contained" size="small" onClick={ onClick }>
                            <CheckCircleOutlineSharpIcon fontSize="small" />
                        </Button>
                    </Stack>
                ) :
                    <FormGroup>
                        {
                            keywords.length <= 0 ?
                                <Alert severity="info" sx={{ mt: 2 }}>
                                    Añada keywords
                                </Alert> :
                                <Grid item xs={12} md={6}>
                                    <Demo>
                                        <List>
                                            {
                                                keywords.map((e, i) =>
                                                    <KeywordItem key={i}
                                                        keyword={e}
                                                        setProdValues={setProdValues} />
                                                )
                                            }
                                        </List>
                                    </Demo>
                                </Grid>
                        }
                    </FormGroup>
            }
        </>
    );
};

export default ProductKeywords;