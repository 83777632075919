import { basicMsg, msg } from './swal_msg';
import { types } from '../types/types';

import { generateURL } from '../helpers/admin/productHelper';
import { axiosProtectedReq, protectedReq } from '../helpers/requestHelper';

import { CATEGORY_INIT } from '../constants/admin/category_const';

// cargar categorías y marcas de producto de BD
export const categoriesLoad = () => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('product/category/get');
            const body = await resp.json();
            if (body.status)
                dispatch(categoriesLoaded(body.resp));
            // else
                // basicMsg('No se pudieron cargas las categorías/marcas de producto');
        } catch (err) {
            // msg('warning', 'Productos',
            // 'Problema para recuperar categorías/marca de producto');
        };
    };
};

// categorías y marcas cargadas
const categoriesLoaded = (payload) => ({
    type: types.categoriesLoaded, payload
});

// guardar categoría/subcategorías de producto
export const saveCategory = (model, setMainCategory, setLoading, { flag, setOpen }) => {
    return async(dispatch) => {
        try {
            const { category, subcategories, imgFile } = model;
            const data = new FormData();
            data.append('category', category.toUpperCase());
            data.append('url', generateURL(category));
            data.append('subcategories', JSON.stringify(subcategories.map(e => e.toUpperCase())));
            data.append('file', imgFile);
            const resp = await axiosProtectedReq('product/category/save', data, 'POST');
            if (resp.status) {
                setMainCategory(e => ({ ...e, ...CATEGORY_INIT }));
                dispatch(categoryAdded(resp.resp));
                if (flag)
                    setOpen(false);
            } else {
                if (resp.msg === 'exists') {
                    msg('warning', 'Categoría', 'La categoría ya existe');
                } else if (resp.msg === 'server-err') {
                    msg('warning', 'Categoría',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                } else msg('warning', 'Categoría',
                    'Inténtelo de nuevo, por favor');
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            msg('warning', 'Categoría',
            'No se pudo registrar la categoría');
        };
    };
};

// categoría de producto añadida
const categoryAdded = (payload) => ({
    type: types.categoryAdded, payload
});

// eliminar categorías de producto
export const deleteCategories = (categories) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('product/category/delete-many', { categories }, 'DELETE');
            const body = await resp.json();
            if (body.status)
                dispatch(categoriesDeleted(body.resp));
            else {
                if (resp.msg === 'not-deleted') {
                    msg('warning', 'Categoría',
                    'Una o más categorías pudieron no ser eliminadas');
                } else if (resp.msg === 'server-err') {
                    msg('warning', 'Categoría',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                } else msg('warning', 'Categoría',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msg('warning', 'Categoría',
            'No se pudo eliminar la categoría(s)');
        }
    }
};

// categorías eliminadas
const categoriesDeleted = (payload) => ({
    type: types.categoriesDeleted,
    payload
});


// eliminar categoría/subcategorías de producto
export const deleteCategory = (category, categories) => {
    return async(dispatch) => {
        try {
            const { _id } = category;
            const resp = await protectedReq('product/category/delete', category, 'DELETE');
            const body = await resp.json();
            if (body.status)
                dispatch(categoryDeleted(categories.filter(e => e._id !== _id)));
            else {
                if (body.msg === 'miss')
                    basicMsg('Falta información de categoría');
                else if (body.msg === 'not-deleted')
                    basicMsg('Categoría no eliminada');
                else if (body.msg === 'server-err')
                    msg('error', 'Categoría',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Categoría',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msg('error', 'Categoría',
            'No se pudo registrar la categoría');
        }
    }
};

// categoría eliminada
const categoryDeleted = (payload) => ({
    type: types.categoryDeleted,
    payload
});

// eliminar categoría/subcategorías de producto
export const updateAllCategories = (allCategories, setLoading) => {
    return async(dispatch) => {
        try {
            const category = allCategories.map(({ _id, category, subcategories }) => ({ _id, category, subcategories }));
            const resp = await protectedReq('product/category/update-all', { category }, 'PUT');
            const body = await resp.json();
            if (body.status)
                dispatch(categoriesUpdated(allCategories));
            else {
                if (body.msg === 'miss')
                    basicMsg('No se enviaron las categorías');
                else if (body.msg === 'server-err')
                    msg('error', 'Categorías',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Categorías',
                    'Inténtelo de nuevo, por favor');
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            msg('error', 'Categorías',
            'No se pudieron actualizar las categorías');
        };
    };
};

// categorías actualizadas
const categoriesUpdated = (payload) => ({
    type: types.categoriesUpdated,
    payload
});