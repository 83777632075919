import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { generateURL } from '../../../../helpers/admin/productHelper';
import { productCloseModal, productUpdate } from '../../../../actions/product';
import { form2Validator } from '../../../../validators/Admin/Product/ProductValidator';

import ProductFBT from '../NewProduct/FBT/ProductFBT';
import ProductInfo from '../ProductUpdate/ProductInfo';
import ProductCategory from '../ProductUpdate/ProductCategory';
import ProductImages from '../ProductUpdate/Images/ProductImages';
import ProductKeywords from '../NewProduct/Keywords/ProductKeywords';

import {
  Typography, TextField, ToggleButton, FormGroup,
  FormControlLabel, Container, Box, Switch, Dialog,
  AppBar, Toolbar, IconButton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProductBrand from '../NewProduct/Brand/ProductBrand';

export default function ProductModal() {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:600px)');
  // lista de categorías
  const { categories } = useSelector(state => state.category);
  const { products, active, modal } = useSelector(state => state.product);

  // valor de producto
  const [prodValues, setProdValues] = useState(active);
  const {
    _id: prod_id, title, info, images,
    prod_category, brand, keywords, featured,
    refrigerated, medicine, fbt_item
  } = prodValues;

  // variantes como producto único (se utiliza en ProductFBT.js)
  const [allProducts, setAllProducts] = useState([]);

  // todas las categorías disponibles
  const [allCategories, setCategories] = useState([]);
  // imágenes removidas (a eliminar)
  const [deletedImages, setDeletedImages] = useState([]);
  // imágenes adicionales (nuevas)
  const [additionalImages, setAdditionalImages] = useState([]);
  // botón de carga
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let items = [...fbt_item];
    // tomar cada variante como producto único
    if (products.length > 0) {
      let Products = [];
      for (const e of products) { // productos
        const { prod_variants } = e;
        if (prod_id !== e._id) { // no mostrar producto actual como opción
          for (const f of prod_variants) { // variantes
            const checked = items.some(item =>
              item.prod_id === e._id && item.prod_var_id === f._id
            );
            // es producto comprado frecuentemente
            if (checked) {
              Products = [...Products, {
                prod_id: e._id,
                prod_var_id: f._id,
                title: `${e.title} ${f.variant_title}`.trim(),
                checked
              }];
              // quitar elemento de arreglo
              const i = items.findIndex(item =>
                item.prod_id === e._id && item.prod_var_id === f._id
              );
              items.splice(i, 1);
            } else
              Products = [...Products, {
                prod_id: e._id,
                prod_var_id: f._id,
                title: `${e.title} ${f.variant_title}`.trim(),
                checked
              }];
          }
        }
      };
      setAllProducts(Products);
    }
  }, [prod_id]);
  
  useEffect(() => {
    setProdValues(active);
  }, [active]);
  
  useEffect(() => {
    if (prod_id !== '') {
      if (categories.length > 0) {
        let category_arr = [];
        for (const item of categories) { // categorías de tienda
          // buscar categoría en producto
          const Category = prod_category.find(u => u.category === item.category);
          if (Category) { // producto tiene categoría
            category_arr = [
              ...category_arr,
              {
                ...item, checked: true, // seleccionar categoría
                subcategories: item.subcategories.map(u => ({
                  subcategory: u, // seleccionar subcategoría
                  checked: Category.subcategories.find(a => a === u) ? true : false
                }))
              }
            ]
          } else // categoría no seleccionada
            category_arr = [
              ...category_arr, {
                ...item, checked: false,
                subcategories: item.subcategories.map(e => ({
                  subcategory: e, checked: false
                }))
              }
            ]
        };
        // ordenar primero categorías seleccionadas
        setCategories(category_arr.sort((a, b) => {
          return a.checked < b.checked ? 1 : -1
        }));
      }
    }
  }, [prod_id, categories]);

  // título, url, información
  const handleInputChange = ({ target }) => {
    const name = target.name;
    const value = target.value;
    if (name === 'title') {
      const _url = generateURL(value);
      setProdValues({
        ...prodValues,
        title: value,
        url: _url
      });
    } else {
      setProdValues({
        ...prodValues,
        [name]: value
      });
    }
  };

  // ¿producto destacado?
  const handleToggleChange = ({ target }) => {
    const _name = target.name;
    if (_name === 'featured')
      setProdValues({
        ...prodValues,
        featured: !featured
      });
    else if (_name === 'refrigerated')
      setProdValues({
        ...prodValues,
        refrigerated: target.checked
      });
    else if (_name === 'medicine')
      setProdValues({
        ...prodValues,
        medicine: target.checked
      });
  };

  // cerrar modal
  const handleClose = () => {
    dispatch(productCloseModal());
  };

  // actualizar producto
  const update = () => {
    if (form2Validator(prodValues, allCategories.filter(e => e.checked))) { // validación básica de formulario
      setLoading(true);
      // productos comprados juntos frecuentemente
      // (filtrar productos seleccionados)
      const fbt_item = allProducts.filter(e => e.checked).map(u => {
        const { prod_id, prod_var_id } = u;
        return { prod_id, prod_var_id };
      });

      dispatch(
        productUpdate({
        ...prodValues, fbt_item
        }, allCategories, additionalImages,
        deletedImages, {
          setDeletedImages, setAdditionalImages, setLoading
        })
      );
    }
  };

  return (
    <Dialog
      fullScreen
      open={modal}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ zIndex: 1000 }}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Editar producto
          </Typography>
          <LoadingButton
            loading={ loading }
            color="inherit"
            variant="outlined"
            onClick={ update }>
            Actualizar
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth='xl' sx={{ mt: 5, mb: 2 }}>
        {/* Titulo */}
        <Box sx={{ mb: 3 }}>
          <TextField fullWidth
            name="title"
            label="Título"
            variant="standard"
            value={title}
            inputProps={{ maxLength: 200 }}
            onChange={handleInputChange} />
        </Box>

        <ProductImages
          images={ images }
          setProdValues={ setProdValues }
          setDeletedImages={ setDeletedImages }
        />

        {/* Información adicional */}
        <ProductInfo
          info={ info }
          handleInputChange={ handleInputChange }
          images={ images }
          additionalImages={ additionalImages }
          setAdditionalImages={ setAdditionalImages }
        />

        <Box sx={{ mb: 3 }}>
          <ProductCategory
            categories={ allCategories }
            setCategories={ setCategories }
          />
        </Box>

        <ProductBrand
          brand={ brand }
          setProdValues={ setProdValues }
        />

        {/* Palabras clave */}
        <Box sx={{ mb: 3 }}>
          <ProductKeywords
            setProdValues={setProdValues}
            keywords={keywords} />
        </Box>

        {/* producto destacado */}
        <Box sx={{ mb: 3 }}>
          <ToggleButton fullWidth={matches ? true : false}
            name="featured"
            value="check"
            selected={featured}
            onChange={handleToggleChange}>
            {
              featured ? <>Destacado<StarIcon sx={{ color: '#ffd600' }} /></> :
                <>No destacado<StarBorderIcon /></>
            }
          </ToggleButton>
        </Box>

        {/* necesita refrigeración */}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="refrigerated"
                checked={refrigerated}
                onChange={handleToggleChange} />}
                label="Necesita refrigeración" />
        </FormGroup>

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="medicine"
                checked={medicine}
                onChange={handleToggleChange} />}
                label="Es medicamento" />
        </FormGroup>

        {/* Comprados juntos frecuentemente */}
        <ProductFBT
          allProducts={ allProducts }
          setAllProducts={ setAllProducts } />
      </Container>
    </Dialog>
  );
};