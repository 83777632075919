import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { form1Submitted, sendFooterContact } from '../../actions/forms';

//MUI
import {
    Box, FormControl, Grid, TextField, Typography, MenuItem,
    FormHelperText, Button
} from '@mui/material'
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";

import { regex_email, regex_no_num, regex_num } from '../../constants/regex';
// const tel_regex = /^[0-9]*$/;

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#56C596',
    paddingLeft: '40px',
    paddingRight: '40px',
    borderRadius: '10px',
    fontWeight: '600',
    '&:hover': {
        backgroundColor: '#358f69'
    }
}));

const init = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    msg: ''
};

const initErr = {
    name_err: false,
    email_err: false,
    phone_err: false,
    subject_err: false,
    msg_err: false
};

const subjectTypeSelect = ['Información de productos', 'Queja o sugerencia', 'Otro'];

const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-input": {
            color: "#5552B2"
        },
        "& .MuiInputLabel-root": {
            color: "#56C596"
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#56C596"
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#5552B2"
        },
        "&:hover .MuiInputLabel-root": {
            color: "#3c9570"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3c9570"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#5552B2"
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#56C596"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#56C596"
        },
        '& .MuiSvgIcon-root': {
            color: '#56C596',
        }
    }
});

const ContactForm = ({ txt }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { form1 } = useSelector(state => state.form);

    const [values, setValues] = useState(init);
    const { name, email, phone, subject, msg } = values;

    const [inputErr, setInputErr] = useState(initErr);
    const { name_err, email_err, phone_err, subject_err, msg_err } = inputErr;

    const name_ref = useRef();
    const email_ref = useRef();
    const phone_ref = useRef();
    const msg_ref = useRef();

    useEffect(() => {
        if (form1) {
            setValues(init);
            dispatch(form1Submitted(false));
        }
    }, [form1]);

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'name') {
            if (value === '' || regex_no_num.test(value)) {
                setValues(e => ({
                    ...e,
                    [name]: value
                }));
            }
        } else if (name === 'phone') {
            if (value === '' || regex_num.test(value)) {
                setValues(e => ({
                    ...e,
                    [name]: value
                }));
            }
        } else setValues(e => ({
            ...e,
            [name]: value
        }));
        switch (name) {
            case 'name':
                setInputErr({
                    ...inputErr,
                    name_err: (value.length < 2 ||
                        value.length > 50) ? true : false
                });
                break;
            case 'email':
                setInputErr({
                    ...inputErr,
                    email_err: (value.length < 6 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'phone':
                setInputErr({
                    ...inputErr,
                    phone_err: (value.length < 3 ||
                        value.length > 20) ? true : false
                });
                break;
            case 'subject':
                setInputErr({
                    ...inputErr,
                    subject_err: (value.length < 1) ? true : false,
                });
                break;
            case 'msg':
                setInputErr({
                    ...inputErr,
                    msg_err: (value.length < 6 ||
                        value.length > 1000) ? true : false
                });
                break;
            default:
                break;
        }
    };

    const formValidator = () => {
        const name_len = name.trim().length;
        if (name_len > 1 && name_len <= 60) {
            const email_len = email.trim().length;
            if (email_len > 5 && email_len <= 120 && regex_email.test(email)) {
                const phone_len = phone.trim().length;
                if (phone_len > 2 && phone_len <= 20) {
                    const subject_len = subject.trim().length
                    if (subject_len > 0) {
                        const msg_len = msg.trim().length
                        if (msg_len > 5 && msg_len <= 2000) {
                            return true
                        } else {
                            msg_ref.current.select();
                            setInputErr({
                                ...inputErr,
                                msg_err: true
                            });
                        }
                    } else {
                        setInputErr({
                            ...inputErr,
                            subject_err: true
                        });
                    }
                } else {
                    phone_ref.current.select();
                    setInputErr({
                        ...inputErr,
                        phone_err: true
                    });
                }
            } else {
                email_ref.current.select();
                setInputErr({
                    ...inputErr,
                    email_err: true
                });
            }
        } else {
            name_ref.current.select();
            setInputErr({
                ...inputErr,
                name_err: true
            });
        }
        return false;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formValidator()) {
            dispatch(sendFooterContact(values));
        }
    };

    return (
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}
            order={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
        >
            <Box mb={3} id='contact-form'>
                <Typography sx={{ color: '#5552B2', fontWeight: 'bold', fontSize: '28px' }} align='center' paragraph>
                    ¡Contáctanos!
                </Typography>
                <Typography variant='body1' align='center'>
                    { `${txt} Déjanos tus datos y en breve te contactaremos para brindarte más información.` }
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            className={classes.root}
                            label='Nombre'
                            size='small'
                            name='name'
                            error={name_err}
                            helperText={name_err ? "Ingrese su nombre" : ""}
                            inputRef={name_ref}
                            value={name}
                            onChange={handleInputChange}
                            InputProps={{ sx: { borderRadius: '15px' } }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            className={classes.root}
                            label='Correo electrónico'
                            size='small'
                            name='email'
                            error={email_err}
                            helperText={email_err ? "Ingrese su correo electrónico" : ""}
                            inputRef={email_ref}
                            value={email}
                            onChange={handleInputChange}
                            InputProps={{ sx: { borderRadius: '15px' } }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            className={classes.root}
                            label='Teléfono'
                            size='small'
                            name='phone'
                            error={phone_err}
                            helperText={phone_err ? "Ingrese su número de teléfono" : ""}
                            inputRef={phone_ref}
                            value={phone}
                            onChange={handleInputChange}
                            InputProps={{ sx: { borderRadius: '15px' } }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            className={classes.root}
                            label='Asunto'
                            size='small'
                            name="subject"
                            error={subject_err}
                            value={subject}
                            onChange={handleInputChange}
                            select
                            InputProps={{ sx: { borderRadius: '15px', textAlign: 'left' } }}
                        >
                            {subjectTypeSelect.map((e, i) => (
                                <MenuItem key={i}
                                    value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </TextField>
                        {
                            subject_err &&
                            <FormHelperText
                                error={subject_err}>
                                Escoja el asunto del mensaje
                            </FormHelperText>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            className={classes.root}
                            label='Mensaje'
                            size='small'
                            name='msg'
                            error={msg_err}
                            helperText={msg_err ? "Escriba su mensaje" : ""}
                            inputRef={msg_ref}
                            value={msg}
                            onChange={handleInputChange}
                            multiline
                            rows={6}
                            InputProps={{ sx: { borderRadius: '15px' } }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ mx: 'auto', textAlign: 'center' }}>
                    {/* <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}></Stack> */}
                    <ColorButton variant='contained' onClick={handleSubmit}>Enviar</ColorButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ContactForm;