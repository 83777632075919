import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import './shopcategory.css';

import ShopFilter from './ShopFilter';
import ShopProducts from './ShopProducts';
// import ShopMoreProducts from './ShopMoreProducts';
import SubcategorySidebar from './SubcategorySidebar';
import CategoryContainer from '../Shop/CategoryContainer';

import {
    Container, Breadcrumbs, Grid, Typography, useMediaQuery
} from '@mui/material';

import { ALL_CATEGORIES, ALL_SUBCATEGORIES, CATEGORY_DEFAULT } from '../../../constants/shop/shop_constants';

const ShopCategory = () => {
    const { category_url } = useParams();
    const matches = useMediaQuery('(max-width: 950px)');

    // categorías y productos de tienda
    const { categories, products } = useSelector(state => state.shop);

    // filtro de productos según categoría/subcategoría
    const [filteredProducts, setFilteredProducts] = useState([]);

    // mostrar más productos
    const [moreProducts, setMoreProducts] = useState({
        text: 'Productos en tienda',
        products: []
    });

    // categoría seleccionada
    const [selectedCategory, setSelectedCategory] = useState(CATEGORY_DEFAULT);
    const { category, subcategories } = selectedCategory;

    // subcategoría seleccionada
    const [selectedSubcategory, setSelectedSubcategory] = useState(ALL_SUBCATEGORIES);

    // la ruta (categoría) ha cambiado
    useEffect(() => {
        const found = categories.find(e => e.url === category_url);
        if (found)
            setSelectedCategory(found);
    }, [category_url, categories]);

    // productos actualizados / subcategoría ha cambiado
    // (filtrar productos)
    useEffect(() => {
        if (category === ALL_CATEGORIES)
            setFilteredProducts(products);
        else {
            // const filtered = products.filter(e =>
            //     e.category.some(item => item.toUpperCase() === category.toUpperCase()) &&
            //     (
            //         selectedSubcategory === ALL_SUBCATEGORIES ? true :
            //             e.subcategory.some(
            //                 item => item.toUpperCase() === selectedSubcategory.toUpperCase()
            //             )
            //     )
            // );
            const filtered = products.filter(e =>
                e.prod_category.some(
                    u => u.category === category && (
                        selectedSubcategory === ALL_SUBCATEGORIES ? true :
                        u.subcategories.some(a => a === selectedSubcategory)
                    )
                )
            );
            // ordenar según posición en categoría guardada en producto
            const sorted = filtered.sort(
                (a, b) => {
                    const catA = a.position.categories.find(u => u.url === category_url);
                    const catB = b.position.categories.find(u => u.url === category_url);
                    if (!catA || !catB) return 1;
                    return catA.position < catB.position ? -1 : 1
                }
            );
            setFilteredProducts(sorted);
        }
    }, [products, category, selectedSubcategory]);

    useEffect(() => {
        if (category !== '') {
            setMoreProducts({
                ...moreProducts,
                text: 'Productos en otras categorías',
                products: products.filter(e =>
                    !e.category.find(item => item.toUpperCase() === category.toUpperCase())
                ).sort((a, b) => a.position.more_products - b.position.more_products)
            });
        } else
            setMoreProducts({
                ...moreProducts,
                text: 'Productos en tienda',
                products: products.sort((a, b) => a.position.more_products - b.position.more_products)
            });
    }, [filteredProducts]);

    const myRef = useRef(null);

    const cat_ref_mat = useMediaQuery('(max-width: 991px)');

    return (
        <>
            {/* Productos */}
            <Container maxWidth='xxl' sx={{ mt: matches ? 5 : 18 }} ref={cat_ref_mat ? null : myRef}>
                <Typography variant='h5' sx={{ fontWeight: 'light' }} gutterBottom>
                    {category}
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit"
                        to="/tienda">Tienda
                    </Link>
                    <Typography color="text.primary">Categorías</Typography>
                </Breadcrumbs>

                {
                    cat_ref_mat ?
                        <div ref={myRef}></div>
                        :
                        null
                }

                {/* Filtros ordenar por... */}
                <ShopFilter
                    filteredProducts={filteredProducts}
                    setFilteredProducts={setFilteredProducts}
                />
                {/* fin filtros ordenar por... */}


                <Grid container
                    spacing={3.5}
                    justifyContent="center">
                    {/* Subcategorías - sidebar */}
                    {
                        subcategories.length > 0 &&
                        <SubcategorySidebar
                            subcategories={subcategories}
                            setSelectedSubcategory={setSelectedSubcategory}
                        />
                    }

                    {/* Productos */}
                    <ShopProducts
                        myRef={myRef}
                        filteredProducts={filteredProducts}
                        category={category}
                    />
                </Grid>
            </Container>

            <Container maxWidth='xl' sx={{ mt: 7 }} className="last-view">
                <Typography variant='h5' sx={{ fontWeight: 'bold' }} textAlign={'center'} paragraph>
                    Explorar otras categorías
                </Typography>
                <CategoryContainer />
            </Container>
            {/* Fin Productos */}
        </>
    );
};

export default ShopCategory;