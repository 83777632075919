import { types } from '../types/types';

const initialState = {
    categories: [],
    brands: []
};

export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.categoriesLoaded:
            return {
                ...state,
                ...action.payload
            };
        case types.categoryAdded:
            return {
                ...state,
                categories: [
                    ...state.categories,
                    action.payload
                ]
            };
        case types.categoryDeleted:
            return {
                ...state,
                categories: [...action.payload]
            };
        case types.categoriesDeleted:
            return {
                ...state,
                categories: state.categories.filter(e => (!action.payload.includes(e._id)))
            };
        case types.categoriesUpdated:
            return {
                ...state,
                categories: action.payload
            };
        case types.brandAdded:
            return {
                ...state,
                brands: [
                    ...state.brands,
                    action.payload
                ]
            };
        case types.brandsDeleted:
            return {
                ...state,
                brands: state.brands.filter(e => (!action.payload.includes(e._id)))
            };
        default:
            return state;
    };
};