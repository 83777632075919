export const types = {
    adminListLoaded: '[admin] Admin list loaded',
    adminSelected: '[admin] Admin is active',
    adminRegistered: '[admin] Admin registered',
    adminUpdated: '[admin] Admin updated',
    adminDeleted: '[admin] Admin deleted',
    adminOrders: '[admin] Admin orders loaded',

    blogLoaded: '[blog] Blog loaded',
    blogSaved: '[blog] Blog entry saved',
    blogUpdated: '[blog] Blog entry updated',
    blogInEdition: '[blog] Blog is being updated',
    blogDeleted: '[blog] Blog entry deleted',
    blogCategoriesLoaded: '[blog] Blog categories loaded',
    blogCategorySaved: '[blog] Blog category saved',
    blogBlockInEdition: '[blog] Block is being edited',

    userBlogLoaded: '[blog] Nefrópolis blog loaded',

    checkoutTaxSystem: '[checkout] Tax system data loaded',
    checkoutCFDI: '[checkout] CFDI data loaded',

    couponAdded: '[coupon] Coupon added',
    couponsLoaded: '[coupon] Coupons loaded',
    couponOpenModal: '[coupon] Open coupon modal',
    couponCloseModal: '[coupon] Close coupon modal',
    couponEdit: '[coupon] Coupon is being edited',
    couponUpdated: '[coupon] Coupon updated',
    couponDeleted: '[coupon] Coupon deleted',
    couponSelected: '[coupon] Coupon selected',
    couponsSelectedDeleted: '[coupon] Coupons selected deleted',

    categoriesLoaded: '[category] Product categories & brands loaded',
    categoryAdded: '[category] Product category added',
    categoryDeleted: '[category] Product category deleted',
    categoriesDeleted: '[category] Product categories deleted',
    categoriesUpdated: '[category] Product categories updated',

    brandAdded: '[brand] Product brand added',
    brandsDeleted: '[brand] Product brand(s) deleted',
    brandUpdated: '[brand] Product brand(s) updated',

    doctorsLoaded: '[doctor] Doctor list loaded',
    doctorUpdated: '[doctor] Doctor updated',
    doctorDeleted: '[doctor] Doctor deleted',

    productListLoaded: '[product] Product list loaded',
    productDefault: '[product] Product reset',
    productActive: '[product] Product is active',
    productEdit: '[product] Product is being edited',
    productCloseModal: '[product] Product is no longer being edited',
    productUpdated: '[product] Product updated',
    productDeleted: '[product] Product and variants deleted',
    productSnackbarOpen: '[product] Variant images uploaded (open snackbar)',
    productSnackbarClose: '[product] Variant images uploaded (close snackbar)',

    activeVariants: '[product] Product variants are shown',
    variantEdit: '[product] Variant is being edited',
    variantSaved: '[product] Variant saved',
    variantUpdated: '[product] Variant updated',
    variantDeleted: '[product] Variant deleted',
    variantCloseModal: '[product] Variant is no longer being edited',
    
    ui_categoryToggle: '[ui] Category toggle change',
    ui_variantAccordion: '[ui] VariantAdd accordion',
    ui_adminUserModal: '[ui] AdminUserList modal',
    ui_adminUserDialog: '[ui] AdminUserList dialog',
    ui_loginModal: '[ui] LoginComponent modal',
    ui_formsModal: '[ui] Forms modal',
    ui_commentLoading: '[ui] Comment process loading',
    ui_commentModal: '[ui] Comment modal',
    ui_shopSnackbar: '[ui] Shop snackbar',
    ui_shopFBTAdded: '[ui] Shop products FBT added to cart',
    uiLoading1: '[ui] Loading Melonn shipping methods',
    uiLoading2: '[ui] Saving blog (admin)',
    loginCodeModal: '[ui] Login code modal [/login]',
    userOrdersTab: '[ui] User orders tab [/mi-cuenta/mis-pedidos]',
    
    shippingMethodsLoaded: '[shop] Nefrópolis shipping methods loaded',
    shippingMethodSelected: '[shop] Shipping method selected',
    shippingMethodSaved: '[shop] Shipping method saved',
    shippingMethodsDeleted: '[shop] Shipping methods deleted',
    shopProductsLoaded: '[shop] Nefrópolis products loaded',
    shopInfoLoaded: '[shop] Product categories and shop banners loaded',
    shopCouponsLoaded: '[shop] Shop coupons loaded',
    shoppingCart: '[shop] Shopping cart loaded',
    shopClearAction: '[shop] Shop clear action made',
    shopUpdateQuantity: '[shop] Update product quantity in shopping cart',
    shopEmptyCart: '[shop] Empty shopping cart',
    shopCoupon: '[shop] Coupon applied to shopping cart',
    shopRedirect: '[shop] Redirect to product page',
    shopProductViewed: '[shop] Shop product viewed',

    reviewAdded: '[shop] Review made',
    reviewSelected: '[shop] Review is active',
    reviewUpdated: '[shop] Review updated',
    reviewDeleted: '[shop] Review deleted',

    form1Submitted: '[form] Complaints & suggestions form',
    form2Submitted: '[form] Postulation form',
    form3Submitted: '[form] Distributors form',
    form1Backdrop: '[form] Complaints & suggestions backdrop',
    form2Backdrop: '[form] Postulation backdrop',
    form3Backdrop: '[form] Distributors backdrop',
    formListLoaded: '[form] Form list loaded',
    formRegistered: '[form] Form registered',
    formReset: '[form] Form fields reset',

    userLoggedIn: '[user] User logged in',
    userLoggedOut: '[user] User logged out',
    loadingDone: '[user] User token loading done',
    clearRdrPage: '[user] Clear redirection page',
    userOrdersLoaded: '[user-account] User orders loaded (mi-cuenta)',
    userOrderSelected: '[user-account] User order selected (mi-cuenta)',
    userOrderRemoved: '[user-account] User order removed from list (mi-cuenta)',
    userOrderRestored: '[user-account] User order restored to list (mi-cuenta)'
};