import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loggedIn } from '../../../actions/user';
import { confirmNewPassword, sendConfirmCode } from '../../../actions/user-account';

import {
    FormControl, InputLabel, OutlinedInput, InputAdornment,
    IconButton, Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Button, Stack, TextField
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { regex_num } from '../../../constants/regex';
const INIT = { pwd1: '', pwd2: '', code: '' };
const INIT2 = {
    show1: false, // mostrar contraseña
    show2: false, // mostrar contraseña (campo 2)
    loading1: false,
    loading2: false,
    code_err: false,
    code_field: false // actualizar/confirmar contraseña (mostrar campo)
};

const NewPasswordDialog = ({ dialog2, setFieldStatus }) => {
    const dispatch = useDispatch();
    // usuario iniciado
    const { loggedUser } = useSelector(state => state.user);
    const { email } = loggedUser;

    // referencias a campos
    const cInputRef = useRef();

    const [data, setData] = useState(INIT);
    const { pwd1, pwd2, code } = data;

    const [interfaceData, setInterfaceData] = useState(INIT2);
    const {
        show1, show2, loading1, loading2, code_err, code_field
    } = interfaceData;

    const handleOnChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (['pwd1', 'pwd2'].includes(name)) {
            if (value === '' || value.length <= 60)
                setData(e => ({ ...e, [name]: value }));
        } else if (name === 'code' && code_field) {
            if (value === '' || (value.length <= 6 && regex_num.test(value)))
                setData(e => ({ ...e, code: value }));
            setInterfaceData(e => ({
                ...e, code_err: value.length !== 6 ? true : false
            }));
        }
    };
    
    // solicitar código para confirmar contraseña
    const requestCode = async () => {
        setInterfaceData(e => ({
            ...e, loading1: true
        }));
        const req = await sendConfirmCode(email);
        if (req.status) {
            setInterfaceData(e => ({
                ...e, code_field: true, loading1: false
            }));
        } else
            setInterfaceData(e => ({
                ...e, loading1: false
            }));
    };

    // confirmar nueva contraseña
    const confirmPassword = async () => {
        setInterfaceData(e => ({ ...e, loading2: true }));
        const req = await confirmNewPassword({ code, email, password: pwd1 });
        if (req.status) {
            dispatch(loggedIn({ no_pwd: false }));
            setInterfaceData(e => ({
                ...e, ...INIT2
            }));
            setFieldStatus(e => ({ ...e, dialog2: false }));
            setData(e => ({ ...e, ...INIT }));
        } else
            setInterfaceData(e => ({
                ...e, loading2: false
            }));
    };

    return (
        <Dialog open={ dialog2 }
            onClose={ () => setFieldStatus(e => ({ ...e, dialog2: false })) }
            sx={{ zIndex: 1000 }}>
            <DialogTitle>Nueva contraseña</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Necesita establecer una contraseña para inicio de sesión y futuros cambios en su cuenta.
                </DialogContentText>

                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Contraseña nueva</InputLabel>
                    <OutlinedInput
                        type={ show1 ? 'text' : 'password' }
                        style={{ backgroundColor: '#fff' }}
                        name='pwd1'
                        value={ pwd1 }
                        inputProps={{ maxLength: 60 }}
                        onChange={ handleOnChange }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={ () => setInterfaceData(e => ({ ...e, show1: !e.show1 })) }
                                    edge="end"
                                >
                                    {
                                        show1 ?
                                            <i className="far fa-eye-slash" style={{ fontSize: '1.3rem' }}></i>
                                        :
                                            <i className="far fa-eye" style={{ fontSize: '1.3rem' }}></i>
                                    }
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Contraseña nueva"
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel>Contraseña nueva</InputLabel>
                    <OutlinedInput
                        type={ show2 ? 'text' : 'password' }
                        style={{ backgroundColor: '#fff' }}
                        name='pwd2'
                        value={ pwd2 }
                        inputProps={{ maxLength: 60 }}
                        onChange={ handleOnChange }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={ () => setInterfaceData(e => ({ ...e, show2: !e.show2 })) }
                                    edge="end"
                                >
                                    {
                                        show2 ?
                                            <i className="far fa-eye-slash" style={{ fontSize: '1.3rem' }}></i>
                                        :
                                            <i className="far fa-eye" style={{ fontSize: '1.3rem' }}></i>
                                    }
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Contraseña nueva"
                    />
                </FormControl>
                <Stack spacing={2} sx={{ mt: 2 }}
                        alignItems='flex-start'>
                        {
                            code_field &&
                                <TextField
                                    label="Código de acceso"
                                    type="code"
                                    variant="standard"
                                    error={ code_err }
                                    helperText={ code_err ? "6 dígitos" : "" }
                                    inputRef={ cInputRef }
                                    inputProps={{ maxLength: 6 }}
                                    name="code"
                                    value={ code }
                                    onChange={ handleOnChange }
                                    disabled={ loading2 }
                                />
                        }
                    </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant='text'
                    onClick={ () => setFieldStatus(e => ({ ...e, dialog2: false })) }>
                    Cancelar
                </Button>
                {
                    code_field ?
                        loading2 ?
                            <LoadingButton
                                loading
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined">
                                Confirmar
                            </LoadingButton>
                        :
                            <Button variant='contained'
                                onClick={ confirmPassword }>
                                Confirmar
                            </Button>
                    :
                        loading1 ?
                            <LoadingButton
                                loading
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined">
                                Actualizar
                            </LoadingButton>
                        :
                            <Button variant='outlined'
                                onClick={ requestCode }>
                                Actualizar
                            </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default NewPasswordDialog;