import axios from 'axios';
import { msg } from "../swal_msg";
import { uiLoading2 } from '../ui';
import { types } from "../../types/types";
import { protectedReq } from "../../helpers/requestHelper";

const base_url = process.env.REACT_APP_NEFRO_API_URL;

export const loadBlog = () => {
    // cargar blog para módulo de admin
    return async(dispatch) => {
        try {
            const resp = await protectedReq('nefro-blog/list');
            const body = await resp.json();
            if (body.status)
                dispatch(blogLoaded(body.resp));
        } catch (err) {};
    }
};

const blogLoaded = (payload) => ({
    type: types.blogLoaded,
    payload
});

// categorías de blog cargadas / selección de categoría (checked)
export const blogCategoriesLoaded = (payload) => ({
    type: types.blogCategoriesLoaded,
    payload
});

// guardar categoría de blog
export const saveBlogCategory = (category) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('nefro-blog/save-category', { category }, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { _id } = body.resp;
                dispatch(categorySaved({
                    _id, category, checked: true
                }));
            } else {
                if (body.msg === 'not-saved')
                    msg('warning', 'Blog',
                    'Categoría no guardada');
                else if (body.msg === 'server-err')
                    msg('error', 'Blog',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Blog',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msg('error', 'Blog',
            'No se pudo guardar la categoría');
        }
    }
};

// categorías cargadas correctamente
const categorySaved = (payload) => ({
    type: types.blogCategorySaved,
    payload
});

// bloque está siendo editado
export const setBlockInEdition = (payload) => ({
    type: types.blogBlockInEdition,
    payload
});

// subir imágenes de contenido y principal
export const uploadBlogContentImages = async(data, endpoint) => {
    const url = `${base_url}/nefro-blog/content-images-${endpoint}`;
    // token guardado
    const token = localStorage.getItem('token') || '';
    try {
        const config = {
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            }
        };
        return await axios.post(url, data, config).then(function(response) {
            const { data: { resp } } = response;
            return { status: true, resp };
        })
        .catch(function(err) {
            msg('error', 'Blog',
            'Los archivos no se han subido');
            return { status: false };
        });
    } catch (err) {
        msg('error', 'Blog',
        'No se subieron los archivos');
        return { status: false };
    }
};

// guardar blog
export const saveBlogDB = (model) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('nefro-blog/save', model, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { _id } = body.resp;
                dispatch(blogSaved({
                    _id, ...model
                }));
            } else {
                if (body.msg === 'not-saved')
                    msg('warning', 'Blog',
                    'Blog no fue guardado');
                else if (body.msg === 'server-err')
                    msg('error', 'Blog',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Blog',
                    'Inténtelo de nuevo, por favor');
            }
            dispatch(uiLoading2(false));
        } catch (err) {
            msg('error', 'Blog',
            'No se pudo guardar el blog');
            dispatch(uiLoading2(false));
        }
    }
};

// blog guardado
const blogSaved = (payload) => ({
    type: types.blogSaved,
    payload
});

// bloque está siendo editado
export const setBlogInEdition = (payload) => ({
    type: types.blogInEdition,
    payload
});

// obtener blog para actualizar (blogs no cargados previamente)
export const getBlogToUpdate = (url) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('nefro-blog/get-blog', { url }, 'POST');
            const body = await resp.json();
            if (body.status)
                dispatch(setBlogInEdition(body.resp));
            else {
                if (body.msg === 'server-err')
                    msg('error', 'Blog',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Blog',
                    'Blog no fue encontrado');
            }
        } catch (err) {
            msg('error', 'Blog',
            'No se pudo encontrar el blog');
        }
    }
};

// actualizar blog
export const updateBlogDB = (model, files) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('nefro-blog/update', model, 'PUT');
            const body = await resp.json();
            if (body.status) {
                dispatch(blogUpdated(model));
                if (files.length > 0)
                    deleteBlogFiles(files);
            } else {
                if (body.msg === 'not-saved')
                    msg('warning', 'Blog',
                    'Blog no fue actualizado');
                else if (body.msg === 'server-err')
                    msg('error', 'Blog',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Blog',
                    'Inténtelo de nuevo, por favor');
            }
            dispatch(uiLoading2(false));
        } catch (err) {
            msg('error', 'Blog',
            'No se pudo actualizar el blog');
            dispatch(uiLoading2(false));
        }
    }
};

// blog actualizado
const blogUpdated = (payload) => ({
    type: types.blogUpdated,
    payload
});

// eliminar archivos de Drive (imagen principal / contenido)
export const deleteBlogFiles = (files) => {
    try {
        protectedReq('nefro-blog/delete-files', { files }, 'DELETE');
    } catch (err) {}
};

// eliminar blog
export const deleteBlogDB = (model) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('nefro-blog/delete', model, 'DELETE');
            const body = await resp.json();
            if (body.status) {
                const { _id } = model;
                dispatch(blogDeleted(_id));
            } else {
                if (body.msg === 'not-deleted')
                    msg('warning', 'Blog',
                    'Blog no fue eliminado');
                else if (body.msg === 'server-err')
                    msg('error', 'Blog',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Blog',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msg('error', 'Blog',
            'No se pudo eliminar el blog');
        }
    }
};

// blog eliminado
const blogDeleted = (payload) => ({
    type: types.blogDeleted,
    payload
});

// url mediante título de blog
export const getBlogURL = (title) => {
    return title.normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/\s+/g,"-").replace(/[`~#$%&@!|*/,.<>;':"/[]{}()=_+]/g, '').toLowerCase();
};