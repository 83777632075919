import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';

import PersonalData from "./PersonalData";
import PasswordDialog from "./PasswordDialog";
import NewPasswordDialog from "./NewPasswordDialog";

import { logout } from "../../../actions/user";
import { basicMsg } from "../../../actions/swal_msg";
import { updatePwd } from "../../../actions/user-account";

//CSS
import './infoseguridad.css';

//MUI
import {
    Box, Container, Grid, Typography, Breadcrumbs,
    FormControl, Button, IconButton, OutlinedInput,
    InputLabel, InputAdornment, Alert, AlertTitle,
    useMediaQuery,
    Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const STYLES = {
    SHOW: { display: "none" },
    HIDE: { display: "flex" }
};
const { SHOW, HIDE } = STYLES;

const UISComponent = () => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width: 950px)');

    /*************************** Cambio de datos personales ********************************/
    // usuario iniciado
    const { loggedUser, loading: loadingUser } = useSelector(state => state.user);
    const { email, no_pwd } = loggedUser;

    const [user, setUser] = useState({
        email: '', username: '', fullname: ''
    });

    // contraseña de Dialog
    const [mainPassword, setMainPassword] = useState('');
    
    const [fieldStatus, setFieldStatus] = useState({
        dialog1: false, // Dialog actualizar contraseña
        dialog2: false,// Dialog nueva contraseña
        save_button: false, // Button guardar cambios
        text_fields: true, // TextFields información
        edit_button: false, // Button editar
        pwd_button: false, // Button actualizar contraseña
        pwd_form: false, // actualización de contraseña
        disabled: false
    });
    const { dialog1, dialog2, pwd_button, pwd_form, disabled } = fieldStatus;

    useEffect(() => {
        if (!loadingUser) { // se comprobó sesión de usuario
            if (email !== '') // usuario iniciado
                setUser(loggedUser);
            else {
                basicMsg('Inicie sesión para acceder');
                navigate('/mi-cuenta');
            }
        }
    }, [loadingUser]);

    const closeForm = () => {
        setFieldStatus(e => ({
            ...e, pwd_button: false,
            pwd_form: false, disabled: false
        }));
    };

    return (
        <>
            {/* Titulo y breadcrumb */}
            <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant='h4'
                    sx={{ fontWeight: 'light' }}
                    gutterBottom>
                        Información y seguridad
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/mi-cuenta">
                        Mi cuenta
                    </Link>
                    <Typography color="text.primary">Información de la cuenta</Typography>
                </Breadcrumbs>
            </Container>
            {/* Fin titulo y breadcrumb */}

            <PersonalData
                fieldStatus={ fieldStatus }
                setFieldStatus={ setFieldStatus }
                user={ user }
                setUser={ setUser }
            />

            <Container maxWidth='xl' sx={{ mt: 6 }} className="last-view">
                <Box sx={{ boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)', p: 3, borderRadius: '3px' }}>
                    <Stack direction="row"
                        sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}>
                        <Typography variant='h5'
                            sx={{ fontWeight: 'light' }} gutterBottom>
                            Nueva contraseña
                        </Typography>
                        <Button variant="outlined"
                            size="small" startIcon={<CloseIcon />}
                            onClick={ closeForm } style={ pwd_form ? HIDE : SHOW }>
                            Cancelar
                        </Button>
                    </Stack>
                    
                    {
                        no_pwd ?
                            <Alert severity="info" sx={{ mb: 2 }}>
                                <AlertTitle>Debe establecer una contraseña en su cuenta</AlertTitle>
                                <Button variant='contained'
                                    onClick={ () => setFieldStatus(e => ({ ...e, dialog2: true })) }>
                                    Establecer
                                </Button>
                            </Alert>
                        :
                            pwd_form ?
                                <FormPass
                                    disabled={ disabled }
                                    setFieldStatus={ setFieldStatus }
                                />
                            :
                                <Button variant='contained'
                                    disabled={ pwd_button }
                                    onClick={
                                        () => setFieldStatus(e => ({ ...e, dialog1: true }))
                                    }>
                                    Actualizar
                                </Button>
                    }
                </Box>
            </Container>

            {/* Dialog */}
            <PasswordDialog
                dialog1={ dialog1 }
                setFieldStatus={ setFieldStatus }
                mainPassword={ mainPassword }
                setMainPassword={ setMainPassword }
            />

            <NewPasswordDialog
                dialog2={ dialog2 }
                setFieldStatus={ setFieldStatus }
            />
        </>
    );
};

const FormPass = ({ disabled, setFieldStatus }) => {
    const dispatch = useDispatch();
    const { loggedUser: { no_pwd } } = useSelector(state => state.user);

    // campos de contraseña
    const [pwds, setPwds] = useState({ pwd1: '', pwd2: '' });
    const { pwd1, pwd2 } = pwds;
    const pwdChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setPwds({ ...pwds, [name]: value });
    };

    //Metodo para mostrar y ocultar contraseña
    const [values, setValues] = useState(false)
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    //Segundo input de contraseña
    const [values1, setValues1] = useState(false)
    const handleClickShowPassword1 = () => {
        setValues1({ ...values1, showPassword: !values1.showPassword });
    };

    //Submit password
    const handleSubmitPass = async (e) => {
        e.preventDefault();
        setFieldStatus(e => ({
            ...e, disabled: true
        }));
        const req = await updatePwd(pwd1);
        if (req.status) {
            setFieldStatus(e => ({
                ...e, pwd_button: false,
                pwd_form: false, disabled: false
            }));
        } else {
            setFieldStatus(e => ({
                ...e, disabled: false
            }));
            if (['no-token', 'not-logged-in', 'unavailable-token', 'invalid-token'].includes(req.msg)) {
                basicMsg("Su sesión expiró");
                dispatch(logout());
            }
        }
    };

    return (
        <form style={{ marginTop: '25px' }} action="" onSubmit={handleSubmitPass}>
            <Grid container spacing={3}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Nueva contraseña</InputLabel>
                        <OutlinedInput
                            type={values.showPassword ? 'text' : 'password'}
                            style={{ backgroundColor: '#fff' }}
                            name='pwd1'
                            value={pwd1}
                            onChange={pwdChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {
                                            values.showPassword ?
                                                <i className="far fa-eye-slash" style={{ fontSize: '1.3rem' }}></i>
                                            :
                                                <i className="far fa-eye" style={{ fontSize: '1.3rem' }}></i>
                                        }
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Nueva contraseña"
                        />
                    </FormControl>
                </Grid>

                {
                    !no_pwd &&
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Confirmar contraseña</InputLabel>
                                <OutlinedInput
                                    type={values1.showPassword ? 'text' : 'password'}
                                    style={{ backgroundColor: '#fff' }}
                                    name='pwd2'
                                    value={pwd2}
                                    onChange={pwdChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword1}
                                                edge="end"
                                            >
                                                {
                                                    values1.showPassword ?
                                                        <i className="far fa-eye-slash" style={{ fontSize: '1.3rem' }}></i>
                                                    :
                                                        <i className="far fa-eye" style={{ fontSize: '1.3rem' }}></i>
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirmar contraseña"
                                />
                            </FormControl>
                        </Grid>
                }

                <Grid item xs={12}>
                    <Button variant='contained'
                        type="submit" disabled={ disabled }>
                        Actualizar
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default UISComponent;