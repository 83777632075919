import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
    Card, CardActions, Grid, CardMedia, Container, IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { GOOGLE_DRIVE_CDN } from '../../../../../constants/constants';

const ProductImages = ({ images, setProdValues, setDeletedImages }) => {

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        setProdValues(e => ({
            ...e, images: items
        }));
    };

    // remueve imagen de producto
    const removeImg = name => () => {
        setDeletedImages(e => e.indexOf(name) === -1 ? ([...e, name]) : e);
        setProdValues(e => ({
            ...e,
            images: e.images.filter(u => u !== name)
        }));
    };

    return (
        <Container maxWidth='xl' sx={{ mt: 2, mb: 4 }}>
            <DragDropContext onDragEnd={ handleOnDragEnd }>
                <Droppable droppableId="product-images">
                    {(provided) => (
                        <Grid container spacing={2}
                            { ...provided.droppableProps }
                            ref={ provided.innerRef }>
                            {
                                images.length > 0 &&
                                images.map((item, i) => 
                                    <Draggable key={ item }
                                        draggableId={ item } index={i}>
                                        {(provided) => (
                                            <Grid item xs={12} md={4}>
                                                <Card key={i}
                                                    ref={ provided.innerRef }
                                                    { ...provided.draggableProps }
                                                    { ...provided.dragHandleProps }>
                                                    <CardMedia
                                                        component="img"
                                                        height="194"
                                                        image={ `${GOOGLE_DRIVE_CDN}${item}` }
                                                        alt="imagen-adicional"
                                                    />
                                                    <CardActions disableSpacing>
                                                        <IconButton aria-label="delete"
                                                            onClick={ removeImg(item) }>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Draggable>
                                )
                            }
                            { provided.placeholder }
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        </Container>
    );
};

export default ProductImages;