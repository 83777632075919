import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Products from './Products';
import Category from './Category';
import { productSortList, saveListOrder } from '../../../../actions/admin/product/product_info';

import {
	Grid, Typography, Box, Tab, Tabs, Fab,
	Snackbar, Alert
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

function a11yProps(index) {
	return {
	  	id: `simple-tab-${index}`,
	  	'aria-controls': `simple-tabpanel-${index}`,
	};
};

const SnackbarObj = {
	saving: {
		message: 'Guardando cambios...',
		severity: 'info',
		variant: 'standard'
	},
	saved: {
		message: 'Guardado',
		severity: 'success',
		variant: 'filled'
	},
}

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const ShopSort = () => {
	const navigate = useNavigate();
	// Tabs
	const [value, setValue] = useState(0);
	// Productos
	const [data, setData] = useState({
		shop_products: [], categories: [], more_products: []
	});
	const { shop_products, categories, more_products } = data;
	
	// Snackbar
	const [snackPack, setSnackPack] = useState([]);
  	const [open, setOpen] = useState(false);
  	const [messageInfo, setMessageInfo] = useState(undefined);
	// Button
	const [disabled, setDisabled] = useState(false);

	// contador de cambios
	const [changes, setChanges] = useState(0);

	useEffect(() => {
		getProductList();
	}, []);

	// cambios en lista
	useEffect(() => {
		// más de 5 movimientos, guardar cambios
		if (changes >= 5) {
			save();
			setChanges(0);
		} else
			setChanges(changes + 1);
	}, [data]);

	useEffect(() => {
		if (snackPack.length && !messageInfo) {
			// Set a new snack when we don't have an active one
			setMessageInfo({ ...snackPack[0] });
			setSnackPack((prev) => prev.slice(1));
			setOpen(true);
		} else if (snackPack.length && messageInfo && open) {
			// Close an active snack when a new one is added
			setOpen(false);
		}
	}, [snackPack, messageInfo, open]);

	// lista de productos
	const getProductList = async () => {
		const req = await productSortList();
		if (req.status)
			setData(req.resp);
		else {
			if (req.msg === 'redirect')
				navigate('/login');
		}
	};

	// cambio de Tab
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// abrir y cerrar Snackbar
	const handleClick = (key) => {
		setDisabled(true);
		setOpen(true);
		setSnackPack((prev) => [...prev, {
			...SnackbarObj[key], key: new Date().getTime()
		}]);
	};
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		  	return;
		}
	
		setOpen(false);
	};
	const handleExited = () => {
		setMessageInfo(undefined);
	};

	// guardar cambios 
	const save = async () => {
		handleClick('saving');
		const req = await saveListOrder(data);
		if (req.status)
			handleClick('saved')
		setDisabled(false);
	};

    return (
        <Grid container
			sx={{ backgroundColor: '#F5F5F5', p: 4 }}>
			<Grid item xs={12}>
				<Box sx={{
					backgroundColor: 'white',
					borderBottom: 1,
					borderColor: 'divider'
				}}>
					<Tabs value={ value }
						onChange={ handleChange }
						aria-label="products tab">
						<Tab label="Tienda" {...a11yProps(0)} />
						{
							categories.map(({ _id, category }, i) =>
								<Tab key={_id}
									label={ category }
									{...a11yProps(i+1)}
								/>
							)
						}
						<Tab label="Más productos" {...a11yProps(categories.length+1)} />
					</Tabs>
				</Box>
			</Grid>
{/* 
			<Grid item xs={12}>
				<Button onClick={handleClick('saving')}>Show message A</Button>
				<Button onClick={handleClick('saved')}>Show message B</Button>
			</Grid> */}

			<Grid item xs={12}>
				<Products
					CustomTabPanel={ CustomTabPanel }
					value={ value }
					index={ 0 }
					products={ shop_products }
					pkey={ 'shop_products' }
					setData={ setData }
				/>
			</Grid>

			{
				categories.map(({ _id }, i) =>
					<Grid item xs={12} key={_id}>
						<Category
							CustomTabPanel={ CustomTabPanel }
							value={ value }
							data={ data }
							setData={ setData }
							i={i+1}
						/>
					</Grid>
				)
			}

			<Grid item xs={12}>
				<Products
					CustomTabPanel={ CustomTabPanel }
					value={ value }
					index={ categories.length + 1 }
					products={ more_products }
					pkey={ 'more_products' }
					setData={ setData }
				/>
			</Grid>

			<Grid item xs={12}>
				<Fab color="primary"
					aria-label="save"
					sx={{
						position: "fixed",
						bottom: (theme) => theme.spacing(2),
						right: (theme) => theme.spacing(2)
					}}
					disabled={ disabled }
					onClick={ save }>
					<SaveIcon />
				</Fab>
			</Grid>

			<Grid item xs={12}>
				<Snackbar
					key={ messageInfo ? messageInfo.key : undefined }
					open={ open }
					autoHideDuration={ 5000 }
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					onClose={ handleClose }
					TransitionProps={{ onExited: handleExited }}
					>
					<Alert
						onClose={ handleClose }
						severity={ messageInfo ? messageInfo.severity : undefined }
						variant={ messageInfo ? messageInfo.variant : undefined }
						sx={{ width: '100%' }}
					>
						{ messageInfo ? messageInfo.message : undefined }
					</Alert>
				</Snackbar>
			</Grid>
        </Grid>
    );
};

export default ShopSort;