import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { basicMsg } from '../../../actions/swal_msg';
import { loggedIn, logout } from '../../../actions/user';
import { updateMyData } from '../../../actions/user-account';

import {
    Box, Container, Grid, FormControl, TextField,
    Button, ButtonGroup
} from '@mui/material';

const PersonalData = ({ fieldStatus, setFieldStatus, user, setUser }) => {
    const dispatch = useDispatch();
    // usuario iniciado
    const { loggedUser } = useSelector(state => state.user);
    const { email } = loggedUser;

    const { save_button, text_fields, edit_button } = fieldStatus;

    const { username, fullname } = user;

    // desactiva TextField y oculta Button (submit)
    const handleSubmit = async (e) => {
        e.preventDefault();
        const _username = username.trim();
        const _fullname = fullname.trim();
        if (_username.length > 1 && _username.length <= 100) {
            if (_fullname.length > 3 && _fullname.length <= 255) {
                const req = await updateMyData({
                    username: _username,
                    fullname: _fullname
                });
                if (req.status) {
                    setFieldStatus(e => ({
                        ...e,
                        save_button: false,
                        text_fields: true,
                        edit_button: false
                    }));
                    loggedIn({ ...loggedUser, username });
                } else {
                    if (['no-token', 'not-logged-in', 'unavailable-token', 'invalid-token'].includes(req.msg)) {
                        basicMsg("Su sesión expiró");
                        dispatch(logout());
                    }
                }
            } else basicMsg('Ingrese su nombre completo');
        } else basicMsg('Ingrese un nombre válido');
    };

    const handleOnChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setUser({ ...user, [name]: value });
    };

    return (
        <Container maxWidth='xl' sx={{ mt: 6 }}>
            <Box sx={{ p: 4 }} className="info-form">
                {/* formulario de información personal */}
                <form id="form-user-info" action="" onSubmit={ handleSubmit }>
                    <Grid container spacing={3}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField label="Nombre(s)"
                                    variant="outlined"
                                    name="username"
                                    value={ username }
                                    onChange={ handleOnChange }
                                    disabled={ text_fields }
                                    inputProps={{ maxLength: 100 }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField label="Correo electrónico"
                                    variant="outlined"
                                    name="email"
                                    value={ email }
                                    disabled={ true }
                                    inputProps={{ maxLength: 120 }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField label="Nombre completo"
                                    variant="outlined"
                                    name="fullname"
                                    value={ fullname }
                                    onChange={ handleOnChange }
                                    disabled={ text_fields }
                                    inputProps={{ maxLength: 255 }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <ButtonGroup variant="contained">
                                <Button onClick={
                                    () => setFieldStatus(e => ({
                                        ...e,
                                        save_button: true,
                                        text_fields: false,
                                        edit_button: true
                                    }))
                                    }
                                    disabled={ edit_button }>
                                    Editar
                                </Button>
                                {/* button submit para guardar los cambios */}
                                {
                                    save_button &&
                                        <Button type="submit">Guardar cambios</Button>
                                }
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </form>
                {/* Fin formulario */}
            </Box>
        </Container>
    );
};

export default PersonalData;