import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

//CSS
import './ProductListItem.css';
import no_image from '../../../../assets/images/banners/coleccion-todos1.jpg';

import { activeVariants, productActive } from '../../../../actions/product';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

import {
  Button, Card, CardActions, CardContent, Grid,
  IconButton, Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const spanItalic = {
  fontStyle: 'italic',
  marginLeft: '4px'
};

export default function ProductListItemCard({ product, anchorEl, setAnchorEl, myRef }) {
  const dispatch = useDispatch();
  const { active } = useSelector(state => state.product);
  const {
    _id, title, info, category, prod_category, folderId, prod_variants
  } = product;
  
  const open = Boolean(anchorEl);
  const aria_controls = `product-list-menu-${_id}`;
  
  const variantList = () => {
    dispatch(activeVariants({
      prod_id: _id, title, folderId,
      variants: prod_variants
    }));
    myRef.current.scrollIntoView();
  };

  const handleSettingsClick = ({ currentTarget }) => {
    // cambió el producto activo
    if (JSON.stringify(active) !== JSON.stringify(product))
      dispatch(productActive(product));
    setAnchorEl(currentTarget);
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xl={4} lg={4} md={12} sm={6} xs={12} sx={{ textAlign: 'center', mx: 'auto' }}>
            {
              prod_variants.length > 0 &&
              prod_variants[0].images.length > 0 ?
                <img className='img_product_list_item'
                  src={`${GOOGLE_DRIVE_CDN}${prod_variants[0].images[0].id}`}
                  width="100%" alt={prod_variants[0].images[0].alt} />
              :
                <img className='img_product_list_item'
                  src={no_image}
                  width="100%" alt="producto-nefropolis" />
            }
          </Grid>

          <Grid item xl={8} lg={8} md={12} sm={12} xs={12} sx={{ my: 'auto' }}>
            <Typography sx={{ fontSize: 14 }}
              component={'span'}
              gutterBottom>
              Categorías:
              {
                (prod_category && prod_category.length > 0) ?
                  prod_category.map(({ category }, i) =>
                    (i === prod_category.length - 1) ?
                      <span key={i} style={spanItalic}>{ category }</span>
                    :
                      <span key={i} style={spanItalic}>{ category } |</span>
                  )
                :
                  (category && category.length > 0) ?
                    category.map((e, i) =>
                      (i === prod_category.length - 1) ?
                        <span key={i} style={spanItalic}>{ e }</span>
                      :
                        <span key={i} style={spanItalic}>{ e } |</span>
                    )
                  :
                    ` N/A`
              }
            </Typography>
            <Typography variant="h5" component="div">
              {title}
            </Typography>

            <Typography variant="caption" display="block" gutterBottom>
              {
                prod_variants.length === 1 ?
                  `(1 variante)`
                :
                  `(${prod_variants.length} variantes)`
              }
            </Typography>
            <Typography variant="body2">
              {info}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={variantList}>Mostrar variantes</Button>
        <IconButton aria-label="settings"
          aria-controls={open ? { aria_controls } : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleSettingsClick}>
          <MoreVertIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};