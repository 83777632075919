// validaciones para PersonalInfoCheckoutComponent.jsx
import { msg } from "../actions/swal_msg";

const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
// const rfc_regex = /[A-ZÑ&]{3,4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])(?:[A-Z\d]{3})/;

// validación formulario
export const shippingAddressFormValidator = ({
    name, lastname, email, tel, street, ext_num,
    int_num, crossing_streets, postalcode, colony,
    city, state, references, residence_type,
    residence_type_select
}, bilingValues) => {
    const name_len = name.trim().length;
    const lastname_len = lastname.trim().length;
    const email_len = email.trim().length;
    const tel_len = tel.trim().length;
    const street_len = street.trim().length;
    const ext_len = ext_num.trim().length;
    const int_len = int_num.trim().length;
    const crossing_len = crossing_streets.trim().length;
    const pc_len = postalcode.trim().length;
    const colony_len = colony.trim().length;
    const city_len = city.trim().length;
    const state_len = state.trim().length;
    const ref_len = references.trim().length;
    const res_len = residence_type.trim().length;
    if (name_len > 1 && name_len <= 60) {
        if (lastname_len > 1 && lastname_len <= 60) {
            if (email_len > 5 && email_len <= 100 && regex.test(email)) {
                if (tel_len > 3 && tel_len <= 10) {
                    if (street_len > 5 && street_len <= 100) {
                        if (ext_len > 0 && ext_len <= 4) {
                            if (int_num === '' || (int_len > 0 && int_len <= 8)) {
                                if (crossing_len > 3 && crossing_len <= 200) {
                                    if (pc_len > 0 && pc_len <= 5) {
                                        if (colony_len > 2 && colony_len <= 100) {
                                            if (city_len > 2 && city_len <= 100) {
                                                if (state_len > 2 && state_len <= 100) {
                                                    if (ref_len > 3 && ref_len <= 200) {
                                                        if (residence_type_select !== 'Otro' ||
                                                            (residence_type_select === 'Otro' &&
                                                            res_len > 3 && res_len <= 60)
                                                        ) {
                                                            if (bilingValues.req_invoice)
                                                                return billingValidator(bilingValues);
                                                            else
                                                                return true;
                                                        } else
                                                            msg('warning', 'Checkout',
                                                            'Ingrese tipo de domicilio');
                                                    } else
                                                        msg('warning', 'Checkout',
                                                        'Ingrese referencias sobre su domicilio');
                                                } else
                                                    msg('warning', 'Checkout',
                                                    'Ingrese estado de residencia');
                                            } else {
                                                msg('warning', 'Checkout',
                                                'Ingrese ciudad de residencia');
                                            }
                                        } else {
                                            msg('warning', 'Checkout',
                                            'Ingrese su colonia');
                                        }
                                    } else {
                                        msg('warning', 'Checkout',
                                        'Ingrese su código postal');
                                    }
                                } else
                                    msg('warning', 'Checkout',
                                    '¿Entre qué calles se encuentra su domicilio?');
                            } else {
                                msg('warning', 'Checkout',
                                'El número interior de su domicilio no es válido');
                            }
                        } else {
                            msg('warning', 'Checkout',
                            'Ingrese número exterior de su domicilio');
                        }
                    } else {
                        msg('warning', 'Checkout',
                        'Ingrese la calle de su domicilio');
                    }
                } else {
                    msg('warning', 'Checkout',
                    'Ingrese su número de teléfono');
                }
            } else {
                msg('warning', 'Checkout',
                'Ingrese un correo electrónico válido');
            }
        } else {
            msg('warning', 'Checkout',
            'Ingrese su apellido');
        }
    } else {
        msg('warning', 'Checkout',
        'Ingrese su nombre');
    }
    return false;
};

// validación formulario de facturación
const billingValidator = ({
    business_name, rfc, cif, filename, tax_system,
    cfdi, billing_tel, address, billing_postalcode,
    billing_colony, billing_city, billing_state
}) => {
    const bname_len = business_name.trim().length;
    const rfc_len = rfc.trim().length;
    const ts_len = tax_system.trim().length;
    const cfdi_len = cfdi.trim().length;
    const btel_len = billing_tel.trim().length;
    const address_len = address.trim().length;
    const bpc_len = billing_postalcode.trim().length;
    const bcolony_len = billing_colony.trim().length;
    const bcity_len = billing_city.trim().length;
    const bstate_len = billing_state.trim().length;
    if (bname_len > 1 && bname_len <= 200) {
        if ((rfc_len === 12 || rfc_len === 13)) { // rfc_regex.test(rfc)
            if (cif !== '' && (cif instanceof File || filename !== '')) {
                if (ts_len > 2 && ts_len <= 60) {
                    if (cfdi_len > 2 && cfdi_len <= 60) {
                        if (btel_len > 3 && btel_len <= 15) {
                            if (address_len > 2 && address_len <= 100) {
                                if (bpc_len > 1 && bpc_len <= 5) {
                                    if (bcolony_len > 2 && bcolony_len <= 100) {
                                        if (bcity_len > 2 && bcity_len <= 100) {
                                            if (bstate_len > 2 && bstate_len <= 100)
                                                return true;
                                            else {
                                                msg('warning', 'Checkout',
                                                'Ingrese estado para facturación');
                                            }
                                        } else {
                                            msg('warning', 'Checkout',
                                            'Ingrese ciudad para facturación');
                                        }
                                    } else {
                                        msg('warning', 'Checkout',
                                        'Ingrese colonia para facturación');
                                    }
                                } else {
                                    msg('warning', 'Checkout',
                                    'Ingrese código postal para facturación');
                                }
                            } else {
                                msg('warning', 'Checkout',
                                'Ingrese dirección para facturación');
                            }
                        } else {
                            msg('warning', 'Checkout',
                            'Ingrese teléfono para facturación');
                        }
                    } else {
                        msg('warning', 'Checkout',
                        'Seleccione CFDI');
                    }
                } else {
                    msg('warning', 'Checkout',
                    'Seleccione régimen social');
                }
            } else {
                msg('warning', 'Checkout',
                'Cargue el documento de su CSF');
            }
        } else {
            msg('warning', 'Checkout',
            'Ingrese un RFC correcto');
        }
    } else {
        msg('warning', 'Checkout',
        'Ingrese la razón social');
    }
    return false;
};